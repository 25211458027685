<template>
    <main>
        <DealersPageContent
        :dealerTitle="geral_field.dealer_title"
        :dealerText="geral_field.dealer_text"
        :dealersObj="dealers"/>
    </main>
</template>
<script>
    import { mapState } from 'vuex'
    import DealersPageContent from '../components/Sections/DealersPageContent/DealersPageContent.vue'

    export default {
        name: 'DealersPage',
        components: { DealersPageContent  },
        computed:{
            ...mapState(['geral_field', 'dealers']),
        },
    }
</script>
