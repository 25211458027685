<template>
    <main v-if="!usedCars.lstUsedCars.isGettingCars">
        <UsadosBanner
        :objBannerData="geral_field.usados_banner"
        :objFilter="setFilterData"
        />
        <AdvtgListUsados/>
        <UsadosPageContent id="sendToCars"
        :text="geral_field.usados_paragrafo"
        :title="geral_field.usados_title"
        :subtitle="geral_field.usados_subtitle"/>
        <ListCarsUsados v-if="usedCars.lstUsedCars.length != 0" :showBtn="handleButtonDisplay" :usadosCarsObj="filterCars" :noCarsLabel="geral_field.usados_nocars_filtro"/>
        <p v-else class="noCars"> {{ geral_field.usados_nocars }} </p>
    </main>
    <LoadingPage v-else />
</template>
<script>
    import { mapState } from 'vuex'
    import LoadingPage from './LoadingPage.vue'
    import UsadosBanner from '../components/Banners/UsadosBanner/UsadosBanner.vue'
    import AdvtgListUsados from '../components/Sections/AdvtgListUsados/AdvtgListUsados.vue'
    import UsadosPageContent from '../components/Sections/UsadosPageContent/UsadosPageContent.vue'
    import ListCarsUsados from '../components/Sections/ListCarsUsados/ListCarsUsados.vue'

    export default {
        name: 'UsadosPage',
        components: { LoadingPage, UsadosBanner, AdvtgListUsados, UsadosPageContent, ListCarsUsados, },
        data(){return{
            // filterData: [],
        }},
        mounted(){
            let usadosLink = document.getElementById('usadosLink')
            if(!usadosLink){
                this.$router.replace('/')
                alert('O concessionário não vende carros usados.')
            }
        },
        // updated(){
        //     if(!this.usedCars.isGettingCars){
        //         this.setFilterData();
        //     }
        // },
        computed:{
            ...mapState(['geral_field', 'selectedFilterOptions', 'usedCars']),
            filterCars(){
                let carsObj = this.usedCars.lstUsedCars;

                let _filterObj = this.selectedFilterOptions.options;
                let filterConditions = [];
                
                if(_filterObj.selectedModel != '') filterConditions.push('el.carModel == _filterObj.selectedModel');
                if(_filterObj.selectedFuel != '') filterConditions.push('el.carFuel == _filterObj.selectedFuel');
                if(_filterObj.selectedYear != '') filterConditions.push('el.year == _filterObj.selectedYear');
                if(_filterObj.selectedPrice != '') filterConditions.push('el.pvp >= _filterObj.selectedPrice[0] && el.pvp <= _filterObj.selectedPrice[1]' );
                if(_filterObj.selectedKms != '') filterConditions.push('el.kilometres <= _filterObj.selectedKms[1]');
                
                let _obj = carsObj.filter(el => {

                    if([...filterConditions].every(cond => eval(cond))){ return el; }

                });
                this.$store.commit('selectedFilterOptions/UPDATE_COUNT', _obj.length );
                return _obj
            },
            handleButtonDisplay(){
                return (this.selectedFilterOptions.totalcars > 8) ? true : false;
            },
            setFilterData(){
                let arrayUsados = this.usedCars.lstUsedCars;
                let models = [];
                let fuel = [];
                let year = [];
                let price = [];
                let kms = [];

                arrayUsados.map(el => {
                    if(el){
                        models.push(el.carModel);
                        fuel.push(el.carFuel);
                        year.push(el.year);
                        price.push(el.pvp);
                        kms.push(el.kilometres);
                    }
                });

                let arr = [
                    [...new Set(models)],
                    [...new Set(fuel)], 
                    [...new Set(year.sort())], 
                    Math.max(...price),
                    Math.max(...kms),
                ];
                return arr;
            }
        },
    }
</script>
<style lang="scss" scoped>
    .noCars{
        width: min(80%, 50rem);
        margin: 0 auto;
        @include mixin_text_content(font18, $blackCol, center, $mediumFont);
        padding: 2.5rem 0;
    }
</style>