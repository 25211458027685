<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.884"
    height="11.989"
    viewBox="0 0 7.884 11.989"
  >
    <path
      d="M22.5,10a3.947,3.947,0,0,0-3.942,3.942c0,2.873,3.6,7.745,3.75,7.951a.236.236,0,0,0,.192.1.243.243,0,0,0,.192-.1c.153-.206,3.75-5.079,3.75-7.951A3.948,3.948,0,0,0,22.5,10Zm0,5.944a2,2,0,1,1,2-2A2.006,2.006,0,0,1,22.5,15.944Z"
      transform="translate(-18.56 -10)"
      fill="#fff"
    />
  </svg>
</template>
