<template>
    <section class="newsSec_lg">
        <p class="newsSec_lg-title">{{ secTitle }}</p>
        <div class="newsSec_lg-cardsMobile">
            <swiper
            :slides-per-view="2.2"
            :space-between="10"
            :pagination="{ clickable: true }"
            :breakpoints="swiperOptions">
                <swiper-slide v-for="(item, index) in newsData" :key="index" >
                    <NewsCard :newsItem="item" />
                </swiper-slide>
            </swiper>
        </div>

        <div class="newsSec_lg-cards">
            <NewsCard v-for="(item, index) in newsData" :key="index" :newsItem="item" />
        </div>
        <div class="newsSec_lg-btnCont">
            <GovButton :displayAtag="false" toRoute="/noticias" btnText="Ver Todos" svgItem="arrow" />
        </div>
    </section>
</template>
<script>
    import SwiperCore, { Pagination, A11y } from "swiper";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import "swiper/swiper-bundle.css";
    SwiperCore.use([ Pagination, A11y]);
    import NewsCard from '../../Cards/NewsCard/NewsCard.vue'
    import GovButton from '../../Buttons/GovButton.vue'
    import {parcialSwiperBreakPoints} from '../../../mixins/swiperBreakPoints'

    export default {
        name: 'NewsHp',
        components:{ Swiper, SwiperSlide, NewsCard, GovButton },
        mixins:[parcialSwiperBreakPoints],
        props: {
            secTitle: { type: String, default: '+Hyundai'},
            newsData: { type: Object, default: () => {return {}; }},
        }
    }
</script>
<style lang="scss">
    @import "./NewsHp.scss";
</style>