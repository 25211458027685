<template>
    <section v-if="secTitle" class="cpGallery">
        <h2 class="cpGallery-title">{{ secTitle }}</h2>
        <div class="cpGallery-list">
            <p v-for="(item, index) in prosList" :key="index"> {{ item }} </p>
        </div>
    </section>
    <Galery :imgArray="srcImgsList" galClass="pipGalery" />
    <div v-if="handleBtnState()" class="btnCont">
        <GovButton :openNewTab="false" btnLink="#sendform" btnText="Quero saber mais" svgItem="arrow"/>
    </div>
</template>
<script>
    import Galery from '../../Sections/Galery/Galery.vue'
    import GovButton from '../../Buttons/GovButton.vue'

    export default {
        name: 'GaleryBlock',
        components: { Galery, GovButton },
        props:{
            secTitle: {type: String},
            prosList: {type: Array, default: () => { return []; }},
            srcImgsList: {type: Array, default: () => { return []; }},
        },
        methods: {
            handleBtnState(){
                const hasGallery = this.srcImgsList.length != 0
                const hasContent = this.secTitle != ''

                return hasGallery && hasContent;
            }
        }
    }
</script>
<style lang="scss">
    @import './GaleryBlock.scss';
</style>
