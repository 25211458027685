<template>
    <div v-if="!hasOneImg" class="contImg">
        <img :src="scrMobile" :alt="altText">
        <img :src="scrDesktop" :alt="altText">
    </div>
    <div v-else class="cardImg">
        <img :src="scrDesktop" :alt="altText">
    </div>
</template>
<script>
    export default {
        name: 'BgImg',
        props: {
            hasOneImg: {type: Boolean, default: false},
            scrDesktop: {type: String},
            scrMobile: {type: String},
            altText: {type: String, default: 'Imagem ilustrativa'},
        }   
    }
</script>

<style lang="scss">
    .contImg{
        position: absolute;
        width: inherit;
        height: inherit;
        // z-index: -1;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;

            &:nth-child(1){display: block;}
            &:nth-child(2){display: none;}
        }
    }

    .cardImg{ 
        width: 100%;
         
        img{
            height: inherit;
            width: inherit;
            object-fit: cover;
            overflow: hidden;
        }
    }

    @media only screen and (min-width: 992px){
        .contImg img:nth-child(1){ display: none;}
        .contImg img:nth-child(2){ display: block;}
    }
</style>