import { mapGetters, mapState } from 'vuex'

export const formsValidation = {
    data(){return{
        sitekey: '6LciPmYeAAAAAMQM4fGRyb5BBtzrIkedKTtqYnfl',
        capchaID: 'capchaID',
        showRecaptcha: true,
        name: null,
        phone: null,
        email: null,
        msg: null,
        modelID: null,
        dealer: null,
        service: null,
        plate: null,
        reCapchaState: false,
        idSubscriptionCenterOrigin: 44,
    }},
    created(){
        if(!this.formParams(this.formParamsKey)) this.$store.dispatch('formParams/fetchFormParams')
    },
    mounted(){
        // reset error msg content
        let fields = document.querySelectorAll('.rgErrorMessage');
        fields.forEach(el => el.innerHTML = '')
    },
    computed:{
        ...mapState(['siteName']),
        ...mapGetters({
            formParams: 'formParams/geFormParams',
            settings: 'getSettings',
        }),
    },
    methods:{
        validadeForms(e){
            let status = {
                /** true if value ain´t common to all forms */
                name: false,
                phone: false,
                email: false,

                msg: true,
                dealer: true,
                modelID: true,
                service: true,
                plate: true,

                policy: false,
                reCapcha: false
            };

            status.name = (this.isEmptyField(this.name));
            this.handleErorMsg('nome', status.name, 'Indique um nome válido');

            status.phone = this.validadePhone(this.phone);
            this.handleErorMsg('telefone',  status.phone, 'Indique um número de telefone');

            status.email = this.validEmail(this.email)
            this.handleErorMsg('email', status.email, 'Indique um email válido');

            if(document.getElementById('msg') != null){

                if(!this.msg){
                    status.msg = this.isEmptyField(this.msg)
                    this.handleErorMsg('msg', status.msg, 'Indique a sua mensagem');
                }

                if(this.service){
                    status.service = this.isEmptyField(this.service);
                    this.handleErorMsgSelects('msg', 'msgSelect', status.service, 'Indique um serviço');
                }
            }

            if(document.getElementById('dealerSelect') != null){
                status.dealer = this.isEmptyField(this.dealer);
                this.handleErorMsgSelects('dealer', 'dealerSelect', status.dealer, 'Indique um concessionário');
            }

            if(document.getElementById('model_lstSelect') != null){
                status.modelID = this.isEmptyField(this.modelID);
                this.handleErorMsgSelects('model_lst', 'model_lstSelect', status.modelID, 'Indique um modelo');
            }

            if(document.getElementById('matricula') != null){
                status.plate = (this.isEmptyField(this.plate));
                this.handleErorMsg('matricula', status.plate, 'Matrícula invalida');
            }

            status.policy = document.getElementById('accept').checked;
            this.handleErorMsg('accept', status.policy, 'Tem que aceitar a Política de Privacidade');

            status.reCapcha = this.reCapchaState;
            this.handleErorMsg(this.capchaID, status.reCapcha, 'Preencha o reCaptcha');

            if(Object.values(status).every(Boolean)){
                this.handleGTMEvent()
                return true;
            }
            e.preventDefault();
        },

        recaptchaVerified() {
            this.reCapchaState = true
        },
        recaptchaExpired() {
            this.$refs.vueRecaptcha.reset();
            this.showRecaptcha = false;
        },
        recaptchaFailed() {
            console.log("Somthing was wrong with captcha");
            this.reCapchaState = false
        },


        /** Handle eror message */
        handleErorMsg(fieldId, isValid, errorMessage){
            let domField = document.getElementById(fieldId);
            let fieldParent = domField.parentElement;
            let errorTag = fieldParent.querySelector('.rgErrorMessage');
            this.setFieldsStyle(domField, errorTag, isValid, errorMessage);
        },
        handleErorMsgSelects(select, parent, isValid, errorMessage ){
            let fieldParent = document.getElementById(parent);
            let comboBox = document.getElementById(select);
            let errorTag = fieldParent.querySelector('.rgErrorMessage');
            this.setFieldsStyle(comboBox, errorTag, isValid, errorMessage);
        },

        /** set error style */
        setFieldsStyle(domField, errorTag, isValid, errorMessage){
            if(isValid){
                domField.style.border = '1px solid #c9c9c9'
                errorTag.textContent = ''
            }else{
                domField.style.border = '1px solid #ff3333'
                errorTag.textContent = errorMessage
            }
        },

        /** Validation */
        isEmptyField(field){
            if(field == undefined || field == ''){
                return false
            }else{ return true}
        },
        validadePhone(field){
            let testCondition = /^\d+$/;
            if(!field || field.length < 9 || field == ''){
                return false;
            }
            return testCondition.test(field);
        },
        validEmail(field) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(field);
        },
        getRouterPage(routeName){
            let baseUrl = window.location.origin;
            let path = this.$router.resolve({name: routeName}).path;
            return baseUrl + path;
        },
    },
}
