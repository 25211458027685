<template>
    <section class="header">
        <p :class="{'header-tag': true, show: isRecentCar}">Recente</p>
        <h1 class="header-title">
            {{ concatTitle(carObj.carBrand, carObj.carModel, carObj.carVersion) }}
        </h1>
    </section>
    <section class="headerCarDetails">
        <div class="headerCarDetails-swiper">
            <CarDetailsSwiper :imgObj="carObj.carPhotos"/>
        </div>
        <div class="headerCarDetails-content">
            <div :class="carObj.promoPrice != 0 ? 'promo' : 'price'">
                <p> {{ format_thousands(carObj.pvp) }} €</p>
                <p> {{ format_thousands(carObj.promoPrice) }} €</p>
            </div>
            <p class="label">Consumos:</p>
            <div class="consumptions">
                <div class="consumptions-item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                        <g id="Group_7595" data-name="Group 7595" transform="translate(-22 -461)">
                            <circle id="Ellipse_212" data-name="Ellipse 212" cx="13" cy="13" r="13" transform="translate(22 461)" fill="#707070" opacity="0.182"/>
                            <path id="Path_16699" data-name="Path 16699" d="M57,79.1a4.137,4.137,0,0,1-3.545-6.051L57,66l3.545,7.048A4.137,4.137,0,0,1,57,79.1Z" transform="translate(-22 401)" fill="#797979"/>
                        </g>
                    </svg>
                    <div class="consumptions-item-wrap">
                        <b>{{carObj.combinedConsumption}}</b><br>Circuito urbano
                    </div>
                </div>
                <div class="consumptions-item">
                    <b>{{carObj.highwayConsumption}}</b><br>Auto estrada
                </div>
            </div>
            <div class="carInfo">
                <LabelRegValBold label="Modelo:" :value="carObj.carModel" />
                <LabelRegValBold label="Ano:" :value="carObj.year" />
                <LabelRegValBold label="Quilómetros:" :value="carObj.kilometres + ' km'" />
                <LabelRegValBold label="Combustível:" :value="carObj.carFuel" />
                <LabelRegValBold label="Tipo de Caixa:" :value="carObj.carTransmission" />
                <LabelRegValBold label="Potência" :value="carObj.power + ' cv'" />
            </div>
        </div>
    </section>
    <section class="headerCarDetails">
        <div v-if="showExtraInfo" class="headerCarDetails-list p3">
            <LabelRegValBold label="Lugares:" :value="carObj.seatCount" />
            <LabelRegValBold label="Portas:" :value="carObj.doorCount" />
            <LabelRegValBold label="Matricula:" :value="carObj.plateNumber" />
            <!-- <LabelRegValBold label="Motor:" :value="tempVal" /> -->
            <LabelRegValBold label="Cor Exterior:" :value="carObj.carExteriorColor" />
            <LabelRegValBold label="Cor interior:" :value="carObj.carInteriorColor" />
            <!-- <LabelRegValBold value="Esconder detalhes" @click="showCarInfo" /> -->
        </div>
        <div v-else class="headerCarDetails-list moreInfo">
            <LabelRegValBold value="Ver todos os detalhes" @click="showCarInfo" />
        </div>
        <div class="btnConts">
            <GovButton :displayAtag="false"
            :toRoute="'/saber-mais?f=1&id=' + carObj.idCar"
            btnClass="btn-primaryRound" btnText="Estou interessado" svgItem="arrow"/>
        </div>
    </section>
</template>
<script>
    import CarDetailsSwiper from '../../SwiperContainers/CarDetailsSwiper.vue'
    import LabelRegValBold from '../../SimpleComponents/LabelRegValBold.vue'
    import GovButton from '../../Buttons/GovButton.vue'
    import {mainFunctions} from '../../../mixins/mainFunctions'

    export default {
        name: 'HeaderCarDetails',
        components: { CarDetailsSwiper, LabelRegValBold, GovButton, },
        mixins: [mainFunctions],
        props:{
            carObj: { type: Object, default: () => { return {}; }}
        },
        mounted(){
            if(window.innerWidth > 1020){
                this.showExtraInfo = true;
            }else{
                this.showExtraInfo = false;
            }
        },
        data(){return{
            showExtraInfo: false,
            tempVal: 'missing',
        }},
        methods:{
            showCarInfo(){
                this.showExtraInfo = !this.showExtraInfo;
            },
            concatTitle(brand = '', model = '', version = ''){
                return brand + ' ' + model + ' ' + version;
            },
        },
    }
</script>
<style lang="scss">
    @import './HeaderCarDetails.scss';
</style>
