<template>
    <div v-show="linkCatalogo || filterItems?.[0]" class="pipGallery">
        <h2 class="pipGallery-title"> {{ secTitle }} </h2>
        <PipGalFilter :filterItems="filterItems" v-model:activeOpt="currFilterOpt" />
        <div v-if="currFilterOpt == 'movie'" class="pipGallery-video" v-html="video"></div>
        <Galery v-else :imgArray="handleRenderGall" :maxImgs="5" galClass="pipGalery"/>
        <div v-show="linkCatalogo" class="btnCont">
            <GovButton :btnLink="linkCatalogo" btnText="Pedido de catálogo" svgItem="arrow" />
        </div>
    </div>
</template>
<script>
    import PipGalFilter from '../../SectionParts/PipGalFilter.vue'
    import Galery from '../Galery/Galery.vue'
    import GovButton from '../../Buttons/GovButton.vue'

    export default {
        name: 'PipGallery',
        components: { PipGalFilter, Galery, GovButton },
        props: {
            secTitle: {type: String},
            extGallery: {type: Array, default: () => { return []; }},
            intGallery: {type: Array, default: () => { return []; }},
            video: {type: String},
            linkCatalogo: { type: [String, Boolean]},
        },
        data(){return{
            isMovie: false,
            currFilterOpt: 'ext',
            filterItems: [],
        }},
        mounted(){
            if(this.extGallery.length > 0) this.filterItems.push( {'id': 'ext', 'label': 'Ver o exterior'})
            if(this.intGallery.length > 0) this.filterItems.push( {'id': 'int', 'label': 'Ver o interior'})
            if(this.video != '') this.filterItems.push( {'id': 'movie', 'label': 'Ver filme'})
        },
        computed:{
            handleRenderGall(){
                if(this.currFilterOpt == 'ext'){
                    return this.extGallery;
                }else if(this.currFilterOpt == 'int'){
                    return this.intGallery;
                }else{ return false }
            }
        }
    }
</script>
<style lang="scss">
    @import './PipGallery.scss';
</style>