import * as COOKIES from "@/cookies/cookies.js";
import { mapMutations, mapState } from "vuex";

window.dataLayer = window.dataLayer || [];

export const handleCookies = {
    data(){return{
    }},
    created(){
        this.updateCookiesState({
            cookieModal: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_modal"),
        })

        if(this.cookieModal != 'N') this.openModal({
            component: 'CookiesModal',
            openModal: true,
            showHeader: false,
            title: '',
            modalClass: 'modal-center',
        })
    },
    watch: {
        appReady(){
            if(this.appReady == true) this.checkGTM()
        },
        cookieModal(){
            if(this.modal == "N") this.checkGTM()       //SE O VALOR DA COOKIE MODAL FOR "N" = NÃO ABRIR MODAL (JÁ EXISTEM CONFIGURAÇÕES DE COOKIES)
        },
        cookieSettings(){
            this.checkGTM()
        },
    },
    computed: {
        ...mapState(['cookieModal', 'cookieSettings']),
    },
    methods: {
        ...mapMutations({
            openModal: 'modalData/UPDATE_STATE',
            updateCookiesState: 'UPDATE_STATE',
        }),
        checkGTM() {
            if (COOKIES.checkRGCookies() == true) {     //SE ALGUMA COOKIE FOI PERMITIDA (NÃO CONTACO A ESSENCIAL)
                window.dataLayer.push({                 //CRIAR LISTA DAS COOKIES BLOQUEADAS
                    "gtm.blocklist": COOKIES.blockedList,
                });
                this.$gtm.enable(true);                 // INICIAR O TAG MANAGER
            }
            else this.$gtm.enable(false);
        },
    },
}
