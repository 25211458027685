<template>
    <router-link v-if="carObj != null"
    :to="'/usados/' + carObj.plateNumber"
    :key="this.$route.fullpath"
    :class="isDefault ? 'usadosCard' : '__usadosCard'">
        <Image theClass="usadosimg" :srcImg="carObj.carPhotos[0].fileUrl" />
        <div class="content">
            <p v-show="isRecentCar" class="content-tag">Recent</p>
            <h4 class="content-title">
                {{ concatTitle(carObj.carBrand, carObj.carModel, carObj.carVersion) }} </h4>
            <div class="content-info">
                <p> {{ carObj.year }} </p>
                <p> {{ carObj.carFuel }} </p>
                <p> {{ carObj.kilometres }} km</p>
            </div>
            <div v-if="carObj.isInPromotion" class="content-price promo">
                <div class="wrap">
                    <span> Compre por<br> </span>
                    <p> {{ format_thousands(carObj.pvp) }} €</p>
                </div>
                <h3> {{ format_thousands(carObj.pvp)  }} €</h3>
            </div>
            <div v-else class="content-price">
                <span> Compre por<br> </span>
                <h3> {{ format_thousands(carObj.pvp)  }} €</h3>
            </div>
            <div class="content-btn">
                <GovButton btnClass="btn-orangeInverted" svgItem="arrow"/>
            </div>
        </div>
    </router-link>
</template>
<script>
    import Image from '../../SimpleComponents/Image.vue'
    import GovButton from '../../Buttons/GovButton.vue'
    import {mainFunctions} from '../../../mixins/mainFunctions'

    export default {
        name: 'UsadosCard',
        components: { Image, GovButton, },
        mixins: [mainFunctions],
        props:{
            isDefault: { type: Boolean, default: true },
            carObj: { type: Object, default: () => { return {}; }}
        },
        data(){return{
            tempImg: 'https://demositehyundai.rigorcg.pt/wp-content/uploads/sites/21/2021/09/The_Elantra_N_LIne_01_High.png',
        }},
        methods:{
            concatTitle(brand, model, version){
                return brand + ' ' + model + ' ' + version;
            },
        }
    }
</script>
<style lang="scss">
    @import './UsadosCard.scss';
</style>