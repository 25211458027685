<template>
    <section class="sectionHeader">
    <h2 :class="isBlack ? 'sectionHeader-title' : 'sectionHeader-title orange'"> {{ secTitle }} </h2>
    </section>
</template>
<script>
    export default {
        name: 'SectionHeader',
        props: { 
            isBlack: { type: Boolean, default: true},
            secTitle: { type: String},
        }   
    }
</script>