<template>
    <div v-show="isOpen" class="modal">
        <div class="modal-blend" @click="handleModalState"></div>
        <div class="modal-content target">
            <div class="modal-content-wrap">
                <div class="modal-content-text" v-html="modalContent"></div>
            </div>
            <div class="modal-content-close" @click="handleModalState">
                <svg xmlns="http://www.w3.org/2000/svg" width="17.67" height="17.67" viewBox="0 0 17.67 17.67">
                    <g id="Group_7145" data-name="Group 7145" transform="translate(0.354 0.354)">
                        <line id="Line_411" data-name="Line 411" x2="16.963" y2="16.963" fill="none" stroke="#fff" stroke-width="1"/>
                        <line id="Line_412" data-name="Line 412" x1="16.963" y2="16.963" fill="none" stroke="#fff" stroke-width="1"/>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ModalBox',
        props:{
            isOpen: { type: Boolean, default: false },
            modalContent: { type: String, default: 'Informação geral'},
        },
        methods:{
            handleModalState(){
                this.$emit('close-modal', false);
                document.body.classList.remove('isActive');
            }
        },
        beforeUnmount(){
            document.body.classList.remove('isActive');
        }
        
    }
</script>
<style lang="scss">
    .modal-content-text{
        // max-height: 550px;
        max-height: 450px;
        overflow-y: scroll;
        &::-webkit-scrollbar{ display: none;}
        @include mixin_text_content(font11, $greyCol, $lineHeight: 1.5rem);

        .extrabold{
            font-family: $boldFont;
        }
    }

    .modal-content-close{
        position: absolute;
        top: 0;
        right: 4%;
        width: 32px;
        height: 32px;
        background-color: $darkCol;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    
    @include respond-to(large){
        #app .modal-content.target{
            right: 0;
            width: 64%;
            // max-height: 650px;
            // max-height: 450px;
            padding: 4%;
        }
    }
</style>