import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createMetaManager } from 'vue-meta'
import { createGtm } from '@gtm-support/vue-gtm';
import axios from 'axios'

const app = createApp(App);

app.use(store)
app.use(router)
app.use(createMetaManager())

const govMaster = process.env.VUE_APP_GOV_MASTER
const apiPips = process.env.VUE_APP_API_PIPS
const URLpips = govMaster + apiPips


axios.get(govMaster + process.env.VUE_APP_API_GOV_MASTER)
.then(resMaster => {
    const origin =  new URL(window.location.origin).hostname
    const res = resMaster.data.dealerLst.find(el => {
        if(el.vueDomain != ''){
            let domain = new URL(el.vueDomain).hostname
            if(domain == origin) return el
        }
    });

    if(!res){
        window.location.replace('https://www.hyundai.pt/')
        return
    }

    app.use(createGtm({
        id: res.gtm,
        enabled: false,
    }))

    store.commit('UPDATE_STATE', { settings: res })

    let url1 = res.api + process.env.VUE_APP_API_GOV_DATA + '/' + res.siteid
    let dealerArr = [res.nif, res.oidDealer];
    let sellsUsed = res?.showUsed

    store.dispatch('fetchDealers', dealerArr[0]);
    if(sellsUsed) store.dispatch('usedCars/fetchUsedCars', res.oidDealers);

    store.commit('UPDATE_STATE', {
        dealerData: dealerArr,
        form_apv_type: res?.form_apv_type?.split(','),
        siteName: res.sitename,
        dealerSellsUsed: sellsUsed,
    });

    const axiosReqHyuDataFromGov = () => axios.get( govMaster + process.env.VUE_APP_API_HYU_DATA );
    const axiosReqGovData = () => axios.get(url1); //generated JSON
    const axiosReqPIPS = () => axios.get(URLpips );

    // handle hyu data
    axiosReqHyuDataFromGov().then(res => {
        const data = res.data

        store.commit('UPDATE_STATE', {
            garanty: data.garanty,
            forms: data.formData,
            news: data.news,
            linkConfigurador: data.linkConfigurador,
            hyuReady: true,
        })
        store.commit('SET_NEWSCATS_TEMATICS', data.news)
        store.commit('UPDATE_GERAL_FIELDS_BANNERS', {
            banners: data.banners,
            geral_field: data.geral_fields
        })
    })

    // handle gov data
    axiosReqGovData().then(res => {
        const data = res.data

        store.commit('UPDATE_STATE', { govReady: true, })
        store.commit('UPDATE_GERAL_FIELDS_BANNERS', {
            banners: data.banners,
            geral_field: data.geral_field,
        })
    })
    .catch(err => console.log(err))

    axiosReqPIPS().then(res => {
        let models = res.data
        store.commit('UPDATE_STATE', { models: models })
    })
    .catch(err => console.log(err))

    store.dispatch('govCampaigns/fetchHyuCampaigns', govMaster )
    store.dispatch('govCampaigns/fetchDealerCampaigns', { api: res.api, id: res.siteid } )

})
.catch(err => console.log(err))



app.mount('#app')
