<template>
    <section class="cpBanner">
        <BgImg :scrMobile="bannerImgMobile" :scrDesktop="bannerImgDesktop"/>
        <div class="cpBanner-content">
            <div class="cpBanner-content-wrap">
                <h1 class="cpBanner-content-title">{{ bannerTitle }}</h1>
                <p class="cpBanner-content-signature">{{ bannerSignature }}</p>
            </div>
            <PriceBlueBox v-if="isObjEmpty(bannerPriceBox)" :cpBanner="bannerPriceBox" :type="true"/>
        </div>
    </section>
</template>
<script>
    import BgImg from '../../SimpleComponents/BgImg.vue'
    import PriceBlueBox from '../../SimpleComponents/PriceBlueBox.vue'

    export default {
        name: 'CampaignsBanner',
        components: { BgImg, PriceBlueBox },
        props:{
            bannerImgMobile: { type: String},
            bannerImgDesktop: { type: String},
            bannerTitle: { type: String},
            bannerSignature: { type: String},
            bannerPriceBox: { type: Object, default: () => { return {}; }}
        },
        methods: {
            isObjEmpty(obj){
                return Object.values(obj).every(prop => prop === '') ? false : true
            }
        }
    }
</script>
<style lang="scss">
    @import './CampaignsBanner.scss';
</style>