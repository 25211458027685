<template>
    <swiper 
    class="mainSwiper"
    :modules="[Thumbs]"
    :slides-per-view="1"
    navigation
    
    :thumbs="{ swiper: thumbsSwiper }">
        <swiper-slide v-for="(item, i) in imgObj" :key="i" class="mainSwiper-slide">
            <img :src="item.fileUrl" alt="Imagem">
        </swiper-slide>
    </swiper>
    <swiper
        class="thumbsSwiper"
        :modules="[Thumbs]"
        :direction="'vertical'"
        :slides-per-view="5"
        :watch-slides-progress="true"
        @swiper="setThumbsSwiper">
            <swiper-slide v-for="(item, i) in imgObj" :key="i" class="thumbsSwiper-slide">
            <img :src="item.fileUrl" alt="Imagem">
            </swiper-slide>
    </swiper>
</template>
<script>
    import { ref } from 'vue';
    import SwiperCore, { Navigation, Thumbs, A11y } from "swiper";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import "swiper/swiper-bundle.css";
    SwiperCore.use([ Navigation, Thumbs, A11y]);

    export default {
        name: 'CarDetailsSwiper',
        components: { Swiper, SwiperSlide },
        props:{
            imgObj:{ type: Object, default: () => { return {}; }}
        },
        setup() {
            const thumbsSwiper = ref(null);
            const setThumbsSwiper = (swiper) => {
                thumbsSwiper.value = swiper;
            };
            return {
                Thumbs,
                thumbsSwiper,
                setThumbsSwiper,
            };
        },
    }
</script>
<style lang="scss">
    .mainSwiper{ padding-bottom: 2rem;
        .swiper-button-prev{ 
            &:after{
            content: url(../../assets/leftChev.svg);
        }}
        .swiper-button-next{ transform: rotate(180deg);
            &:after{
            content: url(../../assets/leftChev.svg);
        }}
        .swiper-pagination{ bottom: 0; }
    }

    @include respond-to(extralarge){
        .mainSwiper{ padding-bottom: 0;
            &:after{
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: 1px;
                // background-color: red;
                z-index: 222;
                top: 50%;
            }
            .swiper-button-prev,
            .swiper-button-next{
                width: 71px;
                height: 71px;
                left: 0;
            }
            .swiper-button-prev{
                background-color: $whiteCol;
                top: 40.5%;
                left: unset;
                &:after{
                    content: url(../../assets/arrow_left_blue.svg);
                }
            }
            .swiper-button-next{
                transform: rotate(0);
                background-color: $primaryCol;
                top: 54.5%;
                &:after{
                    content: url(../../assets/arrow_right_white.svg);
                }
            }
            .swiper-pagination{ bottom: 1rem; }
        }
    }
</style>