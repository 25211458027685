<template>
    <div :class="theClass">
        <img :src="srcImg" alt="Imagem">
    </div>
</template>
<script>
    export default {
        name: 'Image',
        props:{
            theClass: { type: String, default: 'item'},
            srcImg: { type: String, default: null},
        }   
    }
</script>
<style lang="scss" scoped>
    .formLayout-img img
    {
        @extend %img;
        margin: 40px 0;
    }
    @include respond-to(large){
        .formLayout-img img{ margin: 0;}
    }
</style>