<template>
    <div :class="btnClass">
        <span> {{ btnText }} </span>
        <SvgList :svgItem="svgItem" />
    </div>
</template>
<script>
    import SvgList from '../SimpleComponents/SvgList.vue'

    export default {
        name: 'SimpleButton',
        components:{SvgList},
        props:{
            btnClass: { type: String, default: 'btn-primary'},
            btnText: { type: String, default: 'Saber mais'},
            svgItem: { type: String, default: ''},
        }   
    }
</script>