<template>
    <section v-if="sellerTitle || sellerText" class="sellerBlock">
        <h2 class="sellerBlock-title"> {{ sellerTitle }} </h2>
        <p class="sellerBlock-text"> {{ sellerText }} </p>
        <div v-if="handleBtnState()" class="btnCont">
            <GovButton :openNewTab="false" btnLink="#sendform" btnText="Pedir mais informações" svgItem="arrow"/>
        </div>
    </section>
</template>
<script>
    import GovButton from '../Buttons/GovButton.vue'

    export default {
        name: 'SellerBlock',
        components: { GovButton },
        props:{
            sellerTitle: { type: String},
            sellerText: { type: String},
        },
        methods: {
            handleBtnState(){
                const hasTitle = this.sellerTitle != ''
                const hasContent = this.sellerText != ''

                return hasTitle && hasContent;
            }
        }
    }
</script>
<style lang="scss">
    .sellerBlock{
        @extend %container;
        margin-top: 2rem;
        margin-bottom: 4rem;

        h2{
            @include mixin_text_content(font20, $darkCol, $font: $boldFont, $lineHeight: 1.625rem);
            margin-bottom: 1.5rem;
        }
        p{
            @include mixin_text_content(font14, $greyCol, $lineHeight: 1.5rem);
            margin-bottom: 3rem;
        }
    }

    @include respond-to(large){
        .sellerBlock {
            h2,
            p{ text-align: center;}

            .btn-primary{
                width: 274px;
            }
        }
    }
</style>
