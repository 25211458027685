import axios from "axios";

const state = () => ({
    campaigns: [],
    cpsHyu: false,
    cpsGOV: false,
});

const getters = {
    getCampaignsLstSorted: (state, getters) => {
        let res = getters.getCpsList

        // let sortByDate = res.sort((a, b) => {
        //     let aDate = new Date(a.post_published).getTime()
        //     let bDate = new Date(b.post_published).getTime()

        //     if(bDate > aDate) return 1
        //     if(bDate < aDate) return -1
        //     return 0
        // })

        let sortByOrigin = res.sort((current,next) => current.from?.localeCompare(next.from))
        return sortByOrigin
    },
    getCpsList: (state, getters, rootState) => {
        let res = state.campaigns.filter(el => {
          if(!rootState.dealerSellsUsed && el.campaign_type == 'Usados') return
          if(!rootState.dealerHasAfterSale && el.campaign_type == 'Após Venda') return
          return el
        })
        return res
    },
    getCpsFilterItems: (state, getters, rootState) => {
        let firstEl = 'Todas';
        let arr = [firstEl, 'Novos']

        state.campaigns.map(el => {
            rootState.dealerSellsUsed && el.campaign_type == 'Usados' && arr.push('Usados')
            rootState.dealerHasAfterSale && el.campaign_type == 'Após Venda' && arr.push('Após Venda')
        })
        return [...new Set(arr)]
    },
    hasCampaigns: state => {
        return (state.cpsGOV && state.cpsHyu) ? true : false
    }
}

const mutations = {
    SAVE_CAMPAIGN(state, payload){
        if(payload?.length){
            state.campaigns = [...state.campaigns, ...payload]
        }
    },
    UPDATE_STATE(state, payload){
        state = Object.assign(state, payload)
    }
};

const actions = {
    async fetchDealerCampaigns({ commit }, obj){
        let url = obj.api + process.env.VUE_APP_API_GOV_CAMPAIGNS + '/' + obj.id

        await axios.get(url)
        .then(res => {
            commit('SAVE_CAMPAIGN', res.data)
            commit('UPDATE_STATE', { cpsGOV: true })
        })
        .catch(err => console.log(err))
    },

    async fetchHyuCampaigns({ commit }, domain ){
        let url = domain + process.env.VUE_APP_API_HYU_CAMPAIGNS
        await axios.get(url)
        .then(res => {
            commit('SAVE_CAMPAIGN', res.data)
            commit('UPDATE_STATE', { cpsHyu: true })
        })
        .catch(err => console.log(err))
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
