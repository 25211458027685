<template>
    <div class="select selFilterUsados">
        <select v-if="isMinMax" 
        @change="getSelectValues($event, true)">
            <option v-show="defaultVal != ''" value=""> {{ defaultVal }} </option>
            <option v-for="(item, index) in selectObj" :key="index"
            :data-min="item.min" :data-max="item.max" :value="item.value"> {{ item.text }} </option>
        </select>
        <select v-else
        @change="getSelectValues($event, false)">
            <option v-show="defaultVal != ''" value=""> {{ defaultVal }} </option>
            <option v-for="(item, index) in selectObj" :key="index"
            :value="item"> {{ item }} </option>
        </select>
    </div>
</template>
<script>
    export default {
        name: 'ComboBox',
        props:{
            isMinMax: { type: Boolean, default: false},
            defaultVal: { type: String},
            selectObj: { type: Object, default: () => { return {}; }},
        },
        methods:{
            getSelectValues(e, isMinMax){
                let _target = e.target;
                if(isMinMax && _target.options.selectedIndex != 0){
                    this.$emit('update:value', [_target.options[_target.selectedIndex].dataset.min,
                            _target.options[_target.selectedIndex].dataset.max])
                }else{
                    this.$emit('update:value', _target.value)
                }
            }
        },
    }
</script>
<style lang="scss">
    #app .select.selFilterUsados{
        &:after{ top: .25rem; color: $whiteCol; }
        select{
            border: 1px solid $whiteCol;
            border-radius: 5rem;
            background-color: $primaryCol;
            color: $whiteCol;
        }
    }
</style>