import axios from "axios";

// env vars
const govMaster = process.env.VUE_APP_GOV_MASTER
const cfgApi = process.env.VUE_APP_API_MODEL

const state = () => ({
    pipLst: [],
});

const getters = {
    getPipById: state => (id, key) => {
        let res = state.pipLst.find(el => el.id == id)
        return key ? res?.[key] : res
    },
}

const mutations = {
    UPDATE_PIPS(state, payload){
        state = Object.assign(state, payload)
    },

    SAVE_DATA(state, payload){
        let notFound = true
        state.pipLst.find(el => {
            if(el.id == payload.id){
                Object.assign(el, {...el, ...payload});
                notFound = false
            }
        })
        if(notFound) state.pipLst.push(payload)
    }
}

const actions = {
    fetchCfgData({ commit }, id){
        let url = govMaster + cfgApi + id
        axios.get(url)
        .then( res => {
            let data = res.data.message
            if(data.length == 0){
                data = {
                    id: id,
                    cfgNull: true,
                }
            }
            commit('SAVE_DATA', data)
        })
        .catch(err => console.log(err))
    },
    // fetctPipData({ commit }, id){
    //     let url = hyu + pipApi + id
    //     axios.get(url)
    //     .then( res => commit('SAVE_DATA', res.data))
    //     .catch(err => console.log(err))
    // }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
