<template>
    <section class="garanty">
        <BgImg :scrDesktop="garanty.banner_desk" :scrMobile="garanty.banner_mobile" altText="Imagem da garantia"/>
        <div class="garanty-content">
            <div class="garanty-wrap">
                <p class="garanty-title">{{ garanty.title }}</p>
                <p class="garanty-text">{{ garanty.texto }}</p>
            </div>

            <img :src="garantyImg" alt="Imagem selo 7 anos de garantia"/>
        </div>
    </section>
</template>
<script>
    import { mapState } from 'vuex'
    import BgImg from '../../SimpleComponents/BgImg.vue'
    export default {
        name: 'GarantyBanner',
        components:{ BgImg, },
        data(){return{
            garantyImg: require("@/assets/SELO_7_ANOS_2022_RGB-02.webp")
        }},
        computed: {
            ...mapState(['garanty']),
        } 
    }
</script>
<style lang="scss">
    @import './GarantyBanner.scss';
</style>