import * as COOKIES_CONSTANTS from "@/cookies/cookies-constants.js";
import * as COOKIES_FUNCTIONS from "@/cookies/cookies-functions.js";

let blockedList = [];
let deleteList = [];

function checkRGCookies() {
  let gTag = false;
  blockedList = [];
  deleteList = [];

  if (COOKIES_FUNCTIONS.getCookie("_rg_modal")) {
    const cookies = COOKIES_FUNCTIONS.getCookie("_rg_settings");
    let performance = false;
    let functional = false;
    if (cookies) {
      performance = cookies.split(",")[1] == "S" ? true : false;
      functional = cookies.split(",")[2] == "S" ? true : false;
    }

    if (performance) {
      gTag = true;
    } else {
      buildBlockDeleteList(COOKIES_CONSTANTS.PERFORMANCE_COOKIES);
    }

    if (functional) {
      gTag = true;
    } else {
      buildBlockDeleteList(COOKIES_CONSTANTS.TARGETING_COOKIES);
    }

    deleteBlockedCookies();
  }
  return gTag;
}

function buildBlockDeleteList(cookies) {
  cookies.forEach((el) => {
    blockedList.push(el[1]);
    el[2].forEach((e) => {
      e != "" ? deleteList.push(e) : "";
    });
  });
}

function deleteBlockedCookies() {
  deleteList.forEach((cookie) => {
    COOKIES_FUNCTIONS.getAllCookies().forEach((el) => {
      if (el.startsWith(cookie)) {
        COOKIES_FUNCTIONS.deleteCookie(el);
      }
    });
  });
}

export {
  checkRGCookies,
  deleteBlockedCookies,
  blockedList,
  deleteList,
  COOKIES_FUNCTIONS,
};
