<template>
    <div class="pipBanner">
        <BgImg :scrMobile="bannerObj.pip_header_mobile" :scrDesktop="bannerObj.pip_header_desk" :altText="'imagem ' + bannerObj.pip_name"/>
        <div class="pipBanner-content">
            <h1 class="pipBanner-content-title" v-html="bannerObj.pip_name"></h1>
            <p class="pipBanner-content-signature">{{ bannerObj.pip_signature }}</p>
            <div class="pipBanner-content-btns">
                <GovButton v-if="bannerObj.btn_test_drive"
                :displayAtag="false"
                :toRoute="getTDroute"
                btnClass="btn-secondary"
                btnText="Test Drive"
                svgItem="arrow"/>
                <GovButton
                :displayAtag="false"
                :toRoute="getCProute"
                btnClass="btn-primary"
                btnText="Pedir Proposta Comercial"
                svgItem="arrow"/>
            </div>
        </div>
    </div>
</template>
<script>
    import BgImg from '../../SimpleComponents/BgImg.vue'
    import GovButton from '../../Buttons/GovButton.vue'

    export default {
        name: 'PipBanner',
        components:{BgImg, GovButton, },
        props:{
            bannerObj: { type: Object, default: () => { return {}; } },
            pipId: { type: String},
            pipModel: { type: String},
        },
        computed: {
            getCProute(){
                return `/proposta-comercial?f=2&id=${this.pipId}&opt=0`
            },
            getTDroute(){
                return `/test-drive?id=${this.pipId}&opt=0`
            }
        }
    }
</script>
<style lang="scss">
    @import './PipBanner.scss';
</style>
