<template>
    <main>
        <SimpleBanner 
        :bannerImgMobile="geral_field.banner_campaigns_imgD" 
        :bannerImgDesktop="geral_field.banner_campaigns_imgD" 
        :bannerTitle="geral_field.cp_banner_title"/>
        <ListCampaigns 
        :objFilterCategories="campaignsFilterItems"/>
    </main>
    <!-- <div v-else>Is Loading...</div> -->
</template>
<script>
    import { mapGetters, mapState } from 'vuex'
    import SimpleBanner from '../components/Banners/SimpleBanner/SimpleBanner.vue'
    import ListCampaigns from '../components/Sections/ListCampaigns/ListCampaigns.vue'

    export default {
        name: 'Campaings',
        components: { SimpleBanner, ListCampaigns, },
        computed:{
            ...mapState(['geral_field', 'settings']),
            ...mapGetters({
                campaignsFilterItems: 'govCampaigns/getCpsFilterItems',
                // hasCampaigns: 'govCampaigns/hasCampaigns',
            }),
        },
        // created(){
        //     const settings = this.settings
        //     !this.hasCampaigns && this.$store.dispatch('govCampaigns/fetchDealerCampaigns', { api: settings.api, id: settings.siteid})
        // }
    }
</script>
<style lang="scss" scoped>
    .simpleBanner{display: none;}
</style>