import axios from "axios";

const govMaster = process.env.VUE_APP_GOV_MASTER
const apiFormParam = process.env.VUE_APP_API_FORM_PARAMS
const urlFP = govMaster + apiFormParam;

const state = () => ({
    params: {},
});

const getters = {
    geFormParams: state => key => {
        let obj = state.params;
        if(Object.entries(obj).length === 0 && obj[key]) return false
        return obj[key]
    }
}

const mutations = {
    SET_PARAMS(state, data){
        state.params = data
    }
}

const actions = {
    fetchFormParams({ commit }){
        axios.get(urlFP)
        .then( res => commit('SET_PARAMS', res.data))
        .catch(err => console.log(err))
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
