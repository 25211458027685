<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content}` : `${this.siteName}` }}</template>
    </metainfo>

    <template v-if="appReady">
        <ModalContainer>
            <component :is="modalData.component" />
        </ModalContainer>
        <Navbar/>
        <router-view/>
        <p v-show="showAnchor" :class="{topAnchor: true, active: achorClass}" @click="scrollUp"></p>
        <Footer />
    </template>

    <LoadingPage v-else />
</template>
<script>
    import { useMeta } from 'vue-meta'
    import { mapGetters, mapState } from 'vuex'
    import LoadingPage from './pages/LoadingPage.vue'
    import Navbar from './components/Navbar/Navbar.vue'
    import Footer from './components/Footer/Footer.vue'
    import ModalContainer from './components/Modals/ModalContainer.vue'
    import { handleCookies } from './mixins/handleCookies'

    // modals componens
    import CookiesModal from './components/Modals/ModalSlots/CookiesModal.vue'

    export default {
        setup(){
            useMeta({
                title: '',
                htmlAttrs: { lang: 'pt', amp: true },
                meta: [
                    { name: 'description', content: 'Governance', vmid: 'Governance' },
                ],
            })
        },
        components: { LoadingPage, Navbar, Footer, ModalContainer, CookiesModal },
        mixins: [handleCookies],
        mounted(){
            this.handleAnchorStates();
        },
        data(){return{
            showAnchor: false,
            achorClass: false,
        }},
        computed:{
            ...mapState(['siteName', 'modalData']),
            ...mapGetters({
                appReady: 'isReady',
            }),
        },
        methods:{
            scrollUp(){
                window.scrollTo(0, 0);
            },
            handleAnchorStates(){
                document.addEventListener('scroll', () => {
                    if(window.innerWidth < 992){
                        let html = document.documentElement;
                        let body = document.body;
                        let _height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
                        let scrollY = window.scrollY;
                        let wHeight = window.innerHeight;
                        let footer = document.querySelector('footer').offsetHeight;
                        let heightBreakpoint = _height - wHeight - footer;

                        this.showAnchor = (scrollY > wHeight) ? true : false;
                        this.achorClass = (scrollY > heightBreakpoint) ? true : false;
                    }
                });
            },
        }
    }
</script>
<style lang="scss">
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        text-decoration: none;
        font-family: 'HyundaiRegular';
    }

    .isActive{ overflow: hidden;}

    .topAnchor{
        position: fixed;
        right: 0;
        bottom: 0;
        height: 2.5rem;
        width: 2.5rem;
        background-color: $secondaryCol;
        z-index: 18;

        &.active{
            position: relative;
            margin-left: auto;
            margin-top: -2.5rem;
        }

        &:after{
            content: "";
            position: absolute;
            display: inline-block;
            border-color: $whiteCol;
            border-style: solid;
            border-width: 0.15em 0.15em 0 0;
            height: 0.5em;
            width: 0.5em;
            top: 1.05rem;
            right: .9rem;
            transform: rotate(-45deg);
        }
    }

    #app {
        overflow: hidden;
        @import '@/scss/main.scss';
    }
</style>
