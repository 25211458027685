<template>

    <div class="formCont orange">
        <div class="formCont-header">
            <h2> {{ formTitle }} </h2>
            <p v-show="showBackBtn && backBtn" @click="$router.go(-1)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.955" height="8.792" viewBox="0 0 16.955 8.792"><g transform="translate(-1226.545 -124.604)"><path d="M.326,16.955A.357.357,0,0,1,0,16.574V.381A.357.357,0,0,1,.326,0,.357.357,0,0,1,.651.381V16.574A.357.357,0,0,1,.326,16.955Z" transform="translate(1226.545 129.326) rotate(-90)" fill="#fff"/><path d="M8.466,4.721a.325.325,0,0,1-.23-.1L4.4.786.556,4.626a.326.326,0,1,1-.46-.46L4.165.1a.326.326,0,0,1,.461,0L8.7,4.166a.326.326,0,0,1-.23.556Z" transform="translate(1226.545 133.396) rotate(-90)" fill="#fff"/></g></svg>
                Voltar</p>
        </div>
        <form :action="forms" class="formCont-form" @submit="validadeForms($event)">
            <!-- hidden -->
            <input type="hidden" name="id" value="hyundai_vue">
            <input type="hidden" name="cfg" value="hyundai_vue_contactos">
            <input type="hidden" name="form_type" value="Hyundai TestDrive Usados">
            <input type="hidden" name="from_page" :value="formTitle">

            <input type="hidden" name="redirok" id="redirok" :value="getRouterPage('UsedOkPage') + '?form=' + formId">
            <input type="hidden" name="redirnok" id="redirnok" :value="getRouterPage('UsedErrorPage')">
            <input type="hidden" name="email_lead" :value="formParams(formKey)?.email_lead">

            <input type="hidden" name="oid_dealer" id="oid_dealer" :value="modelsObj[0]?.oidDealer">
            <input type="hidden" name="stand" id="stand" value="">
            <input type="hidden" name="id_model" id="id_model" :value="modelsObj[0]?.idvc">
            <input type="hidden" name="model" id="model" :value="modelsObj[0]?.carModel">

            <input type="hidden" name="pedido" value="Usados">
            <input type="hidden" name="matricula" :value="modelsObj[0]?.plate">
            <input type="hidden" name="version" :value="modelsObj[0]?.version">

            <input type="hidden" name="leadSource" :value="settings('lead_source')">
            <input type="hidden" name="lead_type" id="lead_type" :value="formParams(formKey)?.leadType">
            <input type="hidden" name="form_type_code" id="form_type_code" :value="formParams(formKey)?.formTypeCode">
            <input type="hidden" name="tipo_pedido" id="tipo_pedido" :value="formParams(formKey)?.tipoPedido">

            <input type="hidden" name="contactType" id="contactType" :value="formParams(formKey)?.contactType">
            <input type="hidden" name="idSubscriptionCenterOrigin" :value="idSubscriptionCenterOrigin">
            <input type="hidden" name="service_type" id="service_type" :value="formParams(formKey)?.serviceType">

            <input type="hidden" name="utm_source" value="">
            <input type="hidden" name="utm_medium" value="">
            <input type="hidden" name="utm_campaign" value="">
            <input type="hidden" name="utm_term" value="">
            <input type="hidden" name="utm_content" value="">

            <div class="colL">
                <div class="formCont-form-models">
                    <p class="divider">1. Modelo escolhido:</p>
                    <DefaultSelect :isDisabled="showBackBtn"
                    field="modelo"
                    :fieldOptions="modelsObj"
                    v-model:optSelected="currModelId"/>
                    <Image theClass="formCont-form-models-img" :srcImg="getImageIndex"/>
                </div>
                <div class="formCont-form-fields">
                    <p class="divider">2. Dados Pessoais:</p>
                    <InputText v-model:inputVal="name" :fieldObj="defaultFormFields.nameField"/>
                    <InputText v-model:inputVal="phone" :fieldObj="defaultFormFields.phoneField"/>
                    <InputText v-model:inputVal="email" :fieldObj="defaultFormFields.emailField"/>
                </div>
            </div>
            <div  class="colR">
                <div class="formCont-form-options">
                    <InputCheckbox :checkboxObj="defaultFormFields.policyField" />
                    <InputCheckbox :checkboxObj="defaultFormFields.disclaimerField" />
                </div>
                <div class="formCont-form-submit">
                    <vue-recaptcha  v-if="showRecaptcha" size="normal"  theme="light" :siteKey="sitekey"
                    class="reCapcha"
                    :id="capchaID"
                    @verify="recaptchaVerified"
                    @expire="recaptchaExpired"
                    @fail="recaptchaFailed"
                    ref="vueRecaptcha" />
                    <p class="rgErrorMessage"></p>
                    <button type="submit" class="btn-formUsados">Enviar</button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import Image from '../../SimpleComponents/Image.vue'
    import DefaultSelect from '../FormFields/ComboBox/DefaultSelect.vue'
    import InputText from '../FormFields/InputText/InputText.vue'
    import InputCheckbox from '../FormFields/InputCheckbox/InputCheckbox.vue'
    import VueRecaptcha from 'vue3-recaptcha2';
    import {formsValidation} from '../../../mixins/formsValidation'

    export default {
        name: 'TestDriveFormUsados',
        components: { Image, DefaultSelect, InputText, InputCheckbox, VueRecaptcha },
        mixins: [formsValidation],
        props:{
            formTitle: { type: String, default: 'PEDIDO DE INFORMAÇÃO DE USADOS'},
            formKey: { type: String, default: 'test_drive_used'},
            gtmEvent: { type: String },
            type: { type: String },
            formId: { type: String },
            backBtn: { type: Boolean },
        },
        data(){return{
            showBackBtn: false,
            modelsObj: [],
            currModelId: '',
        }},
        created(){
            const carId = this.$route.query?.id
            this.usedCars.lstUsedCars.find(el => {
                if(el.idCar == carId){
                    let carTitle = el.carBrand + ' ' + el.carModel;
                    this.modelsObj.push({
                        id : el.idCar,
                        text: carTitle,
                        plate: el.plateNumber,
                        version: el.carVersion,
                        oidDealer: el.oidDealer,
                        stand: el.stand,
                        carModel: el.carModel,
                        idvc: el.idvc,
                    });
                }
            });
            this.currModelId = carId;
            this.showBackBtn = !this.showBackBtn;
        },
        computed:{
            ...mapState(['defaultFormFields', 'usedCars', 'forms']),
            getImageIndex(){
                let _Model = this.usedCars.lstUsedCars.find(el => el.idCar == this.currModelId)?.carPhotos[0].fileUrl.toString();
                return _Model;
            },
        },
        methods: {
            handleGTMEvent(){
                const obj = {
                    'event': this.gtmEvent,
                    'type': this.type,
                    'dealer': `${this.siteName} - ${this.modelsObj[0]?.oidDealer}`,
                    'model': this.modelsObj[0]?.carModel || ''
                }
                window.dataLayer?.push( obj );
            },
        }
    }
</script>
<style lang="scss" scoped>
    @include respond-to(large){
        .formCont{
            width: 712px !important;
            &-form-submit{ padding: 2rem 2rem !important; }
        }
    }
</style>
