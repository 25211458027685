<template>
    <section v-if="objCampaigns.length != 0" class="hpCpSec">
        <p class="hpCpSec-title">{{ secTitle }}</p>
        <ModalBox 
        :modalContent="modalData" 
        :isOpen="openModal" 
        @close-modal="changeModalState($event)"/>
        
        <swiper ref="cpsHp" class="hpCpSwiper" :slides-per-view="1" :space-between="50" navigation :pagination="{ clickable: true }" :style="cssVars" @slideChangeTransitionEnd="handleCurrSlideImgHeight($event.el)">
            <swiper-slide v-for="(item, index) in objCampaigns" :key="index" >
                <CampaignsCard cpType="hp" :cpItem="item" :cpIndex="index" @get-index="getCurrentIndex($event)"/>
            </swiper-slide>
        </swiper>
    </section>
</template>
<script>
    import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import "swiper/swiper-bundle.css";
    SwiperCore.use([Navigation, Pagination, A11y]);
    import ModalBox from '../../Modals/ModalBox.vue'
    import CampaignsCard from '../../Cards/CampaignsCard/CampaignsCard.vue'
    import { handleModalFunctions } from '../../../mixins/handleModalFunctions'

    export default {
        name: 'CampaignsHp',
        components:{ Swiper, SwiperSlide, CampaignsCard, ModalBox},
        mixins: [handleModalFunctions],
        props:{
            secTitle:{ type: String, default: 'Novidades em campanha' },
            objCampaigns: { type: Object, default: null},
        },
        data(){return{
            height: 0,
        }},
        mounted(){
            let el = this.$refs.cpsHp?.$el
            this.handleCurrSlideImgHeight(el)

            window.addEventListener('resize',() => {
                this.handleCurrSlideImgHeight(el)
            });
        },
        computed: {
            cssVars() {
                return {
                    '--height': this.height + 42 + 'px', // n dynamic --> CSS <-- vars
                }
            },
        },
        methods: {
            handleCurrSlideImgHeight(el){
                let currSlide = el?.querySelector('.swiper-slide-active')
                if(currSlide){
                    let height = currSlide.querySelector('.cardImg > img')?.offsetHeight
                    if(height) this.height = height
                }
            },
        },
    }
</script>
<style lang="scss">
    @import './CampaignsHp.scss';
</style>