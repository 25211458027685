<template>
    <div :class="isCats? 'newsSidebar-categories': 'newsSidebar-tematics' ">
        <h3> {{ isCats ? 'Categorias' : 'Temáticas populares' }} </h3>
        <p
        v-for="(item, i) in filterCats" :key="i"
        :class="{ active: item == selected}"
        @click="handleClick($event, item)">
            {{ item }}
        </p>
        <span v-show="displayBtn" @click="showAllFields">{{ showAllCats ? 'Ver menos' : 'Ver mais' }}</span>
    </div>

</template>
<script>
    export default {
        name: 'NewsCats',
        props:{
            selected: { type: [ Boolean, String ], default: false },
            isCats: {type: Boolean, default: true}, // is categoria or tematica
            startRows: {type: Number, default: 6}, // filter starter length
            objOptions: { type: Object, dfault: () => { return {}; }}, // obj with data
        },
        mounted(){
            const cat = this.$route.query.cat;
            if((cat != null) && (this.isCats)) this.updateSelected = cat

            if(this.objOptions.length <= this.startRows){
                this.showAllCats = true;
                this.displayBtn = false;
            }
        },
        data(){ return{
            displayBtn: true,
            showAllCats: false,
        }},
        computed:{
            filterCats(){
                return this.showAllCats ? this.objOptions : this.objOptions.slice( 0, this.startRows )
            },
            updateSelected: {
                get(){ return this.selected },
                set(val){  this.$emit('update:selected', val) },
            }
        },
        methods:{
            handleClick(e, item){
                const val = this.selected == item ? false : item
                this.updateSelected = val
                this.$emit('update:selected', val);
                this.isCats && this.$emit('search-news', {
                    cat: e.target.textContent
                });
            },
            showAllFields(){
                this.showAllCats = !this.showAllCats
            }
        },
    }
</script>