<template>
    <div :class="btnClass">
        <a v-if="displayAtag" 
        :href="btnLink"
        :target="openNewTab ? '_blank' : '_self'">{{ btnText }}</a>
        <router-link v-else :to="toRoute">{{ btnText }}</router-link>
        <SvgList :svgItem="svgItem"/>
    </div>
</template>
<script>
    import SvgList from '../SimpleComponents/SvgList.vue'
    
    export default {
        name: 'GovButton',
        components:{SvgList},
        props:{
            displayAtag: { type: Boolean, default: true},
            openNewTab: { type: Boolean, default: true},
            btnClass: { type: String, default: 'btn-primary'},
            btnLink: { type: [String, Boolean], default: '#'},
            btnText: { type: String, default: 'Saber mais'},
            svgItem: { type: String, default: ''},
            toRoute: { type: [String, Object], default: () => { return {}; }}
        },
    }
</script>