import { createStore } from 'vuex'
import axios from 'axios'

// import modules
import usedCars from './modules/usedCars';
import formParams from './modules/formParams';
import govCampaigns from './modules/govCampaigns';
import modalData from './modules/modalData';
import pipData from './modules/pipData';

// env vars
const govMaster = process.env.VUE_APP_GOV_MASTER
const apiDealerLst = process.env.VUE_APP_API_DEALERS_LST

export default createStore({
    state: {
        govReady: false,
        hyuReady: false,

        banners: [],
        garanty: [],
        geral_field: {},
        forms: '', // 'https://demowapp.rigorcg.pt/shared/MainForms',
        models: [],
        news: [],
        newsCats: [],
        pips: [],
        isGettingCars: true,
        dealerData: [],
        dealers: [],
        linkConfigurador: '',
        siteName: 'Governance',
        dealerSellsUsed: true,
        showroom: 'HC00010001', //services
        posVenda: 'HC00010003', //services
        dealerHasAfterSale: true,
        cookieModal: '',
        cookieSettings: '',
    },
    getters:{
        getSocials: state => {
            let check = (obj) => {
                for (var key in obj) if(obj[key] !== null && obj[key] != "") return true;
                return false;
            }
            let GRS = state.geral_field.gov_socials;
            return (check(GRS)) ? GRS : state.geral_field.socials
        },
        get_oidDealerData: state => param =>{
            let urlParam = `?lead_source=${state.settings.lead_source}&dealer=`;

            if(param === 'parent'){
                let _oiddealer = state.dealers?.map(el => el.oidParent)
                return urlParam + [...new Set(_oiddealer)];
            }
            return urlParam + this.dealers?.map(el => el.objectId)
        },
        getSettings: state => key => {
            return key ? state.settings[key] : state.settings
        },
        isReady: state => {
            return state.govReady && state.hyuReady
        }
    },
    mutations: {
        UPDATE_STATE(state, payload){
            state = Object.assign(state, payload)
        },

        UPDATE_GERAL_FIELDS_BANNERS(state, payload){
            if(payload.banners?.length != 0) state.banners = [ ...state.banners, ...payload.banners ]
            state.geral_field = Object.assign(state.geral_field, payload.geral_field)
        },

        SET_NEWSCATS_TEMATICS(state, payload){
            let _array = { categorias : [], tematicas : [] };
            payload?.forEach(el => {
                if(el.categorias.length > 0) el.categorias.forEach(el => (!_array['categorias'].includes(el)) && _array['categorias'].push(el));
                if(el.tematicas.length > 0) el.tematicas.forEach(el => (!_array['tematicas'].includes(el)) && _array['tematicas'].push(el));
            });

            state.newsCats = _array;
        },

        SET_DEALERS(state, payload){
            let afterSaleObjID = state.posVenda
            state.dealers = payload
            let showCpsAfterSale = payload.some(el => el.services.some(el => el.objectId == afterSaleObjID))
            state.dealerHasAfterSale = showCpsAfterSale
        },
    },
    actions: {
        // concessionarios
        fetchDealers({ commit }, dealerNif){
          let fullUrl = govMaster + apiDealerLst + dealerNif;
          axios.get( fullUrl )
          .then( res => commit('SET_DEALERS', res.data.message))
          .catch(errors => console.log('erro', errors))
        },
    },
    modules: {
        usedCars,
        formParams,
        govCampaigns,
        modalData,
        pipData,

// filtro usados
        selectedFilterOptions: {
            namespaced: true,
            state:{
                options: {
                    selectedModel: '',
                    selectedFuel: '',
                    selectedYear: '',
                    selectedPrice: '',
                    selectedKms: '',
                },
                totalcars: '',
            },
            mutations:{
                UPDATE_OPTIONS(state, values){
                    Object.assign(state.options, values)
                },
                UPDATE_COUNT(state, value){
                    state.totalcars = value;
                }
            },
        },

// default objs -> forms field
        defaultFormFields:{
            namespaced: true,
            state:{
                nameField: {
                    'label': 'Nome completo*',
                    'type': 'text',
                    'name': 'nome',
                    'id': 'nome',
                    'maxlength' : '255',
                    'placeholder': 'Nome completo',
                    'value': '',
                    'roundFormat': false,
                    'dataError': 'Indique um nome válido'
                },
                phoneField: {
                    'label': 'Telefone *',
                    'name': 'telefone',
                    'id': 'telefone',
                    'maxlength' : '9',
                    'placeholder': '900000000',
                    'value': '',
                    'roundFormat': false,
                    'dataError': 'Indique um número de telefone'
                },
                emailField: {
                    'label': 'Email *',
                    'name': 'email',
                    'id': 'email',
                    'maxlength' : '255',
                    'placeholder': 'exemplo@exemplo.com',
                    'value': '',
                    'roundFormat': false,
                    'dataError': 'Indique um email válido'
                },
                policyField: {
                    'name': 'accept',
                    'id': 'accept',
                    'value': 'S',
                    'displayAsHtml': true,
                    'content': 'Li e aceito a <a href="https://www.hyundai.pt/politica-de-privacidade/" target="_blank">Política de privacidade. *</a>',
                    'dataError': 'Tem que aceitar a Política de Privacidade'
                },
                disclaimerField: {
                    'name': 'disclaimer',
                    'id': 'disclaimer',
                    'value': 'S',
                    'displayAsHtml': false,
                    'content': 'Gostaria de receber comunicações de marketing, nomeadamente promoções, eventos, novos produtos e serviços Hyundai, seja através de e-mail, telefone ou SMS e no veículo (se suportado), por forma a personalizar e a melhorar a minha experiência enquanto Cliente. * Campos de preenchimento obrigatório.',
                    'dataError': 'Preencha o reCaptcha',
                },
                msg: {
                    'label': 'Mensagem*',
                    'name': 'msg',
                    'id': 'msg',
                    'placeholder': 'Escreva aqui a sua mensagem...',
                    'dataError': 'Indique a sua mensagem'
                },
                plateField: {
                    'label': 'Matrícula*',
                    'type': 'text',
                    'name': 'matricula',
                    'id': 'matricula',
                    'maxlength' : '8',
                    'placeholder': 'AA-00-00',
                    'value': '',
                    'roundFormat': false,
                    'pattern': '([A-Z-]|[0-9-]){8}',
                    'dataError': 'Exemplo de matricula: AA-00-AA'
                },
            }
        },
    },
})
