<template>
    <div :class="accordionClass">
        <p :class="setClass('-header')" @click="handleAccordion($event)"> {{ itemTitle }} </p>
        <div :class="setClass('-content')">
            <Image :theClass="accordionClass + '-content-img'" :srcImg="itemImg" />
            <p><b>Morada: {{ itemStreet }} </b></p>
            <p v-if="itemContacts.length > 0"> <b>Telefone: </b> {{ itemContacts[0].contactList[0]?.value }} </p>
            <!--<p><b>Cordenadas: </b> {{ itemCoordY }}"N {{ itemCoordX }}"W </p>-->
            <p v-if="itemContacts.length > 0"><b>Email: </b> {{ itemContacts[0].contactList[1]?.value }} </p>
            <div :class="accordionClass + '-content-btns'">
                <GovButton :displayAtag="false"
                :toRoute="{ name: 'InformationRequest', params: { displayGoBack: true, theDealer: itemId }}"
                btnClass="btn-secondary" btnText="Contactar" svgItem="arrow" />
                <SimpleButton btnClass="btn-secondary" btnText="Obter direções" svgItem="arrow" @click="goToMaps" />
            </div>
        </div>
    </div>
</template>
<script>
    import Image from '../SimpleComponents/Image.vue'
    import GovButton from '../Buttons/GovButton.vue'
    import SimpleButton from '../Buttons/SimpleButton.vue'

    export default {
        name: 'DealerAccordonItem',
        components:{ Image, GovButton, SimpleButton },
        props:{
            isAct:{ type: Boolean, default: false },
            accordionClass: {type: String, default: 'dealersPage-accordion-item'},
            itemId: {type: [String, Number]},
            idItr: {type: [String, Number]},
            itemTitle: {type: String},
            itemImg: {type: String},
            itemStreet: {type: String},
            itemCoordX: {type: String},
            itemCoordY: {type: String},
            itemContacts: {type: Object, default: () => { return {}; }},
        },
        data(){return{
            defaultImg: require('@/assets/dealerDefaultPhoto.webp'),
        }},
        methods:{
            handleAccordion(e){
                this.$emit('update:imgSrc', this.itemImg );
                this.$emit('update:setActiveItem', this.idItr);

                let accordionheader = document.querySelectorAll('.' + this.accordionClass + '-header');
                let accordionContent = document.querySelectorAll('.' + this.accordionClass + '-content');

                if(!e.target.classList.contains('active')) [...accordionContent, ...accordionheader].forEach(el => el.classList.remove('active'));
                else this.$emit('update:setDefaultImg', this.defaultImg)

                e.target.classList.toggle('active');
                e.target.nextElementSibling.classList.toggle('active');
            },
            goToMaps(){
                window.open('https://www.google.com/maps/search/?api=1&query=' + this.itemCoordY + '%2C' + this.itemCoordX);
            },
            setClass(str){
                let clasStr = this.accordionClass + str;
                if(this.isAct) return clasStr + ' active';
                return clasStr;
            }
        },
    }
</script>
