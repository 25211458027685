<template>
    <section class="pipDescription">
        <div class="pipDescription-wrap">
            <div class="pipDescription-left">
                <h2> {{ title }} </h2>
                <h3 v-html="text"></h3>
            </div>
            <div v-if="false" class="pipDescription-right">
                <!-- <div v-if="price" class="row">
                    <p>Valor desde</p>
                    <b @click="handleModalState"> 
                        {{ format_thousands( price) }} € 
                        <svg v-show="modalContent" xmlns="http://www.w3.org/2000/svg" width="14.125" height="14.124" viewBox="0 0 14.125 14.124">
                            <g id="Group_7386" data-name="Group 7386" transform="translate(-26.499 -33.385)">
                                <path id="Path_16749" data-name="Path 16749" d="M33.561,33.885a6.562,6.562,0,1,0,6.562,6.562A6.562,6.562,0,0,0,33.561,33.885Z" fill="none" stroke="#898989" stroke-miterlimit="10" stroke-width="1"/>
                                <g id="Group_6935" data-name="Group 6935" transform="translate(32.412 35.695)">
                                <path id="Path_16750" data-name="Path 16750" d="M48.115,48.74a.993.993,0,1,1-1.986,0V44.573a.993.993,0,0,1,1.986,0Zm-.993-6.212a1.151,1.151,0,0,1-1.149-1.149,1.164,1.164,0,0,1,1.149-1.149,1.149,1.149,0,0,1,0,2.3Z" transform="translate(-45.973 -40.23)" fill="#898989"/>
                                </g>
                            </g>
                        </svg>
                    </b>
                </div>
                <div v-if="price" class="row">
                    <p>Potência</p>
                    <b>{{ showPowerText() }}</b>
                </div>
                <div v-if="fuelAverage" class="row">
                    <p>Eficiência Energética</p>
                    <b>{{ fuelAverage }} l/100km</b>
                </div> -->
            </div>
        </div>
    </section>
    <ModalBox :isOpen="modalState" :modalContent="modalContent" @close-modal="handleModalState" />
</template>
<script>
    import ModalBox from '../../Modals/ModalBox.vue'    
    import {mainFunctions} from '../../../mixins/mainFunctions'

    export default {
        name: 'PipDescription',
        components: { ModalBox },
        mixins: [mainFunctions],
        props:{
            title: {type: [String, Number]},
            text: {type: [String, Number]},
            price: {type: [String, Number]},
            power: {type: [String, Number]},
            powerkw: {type: [String, Number]},
            fuelAverage: {type: [String, Number]},
            modalContent: {type: String, default: null},
        },
        data(){return{
            modalState: false,
        }},
        methods:{
            handleModalState(){
                if(this.modalContent){
                    this.modalState = !this.modalState;
                    document.body.classList.toggle('isActive');
                }
            },
            showPowerText(){
                let pw = this.power;
                let pwKw = this.powerkw;
                if(pwKw != ('' && 0)){
                    return pw + ' CV | ' + pwKw + ' KW';
                }else{
                    return this.power + ' CV';
                }
            }
        }
    }
</script>
<style lang="scss">
    @import './PipDescription.scss';
</style>