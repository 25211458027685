<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="151.246"
    height="20.801"
    viewBox="0 0 151.246 20.801"
  >
    <g transform="translate(-1153.754 -55.271)">
      <path
        d="M192.568,5.5h-10.5v14.93h3.553V8.737h5.7a.738.738,0,0,1,.753.748V20.434h3.549V8.557a3.036,3.036,0,0,0-.819-2.229A3.031,3.031,0,0,0,192.568,5.5Z"
        transform="translate(1068.833 52.704)"
        fill="#002c5f"
      />
      <path
        d="M100.127,5.5v5.688h-6.45V5.5H90.123v14.93h3.555v-6h6.45v6h3.553V5.5h-3.553Z"
        transform="translate(1111.717 52.704)"
        fill="#002c5f"
      />
      <path
        d="M129.784,5.5l-3.719,5.912L122.351,5.5h-4.194l6.136,9.759v5.171h3.551V15.263L133.98,5.5h-4.2Z"
        transform="translate(1098.642 52.704)"
        fill="#002c5f"
      />
      <path
        d="M160.491,5.5V16.448a.738.738,0,0,1-.756.752h-5.7V5.5h-3.554v14.93h10.5a2.833,2.833,0,0,0,3.054-3.05V5.5h-3.548Z"
        transform="translate(1083.562 52.704)"
        fill="#002c5f"
      />
      <path
        d="M224.147,5.5h-10.5v14.93h10.5a2.838,2.838,0,0,0,3.05-3.05V8.557a3.027,3.027,0,0,0-.823-2.231A3.029,3.029,0,0,0,224.147,5.5Zm-.5,4v6.919a.753.753,0,0,1-.759.772h-5.7V8.737h5.673A.744.744,0,0,1,223.647,9.509Z"
        transform="translate(1054.103 52.704)"
        fill="#002c5f"
      />
      <path
        d="M247.981,5.5a3.01,3.01,0,0,0-2.228.824,3.014,3.014,0,0,0-.822,2.229V20.434h3.545V15.815h6.455v4.619h3.549V5.5Zm6.95,3.233v3.845h-6.455V9.5a.754.754,0,0,1,.758-.759Z"
        transform="translate(1039.509 52.704)"
        fill="#002c5f"
      />
      <rect
        width="3.553"
        height="14.93"
        transform="translate(1301.448 58.208)"
        fill="#002c5f"
      />
      <g transform="translate(1153.754 55.271)">
        <g transform="translate(0 0)">
          <path
            d="M20.311,20.8c11.218,0,20.312-4.656,20.312-10.4S31.529,0,20.311,0,0,4.656,0,10.4,9.094,20.8,20.311,20.8Zm3.823-5.1c-.338.787-.921,2.465-2.261,3.131a3.391,3.391,0,0,1-1.355.312c-.1,0-.172,0-.2,0a35.33,35.33,0,0,1-10.159-1.41c-.025-.006-.092-.034-.113-.042-.269-.09-.407-.216-.407-.368a.452.452,0,0,1,.166-.33c.041-.042.1-.094.184-.164a23.775,23.775,0,0,1,6.532-3.5A32.145,32.145,0,0,1,21.3,11.806C22.33,11.589,26.154,11,24.134,15.7ZM34.4,5.214a.414.414,0,0,1,.343-.243.7.7,0,0,1,.423.139c2.386,1.468,3.8,3.3,3.8,5.286,0,3.588-4.612,6.67-11.2,8.018-.424.083-.7.081-.8-.026a.254.254,0,0,1,0-.322,1.694,1.694,0,0,1,.176-.244c3.592-4.233,6.326-10.475,7.1-12.278C34.293,5.418,34.351,5.3,34.4,5.214ZM16.548,5.081c.339-.783.923-2.461,2.261-3.128a3.373,3.373,0,0,1,1.355-.311c.1,0,.172,0,.2,0A35.289,35.289,0,0,1,30.529,3.053c.021.006.089.033.11.041.269.09.408.213.408.368a.462.462,0,0,1-.168.329c-.041.041-.1.094-.185.163a23.722,23.722,0,0,1-6.531,3.5,32.064,32.064,0,0,1-4.779,1.524C18.352,9.193,14.529,9.775,16.548,5.081Zm-3.684-2.7c.425-.082.7-.081.8.027a.27.27,0,0,1,0,.32,1.973,1.973,0,0,1-.178.243C9.9,7.2,7.165,13.449,6.387,15.253c-.058.124-.117.247-.165.328a.408.408,0,0,1-.34.244.73.73,0,0,1-.424-.138c-2.383-1.469-3.8-3.3-3.8-5.288C1.657,6.811,6.269,3.727,12.865,2.381Z"
            transform="translate(0 0)"
            fill="#002c5f"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
