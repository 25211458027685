export const mainFunctions =  {
    data(){return{
        daysToBeRecent: 14,
    }},
    computed:{
        isRecentCar(){
            let dtCreated = new Date(this.carObj.dtRelease);
            let now =  new Date();
            const diffTime = now - dtCreated;
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            return (diffDays > this.daysToBeRecent) ? false : true;
        },
    },
    methods:{
        handleRouteBlanks(val){
            return val.toLowerCase().replace(/ /g, '-');
        },
        format_thousands(value){
            if(!value) return false
            const rounded = Math.round(value * 100) / 100;
            return rounded
                .toString()
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
    }
}