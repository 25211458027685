import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../pages/HomePage.vue'
import PipDetails from '../pages/PipDetails.vue'
import Campaigns from '../pages/Campaigns.vue'
import CampaignDetails from '../pages/CampaignDetails.vue'
import NewsPage from '../pages/NewsPage.vue'
import NewsDetails from '../pages/NewsDetails.vue'
import UsadosPage from '../pages/UsadosPage.vue'
import UsadosDetails from '../pages/UsadosDetails.vue'
import DealersPage from '../pages/DealersPage.vue'

import FormResponse from '../pages/FormResponse.vue'

import page404 from '../pages/404.vue'
import FormPage from '../pages/FormPage.vue'
// import InformationRequest from '../pages/InformationRequest.vue'
import CookiesPage from '../pages/CookiesPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/modelos/:title',
    name: 'Modelos',
    component: PipDetails,
  },
  {
    path: '/campanhas',
    name: 'Campanhas',
    component: Campaigns,
  },
  {
    path: '/campanhas/:title',
    name: 'DetalhesCapanhas',
    component: CampaignDetails,
  },
  {
    path: '/noticias',
    name: 'Noticias',
    component: NewsPage,
  },
  {
    path: '/noticias/:title',
    name: 'DetalhesNoticias',
    component: NewsDetails,
  },
  {
    path: '/usados',
    name: 'HomeUsados',
    component: UsadosPage,
  },
  {
    path: '/usados/:plate',
    name: 'DetalhesUsados',
    component: UsadosDetails,
  },
  {
    path: '/concessionarios',
    name: 'Concessionarios',
    component: DealersPage,
  },
  {
    path: '/ok-page',
    name: 'OkPage',
    component: FormResponse,
    props:{
      isDefault: true,
      isOk: true,
    }
  },
  {
    path: '/erro-page',
    name: 'ErrorPage',
    component: FormResponse,
    props:{
      isDefault: true,
      isOk: false,
    }
  },
  {
    path: '/ok-usados',
    name: 'UsedOkPage',
    component: FormResponse,
    props:{
      isDefault: false,
      isOk: true,
    }
  },
  {
    path: '/erro-usados',
    name: 'UsedErrorPage',
    component: FormResponse,
    props:{
      isDefault: false,
      isOk: false,
    }
  },
  {
    path: '/test-drive',
    name: 'TestDrivePage',
    component: FormPage,
  },
  {
    path: '/saber-mais',
    name: 'SaberMais',
    component: FormPage,
  },
  {
    path: '/proposta-comercial',
    name: 'CommercialProposal',
    component: FormPage,
  },
  {
    path: '/pedido-de-informacoes',
    name: 'InformationRequest',
    // component: InformationRequest,
    component: FormPage,
  },
  {
    path: '/marcacao-de-servico',
    name: 'APVrequest',
    component: FormPage,
    beforeEnter: (to) => {
      to.query = { f: 4 }
    },
  },
  {
    path: '/politica-cookies',
    name: 'CookiesPage',
    component: CookiesPage,
  },
  {
    path: '/:catchAll(.*)',
    // path: '/:pathMatch(.*)*',
    name: '404',
    component: page404,
  }
]

const router = createRouter(
  {
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {el: to.hash}
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

// router.beforeEach(function (to, from, next) {
//   setTimeout(() => {
//       window.scrollTo(0, 0);
//   }, 100);
//   next();
// });

export default router
