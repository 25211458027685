<template>
    <!-- <router-link v-if="dataObj.news_type == 'noticias'" :to="'/noticias/' + dataObj.route"  class="smallNewsCard"> -->
    <div @click="visitNews" class="smallNewsCard">
        <Image theClass="smallNewsCard-img" :srcImg="dataObj.image" />
        <div class="smallNewsCard-content">
            <p class="smallNewsCard-content-title"> {{ dataObj.categorias[0] }} </p>
            <p class="smallNewsCard-content-text"> {{  dataObj.title }}</p>
        </div>
    </div>
    <!-- </router-link> -->
</template>
<script>
    import Image from '../../SimpleComponents/Image.vue'

    export default {
        name: 'PopularNewsCard',
        components: { Image, },
        props: {
            dataObj: { type: Object, default: () => { return {}; }},
        },
        updated(){
            this.visitNews();
        },
        methods:{
            visitNews(){
                if(this.dataObj.news_type == 'noticias'){
                    this.$router.push('/noticias/' + this.dataObj.route);
                }else{
                    window.open(this.dataObj.link)
                }
            }
        }
    }
</script>
<style lang="scss">
    @import './PopularNewsCard.scss';
</style>