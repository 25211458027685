<template>
    <main>
        <NewsContent :newsObj="news.slice(0,4)" :objCurrNews="findCurrNews" :objFilterNews="newsCats" />
    </main>
</template>
<script>
    import { mapState } from 'vuex'
    import NewsContent from '../components/Sections/NewsContent/NewsContent.vue'

    export default {
        name: 'NewsDetails',
        components: { NewsContent, },
        computed:{
            ...mapState(['news', 'newsCats']),
            findCurrNews(){
                let routeTitle = this.$route.params.title;
                const currentNews = this.news.filter( el => el.route === routeTitle);
                return currentNews[0];
            }
        },
        created(){ document.getElementById('newsLink').classList.add('active'); },
        unmounted(){ document.getElementById('newsLink').classList.remove('active'); }
    }
</script>