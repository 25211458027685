<template>
    <ModalSwiper v-model:showModal="showModal" :swiperImgs="imgArray" :starterSlide="slideToNum" />
    <section :class="galClass + '-desktop'">
        <Image v-for="(item, index) in desktopImgs" :key="index"
        :theClass="galClass + '-desktop-item'" :srcImg="item"
        @click="openModal(index)"/>
    </section>
    <section v-show="imgArray?.[0]" class="galery-mobile">
        <Image v-show="!isPipGal" theClass="galery-mobile-mainItem" :srcImg="imgArray[0]"/>
        <swiper
        class="swiperMobile"
        :slides-per-view="isPipGal ? 1 : 2"
        :space-between="8"
        :navigation="isPipGal ? true : false"
        :pagination="{ clickable: true }">
            <swiper-slide v-for="(item, index) in getImgsList" :key="index">
                <Image theClass="galery-mobile-item" :srcImg="item"/>
            </swiper-slide>
        </swiper>
    </section>
</template>
<script>
    import Image from '../../SimpleComponents/Image.vue'
    import ModalSwiper from '../../Modals/ModalSwiper.vue'
    import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import "swiper/swiper-bundle.css";
    SwiperCore.use([ Navigation, Pagination, A11y]);

    export default {
        name: 'Galery',
        components: { Image, Swiper, SwiperSlide, ModalSwiper },
        props: {
            imgArray: {type: Array, default: () => { return []; }},
            maxImgs: {type: Number, default: 5},
            galClass: { type: String, default: 'galery'}
        },
        beforeMount(){
             if(this.galClass == 'pipGalery') this.isPipGal = true
        },
        data(){
            return{
                showModal: false,
                slideToNum: 1,
                isPipGal: false,
            }
        },
        computed:{
            getImgsList(){
                if(this.isPipGal) return this.imgArray
                const srcImgs = this.imgArray.filter((el, index) =>{ return index > 0});
                return srcImgs;
            },
            desktopImgs(){
                let _arr = [];
                for(let i = 0; i<this.maxImgs; i++){
                    if(this.imgArray[i] != null) _arr.push(this.imgArray[i])
                }
                return _arr;
            }
        },
        methods:{
            openModal(i){
                this.slideToNum = i
                this.showModal = !this.showModal;
                document.body.classList.add('isActive');
            }
        }
    }
</script>
<style lang="scss">
    @import './Galery.scss';
</style>