<template>
    <div v-show="modalData.openModal" class="modal">
        <div class="modal-blend" @click="modalData.bgClose ? closeModal : false "></div>
        <div  :class="modalData.modalClass">
            <div v-if="modalData.showHeader" class="modal-content-header" >
                <h2> {{ modalData.title }} </h2>
                <svg @click="closeModal" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#1C1B1B"/>
                </svg>
            </div>
            <div class="modal-content-wrap">
                <div id="modalWrapper" :class="modalData.contentClass">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';

    export default {
        name: 'ModalContainer',
        provide:{ page: 'Modal'},
        updated(){
            document.getElementById('modalWrapper').scrollTop = 0;
        },
        computed:{
            ...mapState(['modalData']),
        },
        methods:{
            ...mapMutations({
                closeModals: 'modalData/UPDATE_STATE',
            }),
            closeModal(){
                this.closeModals({
                    openModal: false,
                    showHeader: false,
                    modalClass: 'modal-content',
                });
                // document.body.classList.remove('isActive')
            },
        },
    }
</script>
<style lang="scss" scoped>
    .modal-center{
        position: fixed;
        background-color: #ffffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: min(90%, 32rem);
        padding: 2rem;
        isolation: auto;
        z-index: 33;

        .modal-content-wrap{
            position: relative;
            overflow-y: scroll;
            max-height: 500px;
            padding: 0 1.25rem;

            // &::-webkit-scrollbar{ display: none;}

            &::-webkit-scrollbar-track{
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar{
                width: 6px;
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {  background-color: $primaryCol; }
        }
    }
</style>