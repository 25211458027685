<template>
    <main class="formLayout">
        <Image theClass="formLayout-img" :srcImg="pageImg"/>
        <section class="formLayout-content">
            <component
            :is="getFormData.currComp"
            :formTitle="getFormData.formTitle"
            :formKey="getFormData.formKey"
            :gtmEvent="getFormData.gtmEvent"
            :type="getFormData.type"
            :formId="getFormData.formId"
            :backBtn="backBtn"
            />
        </section>
    </main>
</template>
<script>
    import { mapState } from 'vuex'
    import Image from '../components/SimpleComponents/Image.vue'
    import TestDriveForm from '../components/Forms/FormLst/TestDriveForm.vue'
    import TestDriveFormUsados from '../components/Forms/FormLst/TestDriveFormUsados.vue'
    import AfterSaleForm from '../components/Forms/FormLst/AfterSaleForm.vue'

    export default {
        name: 'FormPage',
        components: { Image, TestDriveForm, TestDriveFormUsados, AfterSaleForm },
        data(){return{
            pageImg: '',
            backBtn: true,
            formKey: 0,
            formData: [
                /*  formId -> FormResponse.vue msg type:
                    0 - pedido de informação
                    1 - test-drive
                    2 - proposta comercial
                    3 - serviços apos venda (apv)
                */
                {
                    formId: 1,
                    currComp: "TestDriveForm",
                    formKey: "test_drive",
                    formTitle: "PEDIDO DE TEST DRIVE",
                    gtmEvent: 'eec.test_drive',
                    type: 'novos'
                }, {
                    formId: 0,
                    currComp: "TestDriveFormUsados",
                    formKey: "test_drive_used",
                    formTitle: "PEDIDO DE INFORMAÇÃO DE USADOS",
                    gtmEvent: 'eec.pedido_informacao',
                    type: 'usados'
                }, {
                    formId: 2,
                    currComp: "TestDriveForm",
                    formKey: "commercial_proposal_novos",
                    formTitle: "PEDIDO DE PROPOSTA COMERCIAL",
                    gtmEvent: 'eec.proposta_comercial',
                    type: 'novos'
                }, {
                    formId: 2,
                    currComp: "TestDriveFormUsados",
                    formKey: "commercial_proposal_used",
                    formTitle: "PEDIDO DE PROPOSTA COMERCIAL DE USADOS",
                    gtmEvent: 'eec.proposta_comercial',
                    type: 'usados'
                },
                {
                    formId: 3,
                    currComp: "AfterSaleForm",
                    formKey: "after_sale_service",
                    formTitle: "Marcação de Serviço",
                    gtmEvent: 'eec.marcacao_servico',
                    type: 'apos-venda'
                },
            ],
        }},
        created(){
            this.setCurrForm()

            /** disable back button, if user will open form in new tab */
            if(window.history?.length == 1){
                this.backBtn = false
            }
        },
        updated(){
            this.setCurrForm()
        },
        computed:{
            ...mapState(['geral_field']),
            getFormData(){
                return this.formData[this.formKey];
            }
        },
        methods:{
            setCurrForm(){
                const formKey = this.$route.query?.f
                let imgKey = ''

                switch(formKey){
                    case 1:
                    case 3:
                        imgKey = 'form_test_drive_usados'
                        break
                    case 4: imgKey = 'form_apv_img' /** form aram 4 is defined inside "router/index.js" */
                        break
                    default: imgKey = 'form_test_drive'
                }

                this.formKey = (formKey) ? formKey : 0;
                this.pageImg = this.geral_field[ imgKey ]
            }
        }
    }
</script>
<style lang="scss" scoped>
    .formLayout{
        width: 100%;
        position: relative;

        &-img{
            position: relative;
            width: 100%;
            height: 160px;
            margin-bottom: 40px;
        }

        & + footer{ display:none}
    }

    @include respond-to(large){
        .formLayout{
            min-height: calc(100vh - 98px);
            display: flex;
            align-items: center;

            &-img{
                margin-bottom: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                height: 100%;
                width: calc(100% - 388px);
            }
            &-content{
                position: relative;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
            }

            .formCont{ margin: 3rem 0;}
        }
    }

    @include respond-to(extralarge){ .formLayout-img{
        width: calc(100% - (( 100% - 1170px) / 2 ) - 356px);
    }}
</style>
