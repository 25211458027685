<template>
    <div class="colors360mobile">
        <swiper
        class="swiper360mobile"
        :breakpoints="swiperOptions"
        navigation>
            <swiper-slide v-for="(item, i) in colors" :key="i"
            v-show="item.externalColorImages360.length != 0">
                <p :class="{__color360image: true, active: item.id == colorId || i == 0 }"
                :style="'background-image: url(' + setBgImg(item.colorchip) + ')'"
                @click="handleColor($event, item.designation, item.id)"
               ></p>
            </swiper-slide>
        </swiper>
    </div>
    <h5>{{ currColor }}</h5>
</template>
<script>
    import SwiperCore, { Navigation, A11y } from "swiper";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import "swiper/swiper-bundle.css";
    SwiperCore.use([ Navigation, A11y]);
    import { breakPointsPips360 } from '../../mixins/swiperBreakPoints'

    export default {
        name: 'Pip360colorsMobile',
        inheritAttrs: false,
        components: { Swiper, SwiperSlide },
        mixins: [breakPointsPips360],
        props:{
            colors: { type: Object, default: () => { return {}; }},
            colorId: {type: Number},
        },
        data(){return{
            defaultImgChip: require('../../assets/nopicture_38.webp'),
            currColor: 'Color',
        }},
        methods:{
            handleColor(e, color, colorId){
                let dots = document.querySelectorAll('.__color360image');
                [...dots].forEach(el => el.classList.remove('active'))
                e.target.classList.toggle('active')
                this.currColor = color;
                this.$emit('update:colorId', colorId);
                this.$emit('change-color', colorId);
            },
            setBgImg(colorchip){
                if(colorchip != ''){
                    return colorchip;
                }else{
                    return this.defaultImgChip;
                }
            }
        }
    }
</script>
<style lang="scss">
    .colors360mobile{
        display: block;
        background-color: $whiteCol;
        padding: .5rem 1rem;
        border-radius: 5rem;

        & + h5{
            display: block;
            padding: 1rem 0 2rem;
            @include mixin_text_content($textAlign: center, $font: $mediumFont)
        }
    }
    .swiper360mobile{ border-radius: 5rem;
        .swiper-button-prev{ left: 0 !important;}
        .swiper-button-next{ right: 0 !important; }
        .swiper-button-prev:after,
        .swiper-button-next:after{ color: $greyCol; }
        .swiper-wrapper{ padding: 0 1rem; }
        p{
            position: relative;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 2rem;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            &.active:after{
                content: '';
                position: absolute;
                width: .5rem;
                height: .5rem;
                background-color: $whiteCol;
                border-radius: 2rem;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
</style>