<template>
    <section v-show="showSection" class="viewer360deg">
        <div class="viewer360deg-content">
            <p class="viewer360deg-content-title"> {{ title }} </p>
            <div class="viewer360deg-content-holder"
            @mousedown="handleMouseDown($event)"
            @mouseup="stopRotation"
            @mouseleave="handleMouseLeave"
            @mousemove="handleMouseMove($event)"
            @touchend.passive="stopRotation"
            @touchstart.passive="handleTouchStart"
            @touchmove.passive="handleTouchMove">
                <img :src="currImg" alt="Imagem do carro">
                <img v-show="handle360icon" class="icon360" :src="icon360" alt="Imagem icon 360">
            </div>
            <p class="viewer360deg-content-label">Selecionar a cor</p>
            <Pip360colorsMobile v-model:colorId="currColor" :colors="uniqueColors" @change-color="findPipImgs" />
        </div>
    </section>
    <Colors360Desktop v-show="showSection" v-model:colorId="currColor" :colors="uniqueColors" @change-color="findPipImgs"/>

    <section class="pipBtns">
        <GovButton v-if="false" :btnLink="setConfiguradorLink" btnText="Configurar" svgItem="arrow" />
        <GovButton v-if="btnTestDrive" :displayAtag="false"
        :toRoute="getTDroute"
        btnText="Test Drive" svgItem="arrow" />
        <GovButton :displayAtag="false"
        :toRoute="getCProute"
        btnText="Proposta comercial" svgItem="arrow" />
    </section>

    <div v-show="false">
        <!-- dump imgs -->
         <img v-for="(item, i) in modelImgs" :key="i" :src="item" alt="Imagem">
    </div>
</template>
<script>
    import Pip360colorsMobile from '../../SwiperContainers/Pip360colorsMobile.vue'
    import Colors360Desktop from './Colors360Desktop.vue'
    import GovButton from '../../Buttons/GovButton.vue'
    import { viewer360deg } from '../../../mixins/viewer360deg'
    import { mapGetters, mapState } from 'vuex'

    export default {
        name: 'Car360view',
        components: { Pip360colorsMobile, Colors360Desktop, GovButton },
        mixins: [viewer360deg],
        props:{
            title: {type: String},
            pipId: {type: [String, Number]},
            pipModel: {type: String},
            pipColors: { type: Object, default: () =>{ return {}; }},
            btnTestDrive: {type: Boolean},
            btnCfg: {type: Boolean},
        },
        mounted(){
            this.handleNewCarRender()
        },
        watch:{
            pipModel: function(){
                this.handleNewCarRender()
            }
        },
        data(){return{
            showSection: true,
            icon360: require('../../../assets/arrows360image.webp'),
            defaultImg: require('../../../assets/dealerDefaultPhoto.webp'),
            currColor: 460,
            currImg: '',
            modelImgs: [],
            maxLenght: 1,
        }},
        computed:{
            ...mapState(['dealers', 'linkConfigurador']),
            ...mapGetters({
                dealerOID: 'get_oidDealerData'
            }),
            handle360icon(){
                return (this.maxLenght > 0) ? true : false;
            },
            uniqueColors(){
                let uC = [];
                this.pipColors.map(el => {
                    if(!uC.some(item => item.id == el.id)){
                        uC.push(el);
                    }
                });
                return uC;
            },
            setConfiguradorLink(){
                let modelParam = `&model=${this.pipModel}&modelid=${this.pipId}`
                return this.linkConfigurador + this.dealerOID('parent') + modelParam;
            },
            getCProute(){
                return `/proposta-comercial?f=2&id=${this.pipId}&opt=0`
            },
            getTDroute(){
                return `/test-drive?id=${this.pipId}&opt=0`
            }
        },
        methods:{
            handleNewCarRender(){
            let pipObj = this.pipColors;
                let hasImgs = pipObj.some(el => el.externalColorImages360.length != 0)
                this.showSection = hasImgs
                this.currColor = this.pipColors[0].id

                this.findPipImgs();
            },
            findPipImgs(){
                let extCol = this.pipColors?.find(el => el.id == this.currColor );
                if(extCol){
                    this.modelImgs = extCol.externalColorImages360
                    this.maxLenght = this.modelImgs.length -1;
                    this.currImg = this.modelImgs[0];
                }
            },
        }
    }
</script>
<style lang="scss">
    @import './Car360view.scss';
</style>
