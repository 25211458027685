<template>
    <main>
        <section :class="isDefault ? 'formRes' : 'formRes usados'">
            <svg v-if="isOk" xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76">
                <g id="Group_7389" data-name="Group 7389" transform="translate(-21 -841.829)">
                    <g id="Ellipse_213" data-name="Ellipse 213" transform="translate(21 841.829)" fill="none" stroke="currentColor" stroke-width="1">
                    <circle cx="38" cy="38" r="38" stroke="none"/>
                    <circle cx="38" cy="38" r="37.5" fill="none"/>
                    </g>
                    <path id="Path_16645" data-name="Path 16645" d="M47.336,18.23l4.535,4.513-20.8,20.8L16.57,29.067l4.535-4.535L31.07,34.5Z" transform="translate(24.429 848.599)" fill="currentColor"/>
                </g>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>

            <Image theClass="formRes-img" :srcImg="geral_field.form_res_img"/>
            <h2 v-html="returnMsg()[0]"></h2>
            <p> {{ returnMsg()[1] }} </p>
        </section>
    </main>
</template>
<script>
    import { mapState } from 'vuex'
    import Image from '../components/SimpleComponents/Image.vue'

    export default {
        name: 'FormResponse',
        components:{ Image },
        computed:{ ...mapState(['geral_field']), },
        data(){return{
            msgHeader: '<span>Obrigado</span> pelo pedido de contacto.',
            msgText:  'Em breve será contactado por um dos nossos comerciais.',
            msgHeaderErro: '<span>Ocorreu</span> um erro.',
            msgTextErro:  'Volte a tentar mais tarde',
            isDefault: true,
            isOk: true,
        }},
        created(){
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const form = urlParams.get('form')

            if(form == 1) this.msgHeader = '<span>Obrigado</span> pelo pedido de test drive'
            if(form == 3) {
                this.msgHeader = '<span>Agradecemos</span> a sua marcação de serviço.'
                this.msgText = 'Em breve voltaremos ao seu contacto.'
            }
        },
        mounted(){
            let props = this.$route.matched[0].props.default;
            this.isDefault = props.isDefault
            this.isOk = props.isOk
        },
        methods:{
            returnMsg(){
                return (this.isOk) ? [this.msgHeader, this.msgText] : [ this.msgHeaderErro, this.msgTextErro]
            }
        }
    }
</script>
<style lang="scss">
    .formRes{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        height: calc(100vh - 285px);

        svg{
            z-index: 3;
            color: $secondaryCol;
            margin-bottom: 1.75rem;
            width: 4.75rem;
            height: 4.75rem;
        }

        &-img{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            &:after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: $blackCol;
                mix-blend-mode: multiply;
                opacity: .6;
            }

            img{ @extend %img; }
        }

        h2{
            z-index: 2;
            margin: 0 2rem 1rem;
            @include mixin_text_content(font25, $whiteCol, center, $boldFont, 700, 1.75rem );
            & > span{ color: $secondaryCol; font-family: $boldFont}
        }

        &.usados h2 > span,
        &.usados svg{ color: $orangeCol; }

        p{
            z-index: 2;
            margin: 0 2rem;
            @include mixin_text_content(font14, $whiteCol, center, $regularFont, 400, 1.5rem );
        }

    }


    @include respond-to(large){
        .formRes{  height: calc(100vh - 180.5px);
            h2{ font-size: 2.81rem; }
        }
    }
</style>
