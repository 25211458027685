<template>
    <section :class="isFullWidth ? 'usadosListWhite' : 'usadosList'">
        <div class="usadosList-wrap">
            <div class="usadosList-item" v-for="(item, index) in geral_field.usados_advantages" :key="index">
                <p v-html="item.icon"></p>
                <p class="usadosList-item-text"> {{ item.text }} </p>
            </div>
        </div>
    </section>
</template>
<script>
    import { mapState } from 'vuex'

    export default {
        name: 'AdvtgListUsados',
        props:{
            isFullWidth: {type: Boolean, default: true},
        },
        computed: {
            ...mapState(['geral_field', 'usados']),
        }, 
    }
</script>
<style lang="scss">
    @import './AdvtgListUsados.scss';
</style>