<template>
    <div class="cookiesModal">
        <h2> {{ title }} {{ siteName }} </h2>
        <p> {{ text }} </p>
        
        <div v-if="!showDetails" class="cookiesSection">
            <div class="cookiesSection-item" v-for="(item,i) in content" :key="i">
                <div class="cookiesSection-item-header">
                    <!-- <CheckBox :checkboxObj="buildCheckBoxArr(item)" @checkbox-updated="saveCookies($event, item)" /> -->
                    <CheckBox :checkboxObj="buildCheckBoxArr(item)" @checkbox-updated="($event) => { item.checked = $event; }" />
                    <p> {{ item.title }} </p>
                </div>
                <p> {{ item.description }} </p>
            </div>
        </div>

        <p v-if="showDetails" class="settingsLink" @click="showDetails = false"> {{ labelBtn2 }} </p>
        <SimpleButton btnClass="btn-primary" :btnText="labelBtn1" @click="acceptAllCookies" />
        <SimpleButton v-if="!showDetails" btnClass="btn-primaryInverted" btnText="Guardar" @click="saveCookies()" />

    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex'
    import * as COOKIES from "@/cookies/cookies.js";

    // import GovButton from '../../Buttons/GovButton.vue'
    import SimpleButton from '../../Buttons/SimpleButton.vue'
    import CheckBox from '../../Forms/FormFields/InputCheckbox/InputCheckbox.vue'

    export default {
        name: 'CookiesModal',
        components: { SimpleButton, CheckBox },
        data(){return{
            title: 'Cookies no website',
            text: 'Os cookies são uma das razões pelas quais conseguimos proporcionar uma experiência incrível no nosso site. No entanto, respeitamos totalmente o seu direito de escolher que cookies podemos usar.',
            labelBtn1: 'Sim, concordo',
            labelBtn2: 'Alterar definições',
            showDetails: true,
            cookies: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_settings"),
            content: [
                {
                    field: 'necessary',
                    checked: true,
                    disabled: true,
                    title: 'Estritamente Necessários',
                    description: 'Os cookies essenciais, também conhecidos como cookies "estritamente necessários", ativam recursos sem os quais não seria capaz de usar o site como pretendido.',
                },
                {
                    field: 'performance',
                    checked: false,
                    title: 'Cookies de Desempenho',
                    description: 'Estes cookies ajudam-nos a entender como os visitantes usam o nosso site, para que seja possível melhorá-lo e testar novas funcionalidades.',
                },
                {
                    field: 'functional',
                    checked: false,
                    title: 'Cookies Funcionais',
                    description: 'Estes cookies ajudam-nos a personalizar a sua visita, lembrando as suas preferências e configurações, como páginas que visitou e modelos Hyundai que explorou.',
                },
            ]
        }},
        created() {
            this.content[1].checked = this.cookies  ? this.cookies.split(",")[1] == "S" ? true : false : false; //performance
            this.content[2].checked = this.cookies ? this.cookies.split(",")[2] == "S" ? true : false : false; //functional

            this.updateModal({ bgClose: false })
        },
        beforeUnmount(){
            this.updateModal({ bgClose: true })
        },
        computed: {
            ...mapState(['cookieSettings', 'siteName']),
        },
        methods: {
            ...mapMutations({
                updateCookiesState: 'UPDATE_STATE',
                updateModal: 'modalData/UPDATE_STATE',
            }),
            acceptAllCookies() {
                COOKIES.COOKIES_FUNCTIONS.setCookie("_rg_settings", "S,S,S");
                COOKIES.COOKIES_FUNCTIONS.setCookie("_rg_modal", "N");

                this.content.every(el => el.checked = true)

                this.updateCookiesState({
                    cookieModal: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_modal"),
                    cookieSettings: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_settings"),
                });
                this.closeModal()
            },
            closeModal(){
                this.updateModal({
                    openModal: false,
                    modalClass: 'modal-content',
                });
            },
            buildCheckBoxArr(item){
                return {
                    name: item.field,
                    id: item.field,
                    value: '',
                    displayAsHtml: false,
                    content: '',
                    checked: item.checked,
                    disabled: item.disabled,
                }
            },
            saveCookies(){
                let cookies = "";
                cookies += "S,";
                cookies += this.content[1].checked ? "S," : "N,"; //performance
                cookies += this.content[2].checked ? "S" : "N"; //functional
                
                COOKIES.COOKIES_FUNCTIONS.setCookie("_rg_settings", cookies);
                COOKIES.COOKIES_FUNCTIONS.setCookie("_rg_modal", "N");
                this.updateCookiesState({ 
                    cookieModal: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_modal"),
                    cookieSettings: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_settings"),
                });
                this.closeModal()
            },
        }
    }
</script>
<style lang="scss" scoped>
    .cookiesModal{
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;

        & > * { flex-basis: 100%;}
        & > :nth-last-child(2){ margin-top: 1.25rem; }

        .settingsLink{
            font-size: 1rem;
            color: $primaryCol;
            font-family: $mediumFont;
            cursor: pointer;
        }
    }
    .cookiesSection{
        display: flex;
        flex-flow: column;
        gap: 2rem;
        margin: 1rem auto;

        & > * { flex-basis: 100%;}

        &-item{ 
            &-header{
                font-size: clamp(1rem, 1.5vw, 1.2rem);
                color: $primaryCol;
                display: inline-flex;
                align-items: center;
            }
            & > :last-child{ margin-top: .75rem;}

            input{
                &:disabled + label{
                    pointer-events: none;
                    user-select: none;
                }
            }

            & > p{ @include mixin_text_content()}
        }

        @include respond-to(large){
            & > * { flex-basis: calc((100% - 4rem) / 3 );}
        }
    }
</style>