<template>
    <section class="dealersPage">
        <div class="dealersPage-leftCol">
            <h2 class="dealersPage-title"> {{ dealerTitle }} </h2>
            <p class="dealersPage-text" v-html="dealerText"></p>
            <div class="dealersPage-filters">
                <p :class="isShowRoomOpt && 'active'"
                @click="handleFilter">Showroom</p>
                <p v-show="dealerHasAfterSale" :class="!isShowRoomOpt && 'active'"
                @click="handleFilter">Após Venda</p>
            </div>
            <div v-if="dealersObj.length > 0" class="dealersPage-accordion">
                <DealerAccordonItem v-for="(item, i) in filterDealers" :key="i"
                :isAct="handleActiveAcordItem(i)"
                :itemId="item.objectId"
                :idItr="i"
                :itemTitle="item.postalCodeDesig"
                :itemStreet="fullStreet(item.address, item.numberDoor, item.postalCode4, item.postalCode3)"
                :itemCoordX="item.gpsX"
                :itemCoordY="item.gpsY"
                :itemContacts="defineContactType(item.contacts)"
                :itemImg="item.imageInstallation || defaultImg"
                v-model:imgSrc="currImg"
                v-model:setActiveItem="currActiveAcordion"/>
            </div>
        </div>
        <div v-show="!isMobile" class="dealersPage-rightCol">
            <Image theClass="dealersPage-rightCol-img" :srcImg="currImg" />
        </div>
    </section>
</template>
<script>
import { mapState } from 'vuex';
    import DealerAccordonItem from '../../SectionParts/DealerAccordonItem.vue'
    import Image from '../../SimpleComponents/Image.vue'

    export default {
        name: 'DealersPageContent',
        components:{ DealerAccordonItem, Image, },
        props:{
            dealerTitle: {type: String},
            dealerText: {type: String},
            dealersObj: {type : Object,default: () => { return {};}},
        },
        data(){return{
            isMobile: false,
            isShowRoomOpt: true,
            defaultImg: require('@/assets/dealerDefaultPhoto.webp'),
            currImg: require('@/assets/dealerDefaultPhoto.webp'),
            showroomContactID: 'HC00010043',
            posVendaContactID: 'HC00010044',
            currActiveAcordion: -1,
        }},
        watch: {
            isShowRoomOpt: function(){
                this.currImg = require('@/assets/dealerDefaultPhoto.webp')
                this.currActiveAcordion = -1
            },
        },
        computed:{
            ...mapState(['showroom', 'posVenda', 'dealerHasAfterSale']),
            filterDealers(){
                let filterType = this.isShowRoomOpt ? this.showroom : this.posVenda ;
                let res = this.dealersObj.filter(el => {
                    if(el.services.find(item => item.objectId === filterType)) return el
                });
                return res;
            },
        },
        methods:{
            handleFilter(){
                this.isShowRoomOpt = !this.isShowRoomOpt;
            },
            defineContactType(currDealerContacts){
                let currContact = this.isShowRoomOpt ? this.showroomContactID : this.posVendaContactID
                let res = currDealerContacts.find(el => el.objectId == currContact).contactPersons;
                return res;
            },
            fullStreet(name, door, code3, code4){
                return name + ', ' + door + '  ' + code3 + '-' + code4;
            },
            handleActiveAcordItem(i){
                if((this.dealersObj.length == 1) && (i == 0)) return true
                if(i == this.currActiveAcordion) return true
                return false;
            }
        }
    }
</script>
<style lang="scss">
    @import './DealersPageContent.scss';
</style>
