<template>
    <section class="cookiesPage">
        <div class="cookiesPage-item" v-for="(item,i) in content" :key="i">
            <div class="cookiesPage-item-header">
                <CheckBox :checkboxObj="buildCheckBoxArr(item)" @checkbox-updated="saveCookies($event, item)" />
                <p> {{ item.title }} </p>
            </div>
            <p> {{ item.description }} </p>
        </div>
    </section>
</template>
<script>
    import CheckBox from '../components/Forms/FormFields/InputCheckbox/InputCheckbox.vue'
    import * as COOKIES from "@/cookies/cookies.js";
    import { mapMutations, mapState } from 'vuex';

    export default {
        name: 'CookiesPage',
        components: { CheckBox, },
        created() {
            this.content[1].checked = this.cookies  ? this.cookies.split(",")[1] == "S" ? true : false : false; //performance
            this.content[2].checked = this.cookies ? this.cookies.split(",")[2] == "S" ? true : false : false; //functional
        },
        data(){return{
            cookies: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_settings"),
            content: [
                {
                    field: 'necessary',
                    checked: true,
                    disabled: true,
                    title: 'Estritamente Necessários',
                    description: 'Os cookies essenciais, também conhecidos como cookies "estritamente necessários", ativam recursos sem os quais não seria capaz de usar o site como pretendido.',
                },
                {
                    field: 'performance',
                    checked: false,
                    title: 'Cookies de Desempenho',
                    description: 'Estes cookies ajudam-nos a entender como os visitantes usam o nosso site, para que seja possível melhorá-lo e testar novas funcionalidades.',
                },
                {
                    field: 'functional',
                    checked: false,
                    title: 'Estritamente Funcionais',
                    description: 'Estes cookies ajudam-nos a personalizar a sua visita, lembrando as suas preferências e configurações, como páginas que visitou e modelos Hyundai que explorou.',
                },
            ]
        }},
        computed: {
            ...mapState(['cookieSettings']),
        },
        methods: {
            ...mapMutations({
                updateCookiesState: 'UPDATE_STATE',
            }),
            buildCheckBoxArr(item){
                return {
                    name: item.field,
                    id: item.field,
                    value: '',
                    displayAsHtml: false,
                    content: '',
                    checked: item.checked,
                    disabled: item.disabled,
                }
            },
            saveCookies(e, item){
                this.content.find(el => el.field === item.field).checked = e

                let cookies = "";
                cookies += "S,";
                cookies += this.content[1].checked ? "S," : "N,"; //performance
                cookies += this.content[2].checked ? "S" : "N"; //functional
                
                COOKIES.COOKIES_FUNCTIONS.setCookie("_rg_settings", cookies);
                COOKIES.COOKIES_FUNCTIONS.setCookie("_rg_modal", "N");
                this.updateCookiesState({ 
                    cookieModal: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_modal"),
                    cookieSettings: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_settings"),
                });
            },
        }
    }
</script>
<style lang="scss">
    .cookiesPage{
        // width: min(90%, #{$maxWidth});
        // margin: auto;

        display: flex;
        flex-flow: row wrap;
        gap: 2rem;

        & > * { flex-basis: 100%;}

        &-item{ margin: 1rem 0;
            &-header{
                font-size: clamp(1rem, 1.5vw, 1.25rem);
                display: inline-flex;
                align-items: center;
            }
            & > :last-child{ margin-top: .75rem;}

            input{
                &:disabled + label{
                    pointer-events: none;
                    user-select: none;
                }
            }

            & > p{ @include mixin_text_content()}
        }

        @include respond-to(large){
            & > * { flex-basis: calc((100% - 4rem) / 3 );}
        }
    }
</style>