<template>
    <svg v-if="svgItem == 'arrow'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.2 6.844">
        <g id="Group_7595" data-name="Group 7595" transform="translate(-131.675 -15.439)">
            <path id="Path_16717" data-name="Path 16717" d="M.253,13.2A.278.278,0,0,1,0,12.9V.3A.278.278,0,0,1,.253,0,.278.278,0,0,1,.507.3V12.9A.278.278,0,0,1,.253,13.2Z" transform="translate(144.875 18.608) rotate(90)" fill="currentColor"/>
            <path id="Path_16716" data-name="Path 16716" d="M6.591,3.676A.253.253,0,0,1,6.412,3.6L3.422.612.433,3.6a.253.253,0,0,1-.359-.358L3.243.074a.254.254,0,0,1,.359,0L6.77,3.243a.253.253,0,0,1-.179.433Z" transform="translate(144.875 15.439) rotate(90)" fill="currentColor"/>
        </g>
    </svg>
    <svg v-if="svgItem == 'chevron'" xmlns="http://www.w3.org/2000/svg" width="33.297" height="33.297" viewBox="0 0 33.297 33.297">
        <g id="Group_7014" data-name="Group 7014" transform="translate(-702 -736)">
            <g id="Arrow" transform="translate(702 736)">
            <rect id="Rectangle_7" data-name="Rectangle 7" width="33.297" height="33.297" fill="none"/>
            <g id="Group_20" data-name="Group 20" transform="translate(5.501 11.292)">
                <path id="Path_17" data-name="Path 17" d="M4422.714,491.665a1.15,1.15,0,0,1-.808-.334l-9.486-9.489a1.143,1.143,0,1,1,1.616-1.617l8.678,8.679,8.681-8.679a1.144,1.144,0,0,1,1.618,1.617l-9.491,9.489A1.143,1.143,0,0,1,4422.714,491.665Z" transform="translate(-4412.084 -479.889)" fill="#002c5f"/>
            </g>
            </g>
        </g>
    </svg>
</template>
<script>
    export default {
        name: 'SvgList',
        props:{ svgItem:{ type: String, default: ''}},
    }
</script>