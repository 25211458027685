<template>
    <ModalBox
    :modalContent="modalData" 
    :isOpen="openModal" 
    @close-modal="changeModalState($event)"/>

    <CampaignsFilter 
    :filterCategories="objFilterCategories"
    v-model:activeOpt="activeFilterOpt"/>

    <div class="campaings-container">
        <CampaignsCard v-for="(item, index) in handleCampaignsData.slice(0, maxCards)" :key="index"
        :cpItem="item" 
        :cpIndex="index"
        btnClass="btn-grayInverted"
        @get-index="getCurrentIndex($event)"/>
    </div>
    <div v-show="handleCampaignsData.length > 6" class="wrapbtn">
        <p class="btn-primary btnMore" @click="handleCpsRender"> {{ changeBtnName ? 'Ver mais campanhas' : 'Ver Menos' }} </p>
    </div>
</template>
<script>
    import CampaignsCard from '../../Cards/CampaignsCard/CampaignsCard.vue'
    import ModalBox from '../../Modals/ModalBox.vue'
    import { handleModalFunctions } from '../../../mixins/handleModalFunctions'
    import CampaignsFilter from '../../SectionParts/CampaignsFilter/CampaignsFilter.vue'
    import { mapGetters, mapState } from 'vuex'
    
    export default {
        name: 'ListCampaigns',
        components: { CampaignsCard, ModalBox, CampaignsFilter, },
        mixins: [handleModalFunctions],
        props:{
            objFilterCategories: {type: Object, default: () => { return {}; }},
        },
        data(){return{
            activeFilterOpt: this.objFilterCategories[0],
            maxCards: 6,
            interval: 6,
            changeBtnName: true,
        }},
        computed:{
            ...mapState(['dealerSellsUsed']),
            ...mapGetters({
                objCampaigns: 'govCampaigns/getCampaignsLstSorted',
            }),
            handleCampaignsData(){
                let obj = this.objCampaigns
                if(this.activeFilterOpt == this.objFilterCategories[0]) return obj;
                return obj.filter(item => item.campaign_type == this.activeFilterOpt);
            },
        },
        methods: {
            handleCpsRender(){
                let objLenght = this.handleCampaignsData.length
                let maxLstLenght = Math.ceil(objLenght / this.interval);
                let lstItem = Math.floor((this.maxCards + this.interval) / this.interval);

                if(lstItem <= maxLstLenght){ this.maxCards += this.interval}
                else{ this.maxCards = this.interval }
                
                this.changeBtnName = (lstItem != maxLstLenght) ?  true : false ;
            }
        },
    }
</script>
<style lang="scss">
    @import './ListCampaigns.scss';
</style>

<style lang="scss" scoped>
    .wrapbtn{ background-color: #F6F8F9; padding: 0 0 3.5rem}
    .btnMore{
        width: max-content;
        margin: 0 auto;
        justify-content: center;
        color: $whiteCol;
    }
</style>