<template>
    <main v-if="!usedCars.isGettingCars">
        <HeaderCarDetails :carObj="currCar" />
        <SectionHeader class="changeMargin" :isBlack="false" secTitle="Promessa de compromisso" />
        <AdvtgListUsados :isFullWidth="false"/>
        <SectionHeader :isBlack="false" secTitle="Equipamento disponível" />
        <EquipUsados :idCar="currCar.idCar" :objCarEquipmts="currCar.seriesEquipment" :dealer="currCar.oidDealer"/>
        <SectionHeader secTitle="Pode também ter interesse" />
        <ListCarsUsados :showBtn="false" :usadosCarsObj="carsObj" />
    </main>
    <LoadingPage v-else />
</template>
<script>
    import LoadingPage from './LoadingPage.vue'
    import { mapState } from 'vuex'
    import HeaderCarDetails from '../components/Sections/HeaderCarDetails/HeaderCarDetails.vue'
    import AdvtgListUsados from '../components/Sections/AdvtgListUsados/AdvtgListUsados.vue'
    import EquipUsados from '../components/Sections/ListEquipUsados/ListEquipUsados.vue'
    import ListCarsUsados from '../components/Sections/ListCarsUsados/ListCarsUsados.vue'
    import SectionHeader from '../components/SectionParts/SectionHeader.vue'

    export default {
        name: 'UsadosDetails',
        components: { LoadingPage, HeaderCarDetails, AdvtgListUsados, EquipUsados, ListCarsUsados, SectionHeader },
        created(){
            this.filterUsados();
            let usadosLink = document.getElementById('usadosLink')
            if(usadosLink) usadosLink.classList.add('active');
            else this.$router.replace('/')
        },
        unmounted(){ document.getElementById('usadosLink').classList.remove('active'); },
        beforeUpdate(){
            this.filterUsados();
        },
        data(){return{
            carsObj: [],
            currCar: [],
        }},
        computed: {
            ...mapState(['usedCars', 'geral_field', 'dealerSellsUsed']),
        },
        methods:{
            filterUsados(){
                let plate = this.$route.params.plate;
                let usadosArr = this.usedCars.lstUsedCars;
                let findCar = usadosArr.filter(el => el.plateNumber == plate);
                let usedLenght = usadosArr.length;
                let random = 0;
                if(usedLenght > 5){
                    random = Math.floor(Math.random() * (usedLenght - 5));
                }

                // if plate dont exist -> go used car lst
                if(Object.keys(findCar).length === 0){ this.$router.push('/usados') } //or replace...

                this.currCar = findCar[0];
                this.carsObj = usadosArr.slice(random,random+4);
            }
        },
    }
</script>
<style lang="scss">
    @include respond-to(maxMobile){
        .changeMargin > .sectionHeader-title.orange{ margin: 2.5rem 0 0 !important; }
    }
</style>