<template>
    <div class="lightBg">
        <div class="linksRapidos">
            <p class="linksRapidos-title">{{ secTitle }}</p>
            <div class="govBtns">
                <GovButton v-for="(item, index) in btnsData" :key="index"
                v-show="handleDisplay(item.urlLink, item.btnText)"
                btnClass="btn-primaryInverted"
                :displayAtag="false"
                :toRoute="item.urlLink"
                :btnText="item.btnText"
                svgItem="arrow"/>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import GovButton from '../../Buttons/GovButton.vue'

    export default {
        name: 'LinksRapidos',
        components:{ GovButton },
        props:{
            secTitle: { type: String, default:'Olá, podemos ajudar?'},
            btnsData: { type: Array, default: () => { return []; }},
        },
        computed: {
            ...mapState(['dealerSellsUsed']),
        },
        methods:{
            handleDisplay(a, b){
                if(a != '' && b != ''){
                    if(a === '/usados') return this.dealerSellsUsed
                    return true
                }else{
                    return false;
                }
            },
        }
    }
</script>
<style lang="scss">
    @import './LinksRapidos.scss';
</style>
