<template>
    <div class="hpBanner">
        <BgImg :scrMobile="bannerData.banner_img_mobile" :scrDesktop="bannerData.banner_img"/>
        <div :class="['hpBanner-content', handleTextColor(), bannerData.alignContent || 'left']">
            <p class="hpBanner-content-title" v-html="bannerData.banner_title"></p>
            <p class="hpBanner-content-text" v-html="bannerData.banner_text"></p>

            <GovButton v-show="bannerData.banner_link"
            btnClass="btn-primary"
            svgItem="arrow"
            :displayAtag="true"
            :openNewTab="handleLinkType(bannerData.from)"
            :btnLink="bannerData.banner_link + dealerOID('parent')"/>
        </div>
    </div>
</template>
<script>
    import BgImg from '../../SimpleComponents/BgImg.vue'
    import GovButton from '../../Buttons/GovButton.vue'
    import { mapGetters } from 'vuex';

    export default {
        name: 'HpBanner',
        components:{ BgImg, GovButton },
        props: {
            bannerData: {type: Object, default: () => { return {}; }}
        },
        computed:{
            ...mapGetters({
                dealerOID: 'get_oidDealerData'
            }),
        },
        methods:{
            handleLinkType(from){
                if(from == 'boh'){ return true}
                return false
            },
            handleTextColor(){
                let col = this.bannerData.banner_text_color;
                return (col) ? col.toLowerCase() : 'white';

            }
        }
    }
</script>
<style lang="scss">
    @import './HpBanner.scss';
</style>
