<template>
    <main>
        <SwiperBannerHp :banners="banners"/>
        <LinksRapidos :secTitle="geral_field.fast_links_title" :btnsData="geral_field.fastLinksArr"/>
        <ModelsRange
        :secTitle="geral_field.sec_gama_title" 
        :models="models" 
        :filterData="geral_field.sec_gama_segmentos"/>
        <CampaignsHp :secTitle="geral_field.sec_campagns_title" :objCampaigns="getCampaigns.slice(0,3)"/>
        <SecUsadosHP v-if="dealerSellsUsed && getLstUsados"
        :secTitle="geral_field.sec_usados_title" 
        :infoArr="geral_field.usados_banner"
        :usadosCars="getLstUsados"/>
        <NewsHp :newsData="news.slice(0,3)"/>
        <GarantyBanner />
        <DealersHp 
        :hpTitle="geral_field.hp_title" 
        :hpTSubtitle="geral_field.hp_subtitle" 
        :hpText="geral_field.hp_text"/>
    </main>
</template>
<script>
    import { mapGetters, mapState } from 'vuex'
    import SwiperBannerHp from '../components/SwiperContainers/SwiperBannerHp.vue'
    import LinksRapidos from '../components/Sections/LinksRapidos/LinksRapidos.vue'
    import ModelsRange from '../components/Sections/ModelsRange/ModelsRange.vue'
    import CampaignsHp from '../components/Sections/CampaignsHp/CampaignsHp.vue'
    import SecUsadosHP from '../components/Sections/SecUsadosHP/SecUsadosHP.vue'
    import NewsHp from '../components/Sections/NewsHp/NewsHp.vue'
    import GarantyBanner from '../components/Banners/GarantyBanner/GarantyBanner.vue'
    import DealersHp from '../components/SectionParts/DealersHp.vue'

    export default {
        name: 'HomePage',
        components:{ SwiperBannerHp, LinksRapidos, ModelsRange, CampaignsHp, SecUsadosHP, NewsHp, GarantyBanner, DealersHp},
        computed: {
            ...mapState(['banners', 'geral_field', 'models', 'news', 'dealerSellsUsed']),
            ...mapGetters({
                getLstUsados: 'usedCars/getHomepageCars',
                getCampaigns: 'govCampaigns/getCampaignsLstSorted',
            }),
        },
    }
</script>