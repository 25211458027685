<template>
    <footer>
        <div class="footer">
            <router-link to="/" class="footer-logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="44.892" height="22.987" viewBox="0 0 44.892 22.987"><g transform="translate(0 0)"><path d="M22.446,22.987c12.4,0,22.446-5.145,22.446-11.494S34.843,0,22.446,0,0,5.146,0,11.493,10.049,22.987,22.446,22.987Zm4.225-5.64c-.374.87-1.018,2.724-2.5,3.461a3.747,3.747,0,0,1-1.5.344c-.111,0-.19,0-.224,0a39.043,39.043,0,0,1-11.226-1.558c-.028-.007-.1-.037-.125-.046-.3-.1-.449-.238-.449-.406a.5.5,0,0,1,.184-.365c.045-.047.114-.1.2-.182a26.273,26.273,0,0,1,7.219-3.866,35.523,35.523,0,0,1,5.281-1.683C24.676,12.806,28.9,12.161,26.671,17.347ZM38.011,5.762a.457.457,0,0,1,.379-.268.778.778,0,0,1,.467.153c2.637,1.623,4.2,3.648,4.2,5.842,0,3.965-5.1,7.37-12.382,8.861-.468.091-.778.09-.885-.029a.28.28,0,0,1,0-.356,1.873,1.873,0,0,1,.194-.27c3.969-4.678,6.991-11.576,7.85-13.569C37.9,5.987,37.961,5.852,38.011,5.762ZM18.288,5.615c.374-.866,1.019-2.72,2.5-3.457a3.728,3.728,0,0,1,1.5-.344c.111,0,.19,0,.222,0a39,39,0,0,1,11.231,1.56c.024.006.1.036.122.045.3.1.451.236.451.407a.511.511,0,0,1-.186.364c-.045.045-.113.1-.2.18A26.215,26.215,0,0,1,26.7,8.234a35.434,35.434,0,0,1-5.281,1.685C20.281,10.159,16.056,10.8,18.288,5.615ZM14.217,2.632c.469-.09.778-.089.889.029a.3.3,0,0,1,0,.353,2.181,2.181,0,0,1-.2.269C10.94,7.962,7.918,14.862,7.058,16.856c-.064.137-.13.273-.182.362a.45.45,0,0,1-.376.269.806.806,0,0,1-.469-.153c-2.634-1.624-4.2-3.649-4.2-5.844C1.831,7.527,6.928,4.119,14.217,2.632Z" transform="translate(0 0)" fill="#fff"/></g></svg>
                <!-- <span> {{ geral_field.site_name }} </span> -->
                <span> {{ siteName }} </span>
            </router-link>
            <div class="footer-links">
                <router-link to="/campanhas">CAMPANHAS</router-link>
                <router-link v-if="dealerSellsUsed" to="/usados">USADOS</router-link>
                <router-link to="/#models">MODELOS</router-link>
                <router-link to="/noticias">NOTÍCIAS</router-link>
                <router-link to="/concessionarios">ONDE ESTAMOS?</router-link>
                <p @click="showCookiesModal">POLÍTICA DE COOKIES</p>
            </div>
            <div class="footer-socials">
                <a :href="getSocials.facebook" target="_blank"> <svg xmlns="http://www.w3.org/2000/svg" width="7.503" height="15.548" viewBox="0 0 7.503 15.548"><path d="M-3265.582-909.413h1.606v-1.563a4.179,4.179,0,0,1,.518-2.408,2.863,2.863,0,0,1,2.5-1.17,10.141,10.141,0,0,1,2.883.288l-.4,2.382a5.418,5.418,0,0,0-1.295-.194c-.625,0-1.185.224-1.185.85v1.814h2.563l-.178,2.328h-2.385v8.079h-3.013v-8.079h-1.606Z" transform="translate(3265.582 914.553)" fill="#fff" opacity="0.587"/></svg> </a>
                <a :href="getSocials.intagram" target="_blank"> <svg xmlns="http://www.w3.org/2000/svg" width="14.443" height="14.443" viewBox="0 0 14.443 14.443"><g opacity="0.587"><g transform="translate(0 0)"><path d="M12.035,0H2.407A2.407,2.407,0,0,0,0,2.407v9.628a2.407,2.407,0,0,0,2.407,2.407h9.628a2.407,2.407,0,0,0,2.407-2.407V2.407A2.407,2.407,0,0,0,12.035,0Zm1.2,12.035a1.2,1.2,0,0,1-1.2,1.2H2.407a1.2,1.2,0,0,1-1.2-1.2V2.407a1.2,1.2,0,0,1,1.2-1.2h9.628a1.2,1.2,0,0,1,1.2,1.2Z" fill="#fff"/><path d="M9.611,6a3.611,3.611,0,1,0,3.611,3.611A3.61,3.61,0,0,0,9.611,6Zm0,6.018a2.407,2.407,0,1,1,2.407-2.407A2.407,2.407,0,0,1,9.611,12.018Z" transform="translate(-2.389 -2.389)" fill="#fff"/><circle cx="0.932" cy="0.932" r="0.932" transform="translate(10.26 2.221)" fill="#fff"/></g></g></svg> </a>
                <a :href="getSocials.youtube" target="_blank"> <svg xmlns="http://www.w3.org/2000/svg" width="17.267" height="12.156" viewBox="0 0 17.267 12.156"><g opacity="0.587"><path d="M-3220.557-912.887a99.848,99.848,0,0,1,12.316,0,2.455,2.455,0,0,1,2.285,2.285,34.2,34.2,0,0,1,0,7.205,2.456,2.456,0,0,1-2.285,2.285,98.491,98.491,0,0,1-12.316,0,2.442,2.442,0,0,1-2.285-2.285,34.171,34.171,0,0,1,0-7.205,2.453,2.453,0,0,1,2.285-2.285Zm4.381,3.428,4.6,2.412-4.6,2.381v-4.792Z" transform="translate(3223.033 913.077)" fill="#fff" fill-rule="evenodd"/></g></svg> </a>
                <a :href="getSocials.linkedin" target="_blank"> <svg xmlns="http://www.w3.org/2000/svg" width="14.274" height="14.443" viewBox="0 0 14.274 14.443"><g opacity="0.587"><path d="M720.536-125.02h-11.4a1.441,1.441,0,0,0-1.44,1.444v11.555a1.441,1.441,0,0,0,1.44,1.444h11.4a1.44,1.44,0,0,0,1.438-1.444v-11.555A1.44,1.44,0,0,0,720.536-125.02Zm-8.256,11.441h-1.921v-6.179h1.921Zm-.961-6.937h-.012a.947.947,0,0,1-1-.961.954.954,0,0,1,1.025-.961.952.952,0,0,1,1.011.961A.954.954,0,0,1,711.32-120.516Zm7.973,6.937h-1.921V-117c0-.794-.279-1.338-.973-1.338a1.055,1.055,0,0,0-.987.72,1.39,1.39,0,0,0-.062.479v3.564h-1.921v-4.207c0-.77-.024-1.416-.05-1.971h1.669l.088.858h.038a2.219,2.219,0,0,1,1.907-1c1.263,0,2.212.846,2.212,2.665Z" transform="translate(-707.7 125.02)" fill="#fff"/></g></svg> </a>
            </div>
            <div class="footer-disclaimer" v-html="geral_field.disclaimer"></div>
        </div>
    </footer>
</template>
<script>
    import { mapMutations, mapState } from 'vuex'
    import { mapGetters } from 'vuex'

    export default {
        name: 'Footer',
        computed:{
            ...mapState(['geral_field', 'siteName', 'dealerSellsUsed']),
            ...mapGetters(['getSocials']),
        },
        methods: {
            ...mapMutations({
                updateModalState: 'modalData/UPDATE_STATE',
            }),
            showCookiesModal(){
                this.updateModalState({
                    component: 'CookiesModal',
                    openModal: true,
                    showHeader: false,
                    title: '',
                    modalClass: 'modal-center',
                })
            }
        }
    }
</script>
<style lang="scss">
    @import './Footer.scss';
</style>