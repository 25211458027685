<template>
    <section class="destaqueUsados">
        <p class="destaqueUsados-title"> {{ secTitle }} </p>
        <div class="destaqueUsados-wrap">
            <div class="destaqueUsados-content">
            <div class="destaqueUsados-content-leftCol">
                <div class="logo" v-html="infoArr.logo"></div>
                <p class="title" v-html="infoArr.title"></p>
                <p class="text"> {{ infoArr.text }} </p>
                <GovButton :displayAtag="false" btnClass="btn-tertiary" btnText="Ver Todos" toRoute="/usados" svgItem="arrow"/>
            </div>
            <div class="destaqueUsados-content-swiper">
                <SecUsadosHPSwiper :usadosObj="usadosCars"/>
            </div>
        </div>
        </div>
    </section>
</template>
<script>
    import GovButton from '../../Buttons/GovButton.vue'
    import SecUsadosHPSwiper from '../../SwiperContainers/SecUsadosHPSwiper.vue'

    export default {
        name: 'SecUsadosHP',
        components: { GovButton, SecUsadosHPSwiper, },
        props: {
            secTitle: { type: String},
            infoArr: { type: Object, default: () => { return {}; }},
            usadosCars: { type: Object, default: () => { return {}; }},
        },
    }
</script>
<style lang="scss">
    @import './SecUsadosHP.scss';
</style>