<template>
    <section class="hpDealer">
        <div class="hpDealer-wrapper">
            <h1 class="hpDealer-title">{{ hpTitle }}</h1>
            <h2 class="hpDealer-subtitle">{{ hpTSubtitle }}</h2>
            <h3 class="hpDealer-text">{{ hpText }}</h3>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'DealersHp',
        props: {
            hpTitle: {type: String, default: 'Concessão'},
            hpTSubtitle: {type: String, default: 'Subtitulo'},
            hpText: {type: String, default: 'Descrição'},
        }
    }
</script>
<style lang="scss">
    .hpDealer{
        background-color: $whiteCol;

        &-wrapper{
            @extend %container;
            padding: {
                top: 3rem;
                bottom: 3rem;
            } 
        }   

        &-title{
            @include mixin_text_content(font35, $primaryCol);
            margin-bottom: .5rem;
        }
        &-subtitle{
            @include mixin_text_content(font20, $fontWeight: 700);
            margin-bottom: 2rem;
        }
        &-text{
            @include mixin_text_content($fontWeight: 400);
            line-height: 1.687rem;
        }
    }

    @include respond-to(large){
        .hpDealer-wrapper > * { text-align: center;}
    }
</style>