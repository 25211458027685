<template>
    <main>
        <CampaignsBanner
        :bannerImgMobile="getCampaign.campaign_img_mobile"
        :bannerImgDesktop="getCampaign.campaign_img_desktop"
        :bannerTitle="getCampaign.campaign_title"
        :bannerSignature="getCampaign.campaign_subtitle"
        :bannerPriceBox="getCampaign.campaign_banner" />
        <DownCounter v-show="getCampaign.showCounter == 'Sim'" :showCounter="getCampaign.showCounter" :dateText="getCampaign.dateMsg" :starttime="getCampaign.startDate" :endtime="getCampaign.endDate"/>
        <MoreInfo :dateInfo="getCampaign.dateMsg" :textInfo="getCampaign.campaign_destaq_text"/>
        <CampaignsForm :models="cpModels" :formTypeKey="handleFormParamKey" :campaign_type="getCampaign.campaign_type"/>
        <SellerBlock :sellerTitle="getCampaign.campaign_seller_title" :sellerText="getCampaign.campaign_seller_text"/>
        <GaleryBlock
        :secTitle="getCampaign.campaign_title_galery"
        :prosList="getCampaign.campaign_gallery_list"
        :srcImgsList="getCampaign.campaign_gallery_imgs"/>
        <div class="contInfoLegal" v-html="getCampaign.campaign_info_legal"></div>
        <GarantyBanner />
    </main>
</template>
<script>
    import { mapGetters } from 'vuex'
    import CampaignsBanner from '../components/Banners/CampaignsBanner/CampaignsBanner.vue'
    import DownCounter from '../components/CampaignsBlocks/DownCounter/DownCounter.vue'
    import MoreInfo from '../components/CampaignsBlocks/MoreInfo/MoreInfo.vue'
    import CampaignsForm from '../components/Forms/CampaignsForm/CampaignsForm.vue'
    import SellerBlock from '../components/CampaignsBlocks/SellerBlock.vue'
    import GaleryBlock from '../components/CampaignsBlocks/GaleryBlock/GaleryBlock.vue'
    import GarantyBanner from '../components/Banners/GarantyBanner/GarantyBanner.vue'

    export default {
        name: 'CampaignDetails',
        components: { CampaignsBanner, DownCounter, MoreInfo, CampaignsForm, SellerBlock, GaleryBlock, GarantyBanner },
        computed:{
            ...mapGetters({
                getCpsList: 'govCampaigns/getCpsList'
            }),
            getCampaign(){
                let routeTitle = this.$route.params.title
                let objCps = this.getCpsList
                let res = objCps.filter(item => item.route == routeTitle);
                return res[0];
            },
            cpModels(){
                let obj = this.getCampaign.models_lst;
                if(obj != undefined){
                    let lgh = Object.keys(obj).length;
                    if(lgh == 1) return obj
                    if(lgh > 1){
                        let msg = { msg: {id: '', text: 'Selecione um modelo'}}
                        return {...msg, ...obj }
                    }
                }
                return {empty: {id: '', text: ''}}
            },
            handleFormParamKey(){
                let cp = this.getCampaign.campaign_type
                if(cp == 'Novos') return 'campaigns_novos'
                if(cp == 'Usados') return 'campaigns_used'
                if(cp == 'Após Venda') return 'campaigns_aftersale'
                return false
            }
        },
        created(){ document.getElementById('cpsLink').classList.add('active'); },
        unmounted(){ document.getElementById('cpsLink').classList.remove('active'); }
    }
</script>
<style lang="scss">
    .contInfoLegal{
        @extend %container;
        @include mixin_text_content(font11, $darkCol, $lineHeight: 1.375rem);
        padding: 3.5rem 0;
    }
</style>
