<template>
    <main>
        <SimpleBanner 
        :bannerImgMobile="geral_field.banner_noticias_img" 
        :bannerImgDesktop="geral_field.banner_noticias_imgD" 
        :bannerTitle="geral_field.sec_news_title"/>
        <ListNews :objNews="news" :objFilterNews="newsCats"/>
    </main>
</template>
<script>
    import { mapState } from 'vuex'
    import SimpleBanner from '../components/Banners/SimpleBanner/SimpleBanner.vue'
    import ListNews from '../components/Sections/ListNews/ListNews.vue'

    export default {
        name: 'NewsPage',
        components:{ SimpleBanner, ListNews, },
        computed:{
            ...mapState(['news', 'geral_field', 'newsCats']),
        },   
    }
</script>