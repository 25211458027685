import axios from "axios";

const govMaster = process.env.VUE_APP_GOV_MASTER
const apiUsados = process.env.VUE_APP_API_USADOS
const UrlUsed = govMaster + apiUsados

const state = () => ({
    lstUsedCars: [],
    isGettingCars: true
});

const getters = {
    getHomepageCars: state => {
        let l = state.lstUsedCars.length
        let res = (l > 0) ? state.lstUsedCars.slice(0,4).every(el => el != null) : false
        return res ? state.lstUsedCars.slice(0,4) : false;
    },
}

const mutations = {
    SET_USADOS(state, payload){
        state.lstUsedCars = payload;
        state.isGettingCars = false;
    },
};

const actions = {
    fetchUsedCars({ commit }, dealer){
        const url = UrlUsed + '/' + dealer
        axios.get(url)
        .then( res => commit('SET_USADOS', res.data))
        .catch( err => console.log(err))
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}