<template>
    <div v-show="filterItems?.[0]" class="pipFilter">
        <p v-for="(item, i) in filterItems" :key="i"
        :class="{ active:i == 0 }"
        @click="setCurrGal($event, item.id)"> {{ item.label }} </p>
    </div>
</template>
<script>
    export default {
        name: 'PipGalFilter',
        props:{
            filterItems: {type: Object, default:  () => { return {}; }}
        },
        methods:{
            setCurrGal(e, currOpt){
                this.$emit('update:activeOpt', currOpt);
                let filterItems = document.querySelectorAll('.pipFilter > p');
                [...filterItems].forEach(el => {
                    el.classList.remove('active');
                });
                e.target.classList.add('active');
            }
        }   
    }
</script>
<style lang="scss" scoped>
    .pipFilter{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 2rem auto;

        p{
            cursor: pointer;
            @include mixin_text_content(font14, $greyCol, );
            
            &.active{
                color: $primaryCol;
                font-family: $mediumFont;
            }
            &:not(:last-child){ margin-right: .7rem;}
        }
    }

    @include respond-to(large){
        .pipFilter p{font-size: 1.125rem;
            &:not(:last-child){ margin-right: 2rem;}
        }
    }
</style>