export const swiperBreakPoints = {
    data(){
        return{
            swiperOptions: {
                768:{
                    slidesPerView: 3,
                },
                992:{
                    slidesPerView: 4,
                },
                1366:{
                    slidesPerView: 5,
                }
            }
        }
    }
};

export const parcialSwiperBreakPoints = {
    data(){
        return{
            swiperOptions: {
                0:{
                    slidesPerView: 1.1,
                },
                576:{
                    slidesPerView: 2.2,
                },
            }
        }
    }
};

export const breakPointsSwiperUsadosHp = {
    data(){
        return{
            swiperOptions: {
                0:{
                    slidesPerView: 1.1,
                },
                768:{
                    slidesPerView: 2,
                    spaceBetween: 20
                },
            }
        }
    }
};

export const breakPointsPips360 = {
    data(){
        return{
            swiperOptions: {
                0:{
                    slidesPerView: 5,
                },
                576:{
                    slidesPerView: 7,
                },
                768:{
                    slidesPerView: 9,
                },
            }
        }
    }
};
