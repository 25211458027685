export const viewer360deg = {
    data(){return{
        // currImg: '',
        // modelImgs: [],
        // maxLenght: 1,
        currImg: '',
        num: 0,
        isMoving: false,
        speed: 15,
        savedPositionX: 0,
        currentPositionX: 0,
    }},
    methods:{
        handleMouseDown(e){
            this.isMoving = !this.isMoving;
            this.savedPositionX = e.pageX;
        },
        stopRotation(){
            this.isMoving = !this.isMoving;
        },
        handleMouseLeave() {
            if (this.isMoving) {
                this.isMoving = false;
            }
        },
        handleMouseMove(e) {
            this.cfgGet360Modal(e.pageX);
        },
        handleTouchStart(e) {
            // e.preventDefault();
            this.savedPositionX = e.touches[0].pageX;
            this.isMoving = true;
        },
        handleTouchMove(e) {
            // e.preventDefault();
            this.cfgGet360Modal(e.touches[0].pageX);
        },
        cfgGet360Modal(currentPosition){
            if (this.isMoving && this.maxLenght > 1) {
                this.currentPositionX = currentPosition;
                const distance = this.currentPositionX - this.savedPositionX;
                if (Math.abs(distance) > this.speed) {
                    this.savedPositionX = this.currentPositionX;
                    if ((distance > 0 && !this.reverse) || (distance < 0 && this.reverse)) {
                        this.slideToRight();
                    } else {
                        this.slideToLeft();
                    }
                }
            }
        },
        slideToRight(){
            if(this.num == this.maxLenght){
                this.currImg = this.modelImgs[0]
                this.num = 0;
            }else{
                this.num = this.num + 1;
                this.currImg = this.modelImgs[this.num]
            }
        },
        slideToLeft(){
            if(this.num == 0){
                this.currImg = this.modelImgs[this.maxLenght]
                this.num = this.maxLenght;
            }else{
                this.num = this.num - 1;
                this.currImg = this.modelImgs[this.num]
            }
        },
    }
}