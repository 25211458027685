<template>
    <div v-if="checkboxObj" class="form-group-checkbox">
        <input type="checkbox" 
        :name="checkboxObj.name" 
        :id="checkboxObj.id" 
        :value="checkboxObj.value"
        :checked="checkboxObj.checked || false"
        :disabled="checkboxObj.disabled || false"
        @change="inputChanged($event)">

        <label v-if="checkboxObj.displayAsHtml"
        :for="checkboxObj.name" v-html="checkboxObj.content"></label>
        
        <label v-else
        :for="checkboxObj.name">{{ checkboxObj.content }}</label>
        <p class="rgErrorMessage"></p>
    </div>
</template>
<script>
    export default {
        name: 'InputCheckbox',
        props: {
            checkboxObj: { type: Object, default: () => { return {}; }},
        },
        methods: {
            inputChanged(e){
                this.$emit('checkbox-updated', e.target.checked)
            }
        }
    }
</script>
<style lang="scss">
    @import './InputCheckbox.scss';
</style>