<template>
    <div :class="contClass">
        <p class="campaignCard-content-info">{{ infoLabel }}
            <b v-show="boldValue">{{ boldValue }}</b>
        </p>
        <svg xmlns="http://www.w3.org/2000/svg" width="14.125" height="14.124" viewBox="0 0 14.125 14.124">
            <g id="Group_7386" data-name="Group 7386" transform="translate(-26.499 -33.385)">
                <path id="Path_16749" data-name="Path 16749" d="M33.561,33.885a6.562,6.562,0,1,0,6.562,6.562A6.562,6.562,0,0,0,33.561,33.885Z" fill="none" stroke="#898989" stroke-miterlimit="10" stroke-width="1"/>
                <g id="Group_6935" data-name="Group 6935" transform="translate(32.412 35.695)">
                <path id="Path_16750" data-name="Path 16750" d="M48.115,48.74a.993.993,0,1,1-1.986,0V44.573a.993.993,0,0,1,1.986,0Zm-.993-6.212a1.151,1.151,0,0,1-1.149-1.149,1.164,1.164,0,0,1,1.149-1.149,1.149,1.149,0,0,1,0,2.3Z" transform="translate(-45.973 -40.23)" fill="#898989"/>
                </g>
            </g>
        </svg>
    </div>
</template>
<script>
    export default {
        name: 'InfoLegal',
        props: {
            contClass: {type: String, default: 'infoLegal'},
            infoLabel: {type: String, default: 'Informação legal'},
            boldValue: {type: [String, Number]},
        },
    }
</script>
<style lang="scss">
    .infoLegal{
        display: flex;
        align-items: center;
        @include mixin_text_content(font14, $color: $greyCol, $textAlign: left);
        padding: .5rem 0;
        cursor: pointer;
        // margin: 1rem auto;
        margin-top: .75rem;

        & svg{ margin-left: .5rem;}
    }
</style>
