export const handleModalFunctions = {
    data(){
        return {
            currentIndex: 0,
            openModal: false,
            modalData: '',
        }
    },
    methods:{
        getCurrentIndex(value){
            if(value != this.currentIndex){
                this.currentIndex = value;
            }
            this.openModal = true;
            this.modalData = this.objCampaigns[this.currentIndex].campaign_info_legal,
            document.body.classList.add('isActive');
        },

        changeModalState(value){
            this.openModal = value
        }
    }
}