<template>
    <section class="cpCounter">
        <p class="cpCounter-label">Esta oportunidade termina em:</p>
        <!-- <ul v-if="showCounter == 'Sim'"> -->
        <ul>
            <li><p>{{ days }}</p>
                <p>Dias</p></li>
            <li><p>{{ hours }}</p>
                <p>Horas</p></li>
            <li><p>{{ minutes }}</p>
                <p>Minutos</p></li>
            <li><p>{{ seconds }}</p>
                <p>Segundos</p></li>
        </ul>
        <!-- <p v-else class="cpCounter-endDate"> {{ getDateText }} </p> -->
        <div class="btnWrapper">
            <GovButton :openNewTab="false" btnLink="#sendform" svgItem="arrow "/>
        </div>
    </section>
</template>
<script>
    import GovButton from '../../Buttons/GovButton.vue'

    export default {
        name: 'DownCounter',
        components:{ GovButton, },
        props:{
            starttime:{type: String, default: '' },
            endtime:{type: String, default: '' },
            dateText:{type: String,},
            showCounter:{type: String},
        },
        data(){
            return{
                start: "",
                end: "",
                interval: "",
                days:"",
                minutes:"",
                hours:"",
                seconds:"",
            }
        },
        mounted(){
            this.start = new Date(this.starttime).getTime();
            this.end = new Date(this.endtime).getTime();
            this.timerCount(this.start,this.end);
            if(this.showCounter == 'Sim'){
                this.interval = setInterval(() => {
                    this.timerCount(this.start,this.end);
                }, 1000);
            }
        },
        computed:{
            // getDateText(){
            //     let arr = this.dateText.split('até ')[1];
            //     console.log(arr.split());
            //     let _arr = arr.replace(/ /g, ' de ');
            //     console.log(_arr);
            //     return _arr;
            // }
        },
        methods:{
            timerCount(start,end){
                let now = new Date().getTime();
                let distance = start - now;
                let passTime = end - now;

                if(distance < 0 && passTime < 0){
                     this.days = '00';
                     this.hours = '00';
                     this.minutes = '00';
                     this.seconds = '00';

                }else{
                    this.calcTime(passTime);
                }
            },
            calcTime(dist){
                this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
                this.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
                this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
            },
        },
    }
</script>
<style lang="scss">
    @import './DownCounter.scss';
</style>