<template>
    <div v-if="showModels" class="navModelsMobile">
        <div v-for="(el, i) in modelsType" :key="i" class="navModelsMobile-accordion">
            <p class="navModelsMobile-accordion-header" @click="showAccordionContent"> {{ el }} </p>
            <div class="navModelsMobile-accordion-content">
                <ModelCard v-for="(item, i) in accordionModels(el)" :key="i" 
                :modelItem="item" cardClass="navCardModel"
                @click="this.$emit('update:value', false)" />
            </div>
        </div>
    </div>
</template>
<script>
    import ModelCard from '../../Cards/ModelCard/ModelCard.vue'

    export default {
        name: 'ModelsMobile',
        components: { ModelCard, },
        props:{
            showModels: {type: Boolean, default: false},
            modelsType: {type: Array, default: () => { return [];}},
            modelsObj: {type: Object, default: () => { return {};}},
        },
        methods:{
            accordionModels(segment){
                return this.modelsObj.filter(el => el.segment.includes(segment));
            },
            showAccordionContent(e){
                let accordionheader = document.querySelectorAll('.navModelsMobile-accordion-header');
                let accordionContent = document.querySelectorAll('.navModelsMobile-accordion-content');

                if(!e.target.classList.contains('active')){
                    [...accordionContent, ...accordionheader].forEach(el => el.classList.remove('active'));
                }

                e.target.classList.toggle('active');
                e.target.nextElementSibling.classList.toggle('active');
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import './ModelsMobile.scss';
</style>