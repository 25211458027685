<template>
    <div :class="{bluePriceBox: true, 'isCp': type}">
        <div class="price" v-html="cpBanner.cp_banner_preco"></div>
        <div class="info" v-html="cpBanner.cp_banner_informacao"></div>
        <div class="taeg" v-html="cpBanner.cp_banner_taeg"></div>
    </div>
</template>
<script>
    export default {
        name: 'PriceBlueBox',
        props:{
            cpBanner: {type: Object, default: () =>{ return {}; }},
            type: {type: Boolean, default: false},
        }
    }
</script>
<style lang="scss">
    .bluePriceBox{
        width: fit-content;
        @include mixinPriceBox();
        
        &.isCp{
            @include mixinPriceBox($type:'cp');
        }

        @include respond-to(maxLarge){
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
</style>