<template>
    <section class="usadosCardList">
        <UsadosCard v-for="(item, index) in carData" :key="index"
        :carObj="item"
        :isDefault="true"
        @change="console.log('change')"/>
        <div v-show="carData.length == 0" class="zeroCars" v-html="noCarsLabel"></div>
    </section>
    <div v-show="showBtn" class="btnCont">
        <SimpleButton :btnText="this.dislayAll ? this.btnText[1] : this.btnText[0]" 
        btnClass="btn-tertiary" svgItem="arrow" @click="showAllUsados"/>
    </div>
</template>
<script>
    import UsadosCard from '../../Cards/UsadosCard/UsadosCard.vue'
    import SimpleButton from '../../Buttons/SimpleButton.vue'

    export default {
        name: 'ListCarsUsados',
        components: { UsadosCard, SimpleButton },
        props:{
            showBtn: {type: Boolean, default: true},
            usadosCarsObj: { type: Object, default: () => { return {}; }},
            noCarsLabel: { type: String },
        },
        data(){return{
            dislayAll: false,
            starterCars: 8, 
            btnText:  ['Ver todos', 'Ver menos'],
        }},
        computed:{
            carData(){
                let maxCars = this.starterCars;
                let usedCars = this.usadosCarsObj;
                if(this.dislayAll || usedCars.length < maxCars){
                    return usedCars;
                }else{
                    return usedCars.slice(0,maxCars);
                }
            }
        },
        methods:{
            showAllUsados(){
                this.dislayAll = !this.dislayAll;
            }
        }
    }
</script>
<style lang="scss">
    @import './ListCarsUsados.scss';
</style>