<template>
    <section class="newsContainer">
        <div class="listNews">
            <NewsCard v-for="(item, i) in filterNews" :key="i" :newsItem="item"/>
        </div>
        <div class="newsSidebar">
            <NewsCats
                :objOptions="objFilterNews.categorias"
                v-model:selected="selectedCat"
            />
            <div class="tematicasMobile select">
                <select
                @change="handleCatsSelect($event)">
                    <option v-if="showSelectDefault" value="0">Selecionar categoria</option>
                    <option v-for="(item, i) in objFilterNews.categorias" :key="i"
                    :value="item"> {{ item }} </option>
                </select>
            </div>
            <hr>
            <NewsCats v-if="objFilterNews.tematicas?.length" :objOptions="objFilterNews.tematicas" :startRows="12" :isCats="false" v-model:selected="selectedTheme"/>
        </div>
        <div v-show="showBtn" class="button">
            <SimpleButton :btnText="showAllNews ? 'Ver menos' : 'Ver todos'" svgItem="arrow" @click="changeNewsLenght"/>
        </div>
    </section>
</template>
<script>
    import NewsCard from '../../Cards/NewsCard/NewsCard.vue'
    import NewsCats from '../../SectionParts/NewsCats.vue'
    import SimpleButton from '../../Buttons/SimpleButton.vue'

    export default {
        name: 'ListNews',
        components: { NewsCard, NewsCats, SimpleButton, },
        props:{
            objNews: { type: Array, default: () => { return []; }},
            objFilterNews: { type: Object, default: () => { return {}; }},
        },
        data(){return {
            selectedCat: false,
            selectedTheme: false,
            showSelectDefault: true,
            maxNews: 6,
            showAllNews: false,
            showBtn: true,
            filteredObjLenght: 0,
        }},
        watch:{
            filteredObjLenght: function(val){
                this.showBtn = (val < this.maxNews) ? false : true
            },
        },
        mounted(){
            const cat = this.$route.query.cat;
            if(cat != null) this.selectedCat = cat
        },
        computed:{
            filterNews(){
                let maxNews = this.maxNews;
                let res = this.handleFilterNews();

                if(this.showAllNews) return res;
                return res.slice(0,maxNews)
            },
        },
        methods:{
            handleCatsSelect(e){
                let val = e.target.value
                this.selectedCat = val != 0 ? val : false
            },
            changeNewsLenght(){
                this.showAllNews = !this.showAllNews
            },
            handleFilterNews(){
                const obj = this.objNews;
                const cat = this.selectedCat
                const theme = this.selectedTheme

                if(!cat && !theme){
                    this.filteredObjLenght = obj.length
                    return obj
                }
               
                const res = obj.filter(el  => {
                    const hasTheme = el.tematicas.find( item => item == theme)
                    const hasCat = el.categorias.find( item => item == cat)

                    if(cat && theme) return hasTheme && hasCat && el
                    if(cat) return hasCat && el
                    if(theme) return hasTheme && el
                })
                
                this.filteredObjLenght = res.length
                return res
            }
        }
    }
</script>
<style lang="scss">
    @import './ListNews.scss';
</style>