<template>
    <div v-if="showModels" class="navModelsDesktop">
        <div class="navModelsDesktop-filter">
            <p v-for="(item, i) in modelsType" :key="i" @click="filterModels($event)"> {{ item }} </p>
        </div>

        <div class="navModelsDesktop-list">
            <ModelCard v-for="(item, i) in newModels" :key="i"
            :modelItem="item" cardClass="navCardModel"
            @click="this.$emit('update:value', false)" />
        </div>

        <div class="desktopBtns">
            <img :src="garantyImg" alt="Imagem selo 7 anos de garantia"/>
            <GovButton :displayAtag="false" toRoute="/test-drive" btnClass="btn-outline" btnText="Marcar Test Drive" svgItem="arrow"/>
            <GovButton :displayAtag="false" toRoute="/campanhas" btnClass="btn-outline" btnText="Campanhas" svgItem="arrow"/>
        </div>
        <div class="closeNewModels" @click="this.$emit('update:value', false)">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.432" height="7.276" viewBox="0 0 12.432 7.276"><g transform="translate(-714.863 -650.361)"><path d="M-8139.394,2023.428l5.686-5.685,2.843,2.843,2.843,2.843" transform="translate(8854.787 -1366.321)" fill="none" stroke="#002c5f" stroke-width="1.5"/></g></svg>
        </div>
    </div>
</template>
<script>
    import ModelCard from '../../Cards/ModelCard/ModelCard.vue'
    import GovButton from '../../Buttons/GovButton.vue'

    export default {
        name: 'ModelsDesktop',
        components:{ ModelCard, GovButton, },
        props: {
            showModels: {type: Boolean, default: false},
            modelsType: {type: Array, default: () => { return [];}},
            modelsObj: {type: Object, default: () => { return {};}},
        },
        data(){return{
            newModels: [],
            garantyImg: require("@/assets/SELO_7_ANOS_2022_RGB-02.webp"),
        }},
        mounted(){
            this.newModels = this.modelsObj;
        },
        updated(){
            if(!this.showModels){
                this.newModels = this.modelsObj;
            }
        },
        methods:{
            filterModels(e){
                let currPick = e.target;
                let options = document.querySelectorAll('.navModelsDesktop-filter > p');
                [...options].forEach(el => el.classList.remove('active'));

                this.newModels = this.modelsObj.filter(el => el.segment.includes(currPick.textContent));
                currPick.classList.add('active');
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import './ModelsDesktop.scss';
</style>
