<template>
    <swiper
    class="bannerSwiper"
    :slides-per-view="1"
    :space-between="0"
    navigation
    :pagination="{ clickable: true }">
        <swiper-slide v-for="(item, index) in bannerLst" :key="index">
            <HpBanner :bannerData="item"/>
        </swiper-slide>
    </swiper>
</template>
<script>
    import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import "swiper/swiper-bundle.css";
    SwiperCore.use([ Navigation, Pagination, A11y]);
    import HpBanner from '../Banners/HpBanner/HpBanner.vue'
    import { mapState } from 'vuex';

    export default {
        name: 'SwiperBannerHp',
        components: {
            Swiper,
            SwiperSlide,
            HpBanner,
        },
        props:{
            banners: {type: Object, default: () => { return {}; }}
        },
        data(){return{
            bannerLst: {},
        }},
        beforeMount(){
            const hasBanner = this.banners?.length != 0
            this.bannerLst = hasBanner ? this.banners : [ this.geral_field.defaultBanner ]
        },
        computed:{
            ...mapState(['geral_field']),
        },
    };
</script>
<style lang="scss">
    .bannerSwiper{
        .swiper-pagination-bullets{
            bottom: 4.5rem;
        }
        .swiper-pagination-bullet{background-color: $whiteCol; opacity: 1;}
        .swiper-button-next,
        .swiper-button-prev{ display: none;}

        @include respond-to(large){
            .swiper-button-next,
            .swiper-button-prev{ display: block;}

            .swiper-button-prev{ left: 2rem;
                &:after{
                content: url(../../assets/leftChev.svg);
            }}
            .swiper-button-next{ right: 2rem; transform: rotate(180deg);
                &:after{
                content: url(../../assets/leftChev.svg);
            }}
        }
    }
</style>
