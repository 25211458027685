<template>
    <section class="hpUsados">
        <BgImg :scrMobile="objBannerData.img_mobile" :scrDesktop="objBannerData.img"/>
        <div class="hpUsados-content">
            <div class="hpUsados-content-filter">
                <h4 v-html="objBannerData.title"></h4>
                <h5> {{ objBannerData.text }} </h5>
                <UsadosFilter :filterData="objFilter" />
            </div>
            <div class="hpUsados-content-logo" v-html="objBannerData.logo"></div>
        </div>
    </section>
</template>
<script>
    import BgImg from '../../SimpleComponents/BgImg.vue'
    import UsadosFilter from '../../SectionParts/UsadosFilter/UsadosFilter.vue'

    export default {
        name: 'UsadosBanner',
        components: { BgImg, UsadosFilter, },
        props:{
            objBannerData: {type: Object, default: () => { return {}; }},
            objFilter: {type: Object, default: () => { return {}; }},
        },
    }
</script>
<style lang="scss">
    @import './UsadosBanner.scss';
</style>