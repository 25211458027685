<template>
    <router-link v-if="newsItem.news_type == 'noticias'" :to="'/noticias/' + newsItem.route" class="newsCard">
        <BgImg :hasOneImg="true" :scrDesktop="newsItem.image" altText="imagem ilustrativa noticia"/>
        <div class="newsCard-content">
            <p class="newsCard-content-date">{{ newsItem.news_date}}</p>
            <h3 class="newsCard-content-title">{{ newsItem.title}}</h3>
        </div>
        <div class="btn-newsCard">
            <SvgList svgItem="arrow" />
        </div>
    </router-link>

    <a v-else :href="newsItem.link" target="_blanck"  class="newsCard">
        <BgImg :hasOneImg="true" :scrDesktop="newsItem.image" altText="imagem ilustrativa noticia"/>
        <div class="newsCard-content">
            <p class="newsCard-content-date">{{ newsItem.news_date}}</p>
            <h3 class="newsCard-content-title">{{ newsItem.title}}</h3>
        </div>
        <div class="btn-newsCard">
            <SvgList svgItem="arrow" />
        </div>
    </a>
</template>
<script>
    import BgImg from '../../SimpleComponents/BgImg.vue'
    import SvgList from '../../SimpleComponents/SvgList.vue'

    export default {
        name: 'NewsCard',
        components: { BgImg, SvgList },
        props:{
            newsItem: { type: Object, default: () => {return {}; }},
        }   
    }
</script>
<style lang="scss">
    @import "./NewsCard.scss";
</style>