<template>
    <section class="viewer360deg-content-colors">
        <div v-for="(item, i) in colors" :key="i"
        :class="{color360image: true, active: item.id == colorId}"
        v-show="item.externalColorImages360.length != 0"
        @click="handledDotColor($event, item.id )">
            <p class="dot360pips"
            :style="'background-image: url(' + setBgImg(item.colorchip) + ')'"></p>
            <p> {{ item.designation }} </p>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'Colors360Desktop',
        props:{
            colors: { type: Object, default: () => { return {}; }},
            colorId: {type: Number},
        },
        data(){return{
            defaultImgChip: require('../../../assets/nopicture_38.webp'),
        }},
        methods:{
            handledDotColor(e, colorId){
                let dots = document.querySelectorAll('.color360image');
                [...dots].forEach(el => el.classList.remove('active'));
                e.target.classList.add('active');
                this.$emit('update:colorId', colorId);
                this.$emit('change-color', colorId);
            },
            setBgImg(colorchip){
                if(colorchip != ''){
                    return colorchip;
                }else{
                    return this.defaultImgChip;
                }
            }
        }
    }
</script>