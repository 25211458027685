<template>
    <swiper
    :breakpoints="swiperOptions"
    class="destaqueUsadosSwiper"
    :slides-per-view="1.1"
    :space-between="10"
    :pagination="{ clickable: true }"
    >
        <swiper-slide v-for="(item, index) in usadosObj" :key="index">
            <UsadosCard :isDefault="false" :carObj="item"/>
        </swiper-slide>
    </swiper>
</template>
<script>
    import SwiperCore, { Pagination, A11y } from "swiper";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import "swiper/swiper-bundle.css";
    SwiperCore.use([ Pagination, A11y]);
    import UsadosCard from '../Cards/UsadosCard/UsadosCard.vue'
    import {breakPointsSwiperUsadosHp} from '../../mixins/swiperBreakPoints'

    export default {
        name: 'SecUsadosHPSwiper',
        components: { Swiper,SwiperSlide, UsadosCard },
        mixins: [breakPointsSwiperUsadosHp],
        props: {
            usadosObj: {type: Object, default: () => { return {}; }},
        },
    }
</script>