<template>
    <main v-if="handlePageContentDisplay">
        <PipBanner
        :bannerObj="getPipById(modelId)"
        :pipId="modelId"
        :pipModel="modelTitle"
        />
        <PipDescription
        :title="getPipById(modelId).pip_title"
        :text="getPipById(modelId).pip_description"
        :price="getPipById(modelId)?.minPrice"
        :power="getPipById(modelId)?.power"
        :powerkw="getPipById(modelId)?.powerKw"
        :fuelAverage="getPipById(modelId)?.fuelAverage"
        :modalContent="getPipById(modelId).pip_legalNote"
        />
        <PipGallery v-if="getPipById(modelId).pip_gal_ext"
        :secTitle="geral_field.sec_pip_gal_title"
        :extGallery="getPipById(modelId).pip_gal_ext"
        :intGallery="getPipById(modelId).pip_gal_int"
        :video="getPipById(modelId).pip_video"
        :linkCatalogo="getPipById(modelId).pip_catalog"/>
        <Car360view v-if="getPipById(modelId)?.colors?.[0]"
        :title="getPipById(modelId).pip_name"
        :btnTestDrive="getPipById(modelId).btn_test_drive"
        :btnCfg="getPipById(modelId).show_btn_cfg"
        :pipId="modelId"
        :pipModel="modelTitle"
        :pipColors="getPipById(modelId)?.colors" />
        <p class="legalNote" v-html="getPipById(modelId).requiredLegalTxt"></p>
        <SimpleBanner v-if="getPipById(modelId).pip_footer_banner"
        :bannerImgDesktop="getPipById(modelId).pip_footer_banner"
        :bannerImgMobile="getPipById(modelId).pip_footer_banner"/>
        <GarantyBanner />
    </main>
    <LoadingPage v-else />
</template>
<script>
    import { mapGetters, mapState } from 'vuex'
    import LoadingPage from './LoadingPage.vue'
    import PipBanner from '../components/Banners/PipBanner/PipBanner.vue'
    import PipDescription from '../components/Sections/PipDescription/PipDescription.vue'
    import PipGallery from '../components/Sections/PipGallery/PipGallery.vue'
    import Car360view from '../components/Sections/Car360view/Car360view.vue'
    import SimpleBanner from '../components/Banners/SimpleBanner/SimpleBanner.vue'
    import GarantyBanner from '../components/Banners/GarantyBanner/GarantyBanner.vue'

    export default {
        name: 'PipDetails',
        components: { LoadingPage, PipBanner, PipDescription, PipGallery, Car360view, SimpleBanner, GarantyBanner },
        created(){
            document.getElementById('pipsLink').classList.add('active');
            this.findModelId()
        },
        updated(){
            document.getElementById('pipsLink').classList.add('active');
            this.findModelId()
        },
        unmounted(){
            document.getElementById('pipsLink').classList.remove('active');
        },
        data(){return{
            modelId: null,
            modelTitle: null,
        }},
        watch: {
            modelId: function(){
                let obj = this.getPipById(this.modelId);
                if(obj == undefined) {
                    this.$store.dispatch('pipData/fetchCfgData', this.modelId)
                }
            }
        },
        computed: {
            ...mapState(['geral_field', 'pips', 'models']),
            ...mapGetters({
                getPipById: 'pipData/getPipById',
            }),
            handlePageContentDisplay(){
                let id = this.modelId
                let obj = this.getPipById(id);
                if(obj?.pip && !obj?.cfg) return obj?.cfgNull ? true : false
                return (obj?.pip && obj?.cfg) ? true : false
            }
        },
        methods: {
            findModelId(){
                let title = this.$route.params.title;
                this.models.find(el => {
                    if(el.route == title){
                        this.modelId = el.id_model;
                        this.modelTitle = el.model_title;
                    }
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .legalNote{
        margin: 3rem auto;
        @include mixin_text_content(font14, $blackCol, center);
        @extend %container;
    }
</style>
