const state = () => ({
    component: 'CookiesModal',
    openModal: false,
    showHeader: false,
    title: '',
    modalClass: '',
    contentClass: '',
    bgClose: true,
});

const getters = {

}

const mutations = {
    UPDATE_STATE(state, payload){
        state = Object.assign(state, payload);
    },
    CLOSE_MODAL(state){
        state.openModal = false
        // state.modalClas = 'modal-content'
    },
}

const actions = {
    
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

