<template>
    <section class="simpleBanner">
        <BgImg :scrMobile="bannerImgMobile" :scrDesktop="bannerImgDesktop" altText="Imagem" />
        <div v-if="displayContent" class="simpleBanner-content">
            <h1 class="simpleBanner-content-title"> {{ bannerTitle }} </h1>
        </div>
    </section>
</template>
<script>
    import BgImg from '../../SimpleComponents/BgImg.vue'

    export default {
        name: 'SimpleBanner',
        components:{ BgImg, },
        props:{
            displayContent: {type: Boolean, default: true},
            bannerTitle: {type: String, default: ''},
            bannerImgMobile: {type: String, default: ''},
            bannerImgDesktop: {type: String, default: ''},
        },   
    }
</script>
<style lang="scss">
    @import './SimpleBanner.scss';
</style>