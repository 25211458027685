<template>
    <section class="news">
        <p class="news-date"> {{ objCurrNews.news_date }} </p>
        <h1> {{ objCurrNews.title}} </h1>
        
        <div class="news-wrap">
            <div class="news-main">
                <Image :srcImg="objCurrNews.image" theClass="news-img" />
            <div class="news-tematics">
                <p v-for="(item, index) in objCurrNews.tematicas" :key="index"> {{ item }} </p>
            </div>
            <div class="news-content" v-html="objCurrNews.content"></div>
        </div>

        <div class="news-sidebar">
            <div class="news-articles">
                <h3>Artigos populares</h3>
                <PopularNewsCard v-for="(item, i) in newsObj" :key="i" :dataObj="item" />
            </div>
            <NewsCats :objOptions="objFilterNews.categorias" @search-news="backToNews($event)"/>
        </div>
       </div>
    </section>
</template>
<script>
    import Image from '../../SimpleComponents/Image.vue'
    import NewsCats from '../../SectionParts/NewsCats.vue'
    import PopularNewsCard from '../../Cards/PopularNewsCard/PopularNewsCard.vue'
    export default {
        name: 'NewsContent',
        components: { Image, NewsCats, PopularNewsCard },
        props: {
            newsObj: { type: Object, default: () => { return {}; }},
            objCurrNews: { type: Object, default: () => { return {}; }},
            objFilterNews: { type: Object, dfault: () => { return {}; }},
        },
        methods:{
            backToNews(e){
                this.$router.push({
                    name: 'Noticias',
                    path: '/noticias',
                    query: {
                        cat: e.cat
                    },
                });
            }
        }
    }
</script>
<style lang="scss">
    @import './NewsContent.scss';
</style>