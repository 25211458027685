<template>
    <section v-if="checkIfHasEquip()" class="carEquipmts">
        <div class="carEquipmts-filter">
            <p v-for="(item, i) in  equipments" :key="i" :class="{'carEquipmts-filter-item': true, active: i == currTab }" @click="changeTabListing(i, item[1])"> {{ item[0] }} </p>
        </div>

        <div class="carEquipmts-list">
            <p v-for="(item, index) in getCurrList" :key="index" class="carEquipmts-list-item"> {{ item }} </p>
        </div>

        <swiper class="carEquipmtsSwiper"
        :slides-per-view="1"
        :space-between="30"
        :pagination="{ clickable: true }">
            <swiper-slide v-for="(index) in equipSlides" :key="index">
                <p v-for="(item, i) in currLst.slice((index-1) * interval,index * interval)" :key="i" class="carEquipmtsSwiper-slide-item"> {{ item }} </p>
            </swiper-slide>
        </swiper>

        <p v-show="maxLstLenght > 1" class="carEquipmts-btnMore" @click="handleLstRendered">{{ changeBtnName ? 'Mostar menos' : 'Mostrar mais' }}</p>

        <div class="carEquipmts-btnsCont">
            <GovButton
            :displayAtag="false"
            :toRoute="'/proposta-comercial?f=3&id=' + idCar"
            btnClass="btn-tertiary"
            btnText="Pedir Proposta"
            svgItem="arrow"/>
            <GovButton
            :displayAtag="false"
            :toRoute="'/saber-mais?f=1&id=' + idCar"
            btnClass="btn-tertiary"
            btnText="Saber mais"
            svgItem="arrow"/>
            <GovButton
            :displayAtag="false"
            :toRoute="{name: 'Concessionarios', params: { dealer: dealer }}" btnClass="btn-tertiary"
            btnText="Falar com o Concessionário"
            svgItem="arrow"/>
        </div>
    </section>
    <section v-else class="carEquipmts noEquipmts">
        <p> <b>Esta viatura não tem descrição dos equipamentos.</b> </p>
        <p>Para conhecer todas as caraterísticas desta viatura por favor entre em contacto com o concessionário deste Hyundai
             através do formulário, que pode aceder pelo botão acima.</p>
        <p>Obrigado pelo seu interesse neste Hyundai.</p>
    </section>
</template>
<script>
    import SwiperCore, { Pagination, A11y } from "swiper";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import "swiper/swiper-bundle.css";
    SwiperCore.use([ Pagination, A11y]);
    import GovButton from '../../Buttons/GovButton.vue'

    export default {
        name: 'EquipUsados',
        components: { Swiper, SwiperSlide, GovButton, },
        props:{
            objCarEquipmts: { type: Object, default: () => { return {}; }},
            idCar: { type: [Number, String]},
            dealer: { type: [Number, String]},
        },
        created(){
            this.setInitLst();
            this.lstLenght = this.interval;
            let equipsLenght = this.currLst.length / this.interval;
            this.equipSlides = Math.ceil(equipsLenght)
            this.checkIfHasEquip();
        },
        beforeUpdate(){
            // reset data when changes car __ while user on page UsadosDetails.vue
            let id1 = this.tempIdCar;
            let id2 = this.idCar;
            if(id1 != id2){
                this.tempIdCar = id2;
                this.lstLenght = this.interval;
                this.changeBtnName = false;
                this.currTab = 0;
                this.setInitLst();
            }

            let equipsLenght = this.currLst.length / this.interval;
            this.equipSlides = Math.ceil(equipsLenght)
        },
        data(){return{
            tempIdCar: this.idCar,
            equipSlides: '',
            fullEquiplst: [],
            fullEquiplstLenght: 0,

            currLst: [],
            interval: 10,
            lstLenght: 0,
            maxLstLenght : 0,
            currTab: 0,
            equipments: [
                ['Conforto', 'carSeriesEquipmentConfort'],
                ['Exterior', 'carSeriesEquipmentExteriorFinish'],
                ['Interior', 'carSeriesEquipmentInteriorFinish'],
                ['Multimédia', 'carSeriesEquipmentMultimediaAudio'],
                ['Segurança', 'carSeriesEquipmentSecurity'],
            ],
            changeBtnName: false,
        }},
        computed:{
            getCurrList(){ return this.currLst.slice(0, this.lstLenght); }
        },
        methods:{
            changeTabListing(i, key){
                this.currTab = i;
                this.lstLenght = this.interval;
                this.changeBtnName = false;
                this.currLst = this.objCarEquipmts[key].map(el => el.description);
                this.maxLstLenght = Math.ceil(this.currLst.length / this.interval);
            },
            setInitLst(){
                this.currLst = this.objCarEquipmts.carSeriesEquipmentConfort.map(el => el.description);
                this.maxLstLenght = Math.ceil(this.currLst.length / this.interval);
            },
            handleLstRendered(){
                let lstItem = Math.floor((this.lstLenght + this.interval) / this.interval);

                if(lstItem <= this.maxLstLenght){ this.lstLenght += this.interval}
                else{ this.lstLenght = this.interval }

                this.changeBtnName = (lstItem != this.maxLstLenght) ?  false : true ;
            },
            checkIfHasEquip(){
                let isEmpty = Object.values(this.objCarEquipmts).some(el => el.length > 0);
                return isEmpty;
            }
        }
    }
</script>
<style lang="scss">
    @import './EquipUsados.scss';
</style>
