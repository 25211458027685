export const dealersOptions = {
    data(){return{
        showroom: 'HC00010001',
        afterSale: 'HC00010003',
        showroomService: true
    }},
    computed:{
        setDealersObj(){
            let dealersOptions = [ { id: '', text: 'Selecione uma instalação' } ];
            let serviceID = this.showroomService ? this.showroom : this.afterSale
            let arr = this.dealers.filter(el => el.services.some(s => s.objectId == serviceID))

            arr.every(el => dealersOptions.push({
                id: el.objectId,
                text: el.postalCodeDesig
            }));
            return dealersOptions;
        }
    },
}

export const findDealer = {
    computed:{
        filterDealerSelected(){
            let currDealer = this.dealer;
            if(currDealer != undefined){
                let SD = this.dealers.find(el => el.objectId == currDealer);
                return SD;
            }
            return false
        }
    }
}
