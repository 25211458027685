<template>
    <router-link :to="'/modelos/' + modelItem.route" exact :class="cardClass">
        <p v-show="modelItem.model_tag != ''"
        :class="cardClass +'-tag ' + getTagClass(modelItem.model_tag)">
        {{ modelItem.model_tag }} </p>
        <BgImg :scrDesktop="modelItem.model_image" :hasOneImg="true"/>
        <p :class="cardClass + '-title'">{{ modelItem.model_title}}</p>
    </router-link>
</template>
<script>
    import BgImg from '../../SimpleComponents/BgImg.vue'

    export default {
        name: 'ModelCard',
        components: { BgImg, },
        props:{
            modelItem: {type: Object, default: () => { return {}; }},
            cardClass: { type:String, default: 'modelCard'},
        },
        methods:{
            getTagClass(tag){
                let tagClass = '';
                switch(tag){
                    case 'Brevemente': tagClass = "soon"; break;
                    case 'Em campanha':  tagClass = 'cp'; break;
                    case 'Novo': tagClass = 'new'; break;
                }
                return tagClass;
            },
        }
    }
</script>
<style lang="scss">
    @import './ModelCard.scss';
</style>