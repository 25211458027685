<template>
    <section class="modelSec" id="models">
        <p class="modelSec-title">{{ secTitle }}</p>
    </section>
    <ModelFilter :filterOpts="filterData" v-model:activeOpt="activeFilterOpt"/>
    <div class="mobileModelClass">
        <ModelCard  v-for="(item, index) in handleModelsData" :key="index" :modelItem="item"/>
    </div>
    <swiper
    class="modelsSwiper"
    :breakpoints="swiperOptions"
    :space-between="30"
    :slides-per-view="3"
    navigation>
        <swiper-slide v-for="(item, index) in handleModelsData" :key="index">
            <ModelCard :modelItem="item"/>
        </swiper-slide>
    </swiper>
</template>
<script>
    import SwiperCore, { Navigation, A11y } from "swiper";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import "swiper/swiper-bundle.css";
    SwiperCore.use([Navigation, A11y]);
    import ModelFilter from '../../SectionParts/ModelFilter.vue'
    import ModelCard from '../../Cards/ModelCard/ModelCard.vue'
    import {swiperBreakPoints} from '../../../mixins/swiperBreakPoints'

    export default {
        name: 'ModelsRange',
        components: { Swiper, SwiperSlide, ModelCard, ModelFilter},
        mixins:[swiperBreakPoints],
        props:{
            secTitle: { type: String, default: 'Que Hyundai procura?' },
            models: { type: Object, default: () => { return {}; }},
            filterData: { type: Array, default: () => { return []; }},
        },
        data(){
            return{
                activeFilterOpt: 'Gama',
            }
        },
        computed:{
            handleModelsData: function() {
                let segment = this.activeFilterOpt;
                if(segment == 'Gama'){
                    return this.models;
                }else{
                    return this.models.filter(item => item.segment.includes(segment) );
                }
            },
        }
    }
</script>
<style lang="scss">
    @import "./ModelsRange.scss";
</style>
