<template>
    <div class="campaignCard">
        <div class="campaignCard-img">
            <BgImg :hasOneImg="true" :scrDesktop="cpItem.campaign_img" :altText="'Imagem ' + cpItem.campaign_title"/>
        </div>
        <div :class="'campaignCard-content ' + cpType">
            <p :class="setCpDateClass(cpItem.campaign_type)"> {{ editDateString(cpItem.dateMsg) }} </p>
            <h3 class="campaignCard-content-title">{{ cpItem.campaign_title }}</h3>
            <p class="campaignCard-content-text">{{ cpItem.campaign_text }}</p>
            <InfoLegal v-if="cpItem.campaign_info_legal" @click="onClickReturnIndex"/>
            <GovButton v-if="cpItem.from !== 'bogov'" :btnClass="btnClass" svgItem="arrow"
            :btnLink="cpItem.campaign_link + dealerOID('parent')"/>

            <GovButton v-else :btnClass="btnClass" svgItem="arrow"
            :displayAtag="false"
            :toRoute="'/campanhas/' + cpItem.route"/>

            <GovButton v-if="cpType == 'hp'" btnClass="btn-primaryInverted" btnText="Ver mais campanhas"
            :displayAtag="false" toRoute="/campanhas" svgItem="arrow" />
        </div>
    </div>

</template>
<script>
    import { mapGetters } from 'vuex'
    import BgImg from '../../SimpleComponents/BgImg.vue'
    import GovButton from '../../Buttons/GovButton.vue'
    import InfoLegal from '../../SimpleComponents/InfoLegal.vue'

    export default {
        name: 'CampaignsCard',
        components:{ BgImg, InfoLegal, GovButton },
        props:{
            cpType: {type: String, default: 'cp'},
            cpIndex: {type: Number},
            cpItem: {type: Object, default: () => { return {}; }},
            btnClass: {type: String, default: 'btn-primary'},
        },
        computed:{
            ...mapGetters({
                dealerOID: 'get_oidDealerData'
            }),
        },
        methods:{
            onClickReturnIndex(){
                this.$emit('get-index', this.cpIndex);
            },
            editDateString(string){
                return string.substring(string.indexOf('até'));
            },
            setCpDateClass(type){
                let col = 'blue'
                if(type == 'Usados') col = 'orange';
                if(type == 'Após Venda') col = 'darkBlue';
                return `campaignCard-content-${col}`
            }
        }
    }
</script>
<style lang="scss">
    @import "./CampaignsCard.scss";
</style>
