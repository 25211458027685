<template>
    <div v-if="showModal" class="modal">
        <div class="modal-blend"></div>
        <div class="modal-content noBg">
            <swiper
            class="modalSwiper"
            :slides-per-view="1"
            :space-between="100"
            navigation
            @swiper="swiperGalery($event)"
            :pagination="{ clickable: true }">
                <swiper-slide v-for="(item, index) in swiperImgs" :key="index">
                    <img :src="item" alt="Model image">
                </swiper-slide>
            </swiper>
            <div class="closeModalSwiper" @click="closeModal">
                <svg xmlns="http://www.w3.org/2000/svg" width="17.67" height="17.67" viewBox="0 0 17.67 17.67">
                    <g id="Group_7145" data-name="Group 7145" transform="translate(0.354 0.354)">
                        <line id="Line_411" data-name="Line 411" x2="16.963" y2="16.963" fill="none" stroke="#fff" stroke-width="1"/>
                        <line id="Line_412" data-name="Line 412" x1="16.963" y2="16.963" fill="none" stroke="#fff" stroke-width="1"/>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
    import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import "swiper/swiper-bundle.css";
    SwiperCore.use([Navigation, Pagination, A11y]);
    // import Image from '../SimpleComponents/Image.vue'

    export default {
        name: 'ModalSwiper',
        components: { Swiper, SwiperSlide,  },
        props:{
            swiperImgs: { type: Array, default: () => { return []; }},
            showModal : { type: Boolean, default: false },
            starterSlide: { type: Number, default: 1}
        },
        methods:{
            closeModal(){
                document.body.classList.remove('isActive');
                this.$emit('update:showModal', false);
            },
            swiperGalery(e){
                e.slideTo(this.starterSlide,0,0)
            }
        },
        beforeUnmount(){
            this.closeModal();
        }
    }
</script>
<style lang="scss">
    .modal-content{
        &.noBg {background-color: unset !important;}
        .modalSwiper{
            user-select: none;
            width: 100%;
            height: 544px;
            padding: 0 3rem;

            img{
                @extend %img;
                object-fit: contain;
                // background: $darkCol;
            }

            .swiper-pagination-bullet{ background-color: $whiteCol ; opacity: 1;}
            .swiper-button-prev{
                left: 0;
                &:after{
                    content: url(../../assets/leftChev.svg);
                }
            }
            .swiper-button-next{
                right: 0;
                &:after{
                    content: url(../../assets/rightChev.svg);
                }
            }
        }
        .closeModalSwiper {
            position: absolute;
            top: 1.5rem;
            right: 4rem;
            width: 1.125rem;
            height: 1.125rem;
            cursor: pointer;
        }
    }
</style>