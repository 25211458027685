<template>
    <div v-if="filterOpts" :class="{'modelSec-filter': isActive === false, 'modelSec-filter open': isActive === true,}">
        <p class="active" @click="handleFilterOption($event)">Gama</p>
        <p v-for="(item, index) in filterOpts" :key="index" @click="handleFilterOption($event)">{{ item }}</p>
    </div>
</template>
<script>
    export default {
        name: 'ModelFilter',
        props: {
            filterOpts: { type: Array, default: () => { return []; }},
            activeOpt: { type: String, default: 'Gama'},
        },
        computed:{
            currentActiveOpt:{
                get(){ return this.activeOpt},
                set(activeOpt){ this.$emit('update:activeOpt', activeOpt)}
            }
        },
        data(){
            return{
                isActive: false,
            }
        },
        methods:{
            handleFilterOption(event){
                let filterCont = document.querySelectorAll('.modelSec-filter > p');                
                this.currentActiveOpt = event.target.textContent;
                [...filterCont].forEach(el => el.classList.remove('active'))
                event.target.classList.add('active');
                this.isActive = !this.isActive
            },
        }
    }
</script>
<style lang="scss" scoped>
    .modelSec-filter {
        &.open {
            z-index: 99;
            & > p:not(.active) { display: block;}
        }
        p:not(.active) { display: none;}
        & > .active{
            color: $primaryCol;
            font-family: $mediumFont;
        }

        @media only screen and (max-width: 768px){
            &.open{ 
                background-color: $whiteCol; 
                & > .active:after{
                    // transform: rotate(180deg);
                    // transform-origin: 50% 65%;
                    display: none;
                }
            }
            & > .active{
                width: 100%;
                background-color: $primaryCol;
                color: $whiteCol;
                display: inline-flex;
                justify-content: center;
                gap: .5rem;

                &:after{
                    content: url('../../assets//down_chev.svg');
                    display: block;
                }
            }
        }

        @include respond-to(medium){
            p{display: block !important;}
        }
    }
</style>