function getAllCookies() {
    const cookies = document.cookie;
    const cookiesArr = cookies ? cookies.split("; ") : [];
    const cookiesNames = [];
    if (cookiesArr.length > 0) {
      cookiesArr.forEach((el) => {
        cookiesNames.push(el.split("=")[0]);
      });
    }
  
    return cookiesNames;
  }
  
  function setCookie(cname, cvalue, exdays = 30) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = ";expires=" + d.toUTCString();
    /* const domain =
    ";domain=" + window.location.origin.replace(/(^\w+:|^)\/\//, ""); */
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }
  
  function deleteCookie(name) {
    // Main domain with leading dot, path /.
    document.cookie =
      name +
      "=; path=/; domain=." +
      window.location.hostname.split(".").slice(-2).join(".") +
      "; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    // Current hostname (domain or subdomain) without leading dot, path /.
    document.cookie =
      name + "=; path=/; domain=; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    // Current hostname (domain or subdomain) with leading dot, path /.
    document.cookie =
      name +
      "=; path=/; domain=" +
      window.location.hostname +
      "; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    // Current hostname (domain or subdomain) without leading dot, current path.
    document.cookie =
      name +
      "=; path=" +
      window.location.pathname +
      "; domain=; expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
  
  export { getAllCookies, setCookie, getCookie, deleteCookie };
  