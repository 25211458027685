<template>
    <div class="select" :id="field+'Select'">
        <select :id="field" :name="field" :disabled="isDisabled" @change="updateSelected($event)">
            <option v-for="(item, index) in fieldOptions" :key="index"
            :selected="isSelected ? setSelected(item.id) : false"
            :value="item.id"> {{ item.text }} </option>
        </select>
        <p class="rgErrorMessage"></p>
    </div>
</template>
<script>
    export default {
        name:  'DefaultSelect',
        props:{
            isSelected: {type: Boolean, default: false},
            field: { type: String},
            isDisabled: { type: Boolean, default: false},
            fieldOptions: { type: Object, default: () => { return {}; }},
            optSelected: {type: [String, Number]}
        },
        computed:{
            currentId:{
                set(optSelected){ this.$emit('update:optSelected', optSelected) },
                get(){ return this.optSelected },
            },
        },
        methods:{
            setSelected(id){
                return (this.optSelected == id) ? true : false;
            },
            updateSelected(e){
                this.currentId = e.target.value;
            },
        },
    }
</script>
