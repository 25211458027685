<template>
    <div :class="{'campaignsFilter': isActive === false, 'campaignsFilter open': isActive === true,}">
        <p id="currActiveOpt" class="currOption active" @click="changeFilterState($event)"> {{ activeOpt }} </p>
        <div class="campaignsFilter-options">
            <p v-for="(item, index) in filterCategories" 
            :key="index" 
            :class="{ active:index == 0 }"
            @click="changeFilterOption($event)"> {{ item }} </p>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'CampaignsFilter',
        props:{
            filterCategories: {type: Object, default: () => { return {}; }},
        },
        data(){
            return{
                isActive: false,
                activeOpt: 'Selecionar',
            }
        },
        computed:{
            isDesktop(){
                return window.innerWidth > 768 ? true : false
            }
        },
        methods:{
            changeFilterState(e){
                this.isActive = !this.isActive;
                e.target.parentElement.classList.toggle('open');
                (!this.isDesktop) && document.body.classList.toggle('isActive');
            },
            changeFilterOption(e){
                let filterCont = document.querySelectorAll('.campaignsFilter-options > p');
                [...filterCont].forEach(el => {
                    el.classList.remove('active');
                });
                e.target.classList.add('active');
                this.activeOpt = e.target.textContent;
                this.$emit('update:activeOpt', this.activeOpt);
                this.isActive = !this.isActive;
                (!this.isDesktop) && document.body.classList.toggle('isActive');
            },
        }
    }
</script>
<style lang="scss">
    @import './CampaignsFilter.scss';
</style>