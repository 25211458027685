<template>
    <section class="featuredContent">
        <div class="featuredContent-wrap">
            <h3> {{ text }} </h3>
            <h1> {{ title }} </h1>
            <h2> {{ subtitle }} </h2>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'UsadosPageContent',
        props:{
            text: { type: String},
            title: { type: String},
            subtitle: { type: String},
        },
    }
</script>
<style lang="scss">
    @import './UsadosPageContent.scss';
</style>