<template>
    <p> {{ label }}  <b> {{ value }} </b></p>
</template>
<script>
export default {
    name: 'LabelRegValBold',
    props:{
        label: { type: [String, Number] },
        value: { type: [String, Number] },
    }
}
</script>