<template>
    <div :class="fieldObj.roundFormat ? 'form-group-input round' : 'form-group-input'">
        <label :for="fieldObj.name"> {{ fieldObj.label }} </label>
        <input
        :type="fieldObj.type"
        :name="fieldObj.name"
        :id="fieldObj.id"
        :maxlength="fieldObj.maxlength"
        :placeholder="fieldObj.placeholder"
        :pattern="fieldObj?.pattern"
        :title="fieldObj?.dataError"
        :value="currVal"
        @change="handleInput($event)" />
        <p class="rgErrorMessage"></p>
    </div>
</template>
<script>
    export default {
        name: 'InputText',
        props:{
            inputVal: { String},
            fieldObj: { type: Object, default: () => { return {}; }},
        },
        computed:{
            currVal:{
                get(){ return this.inputVal },
                set(inputVal){this.$emit('update:inputVal', inputVal) },
            },
        },
        methods:{
            handleInput(e){
                this.currVal = e.target.value;
            }
        }
    }
</script>
<style lang="scss">
    @import './InputText.scss';
</style>
