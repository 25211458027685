<template>
    <section class="campaignInfo">
        <SvgList svgItem="chevron" />
        <h2>Campanha válida {{ dateInfo }} </h2>
        <h3>{{ textInfo }}</h3>
    </section>
</template>
<script>
    import SvgList from '../../SimpleComponents/SvgList.vue'

    export default {
        name: 'MoreInfo',
        components: { SvgList },
        props: {
            dateInfo: {type: String},
            textInfo: {type: String},
        }   
    }
</script>
<style lang="scss">
    @import './MoreInfo.scss';
</style>