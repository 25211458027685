<template>
    <div v-show="isOpen || showModels" class="openNavBg"></div>
    <nav :class="isOpen ? 'navbar active' : 'navbar'">
        <div class="navbar-top">
            <router-link to="/concessionarios">Como chegar? <IconPlace class="g-icon" /> </router-link>
            <router-link to="/test-drive" class="g-icon-td">Marcar Test Drive  <IconCarWheel /> </router-link>
        </div>
        <div class="navbar-primary">
            <a :href="getHundaiUrl" target="_blank" class="navbar-hyundai">
                <IconHyundai />
            </a>
            <router-link to="/"  class="navbar-logo" v-html="geral_field.site_logo" />
            <div to="/" class="navbar-mobilebBtn" @click="handleNavState">
                <svg v-if="isOpen" xmlns="http://www.w3.org/2000/svg" width="19.092" height="19.092" viewBox="0 0 19.092 19.092"><g transform="translate(-245.765 165.063) rotate(-45)"><rect width="25" height="2" transform="translate(278 69.565)" fill="#fff"/><rect width="25" height="2" transform="translate(289.5 83.065) rotate(-90)" fill="#fff"/></g></svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="25" height="9" viewBox="0 0 25 9"><g transform="translate(-278 -66)"><rect width="25" height="2" transform="translate(278 66)" fill="#fff"/><rect width="25" height="2" transform="translate(278 73)" fill="#fff"/></g></svg>
            </div>
            <div class="navbar-links">
                <router-link id="cpsLink" to="/campanhas">Campanhas</router-link>
                <router-link v-if="dealerSellsUsed" id="usadosLink" to="/usados">Usados</router-link>
                <p id="pipsLink" @click="displayModels" :class="showModels ? 'active' : ''">Novos</p>
                <ModelsMobile v-if="isMobile"
                :showModels="showModels"
                :modelsType="geral_field.sec_gama_segmentos"
                :modelsObj="models"
                v-model:value="showModels"/>
                <ModelsDesktop v-else
                :showModels="showModels"
                :modelsType="geral_field.sec_gama_segmentos"
                :modelsObj="models"
                v-model:value="showModels"/>
                <router-link id="newsLink" to="/noticias">Notícias</router-link>
                <router-link to="/concessionarios">Onde estamos?</router-link>
            </div>
        </div>
    </nav>
</template>
<script>
    import { mapState, mapGetters } from 'vuex'
    import ModelsMobile from './ModelsMobile/ModelsMobile.vue'
    import ModelsDesktop from './ModelsDesktop/ModelsDesktop.vue'

    import IconPlace from '../Icon/IconPlace.vue'
    import IconHyundai from '../Icon/IconHyundai.vue'
    import IconCarWheel from '../Icon/IconCarWheel.vue'

    export default {
        name: 'Navbar',
        components: { ModelsMobile, ModelsDesktop, IconPlace, IconHyundai, IconCarWheel },
        data(){return{
            isMobile:false,
            isOpen: false,
            showModels: false,
        }},
        created() {
            window.addEventListener("resize", this.windowResized);
        },
        mounted(){
            this.windowResized();
        },
        unmounted() {
            window.removeEventListener("resize", this.windowResized);
        },
        watch: {
            $route() {
                this.isOpen = false;
                this.showModels = false;
                document.body.classList.remove('isActive');
            },
            showModels: function(val){
                if(val == false && this.isOpen == false){
                    document.body.classList.remove('isActive');
                }
            },
            isOpen: function(val){
                let body = document.body.classList;
                (!val) ?  body.remove('isActive') :  body.add('isActive')
            }
        },
        computed:{
            ...mapState(['dealerData','geral_field', 'models', 'dealerSellsUsed']),
            ...mapGetters({
                dealerOID: 'get_oidDealerData'
            }),
            getHundaiUrl(){
                return 'https://www.hyundai.pt/' + this.dealerOID('parent')
            },
        },
        methods:{
            windowResized() {
                (window.innerWidth >= 1200) ? this.isMobile = false : this.isMobile = true;
                document.body.classList.remove('isActive');
                this.isOpen = false;
                this.showModels = false;
            },
            handleNavState(){
                this.isOpen = !this.isOpen
                this.showModels = false;
            },
            displayModels(){
                this.showModels = !this.showModels
                document.body.classList.add('isActive')
            }
        }
    }
</script>
<style lang="scss">
    @import './Navbar.scss';
</style>
