<template>
    <h2 class="formTitle">Preencha as seguintes informações e brevemente será contactado</h2>
    <section class="lightBg">
        <div class="campaignsForm">
            <form id="sendform" class="formCampaign" :action="forms" @submit="validadeForms($event)">
                <!-- <input type="hidden" name=""> -->
                <input type="hidden" name="id" value="hyundai_vue">
                <input type="hidden" name="cfg" value="hyundai_vue_contactos">
                <input type="hidden" name="form_type" value="Hyundai Vue Campanha">
                <input type="hidden" name="from_page" :value="this.$route.path">

                <input type="hidden" name="redirok" id="redirok" :value="getRouterPage('OkPage') + '?form=0'">
                <input type="hidden" name="redirnok" id="redirnok" :value="getRouterPage('ErrorPage')">
                <input type="hidden" name="email_lead" :value="formParams(formTypeKey)?.email_lead">

                <input type="hidden" name="oid_dealer" id="oid_dealer" :value="filterDealerSelected?.objectId">
                <input type="hidden" name="stand" id="stand" value="">
                <input v-if="handleCurrModel?.id" type="hidden" name="id_model" id="id_model" :value="handleCurrModel.id">
                <input v-if="handleCurrModel?.text" type="hidden" name="model" id="model" :value="handleCurrModel.text">

                <input type="hidden" name="leadSource" :value="settings('lead_source')">
                <input type="hidden" name="lead_type" id="lead_type" :value="formParams(formTypeKey)?.leadType">
                <input type="hidden" name="form_type_code" id="form_type_code" :value="formParams(formTypeKey)?.formTypeCode">
                <input type="hidden" name="tipo_pedido" id="tipo_pedido" :value="formParams(formTypeKey)?.tipoPedido">

                <input type="hidden" name="contactType" id="contactType" :value="campaign_type">
                <input type="hidden" name="idSubscriptionCenterOrigin" :value="idSubscriptionCenterOrigin">
                <input type="hidden" name="service_type" id="service_type" :value="formParams(formTypeKey)?.serviceType">

                <input type="hidden" name="utm_source" value="">
                <input type="hidden" name="utm_medium" value="">
                <input type="hidden" name="utm_campaign" value="">
                <input type="hidden" name="utm_term" value="">
                <input type="hidden" name="utm_content" value="">

                <div class="campaignsForm-slot">
                    <span>1</span>
                    <p>Dados Pessoais:</p>
                </div>

                <div class="formCampaign-fields">
                    <InputText v-model:inputVal="name" :fieldObj="defaultFormFields.nameField"/>
                    <InputText v-model:inputVal="phone" :fieldObj="defaultFormFields.phoneField"/>
                    <InputText v-model:inputVal="email" :fieldObj="defaultFormFields.emailField"/>
                </div>
                <hr>
                <div class="formCampaign-twoCol">
                    <div>
                        <div class="campaignsForm-slot">
                            <span>2</span>
                            <p>Por favor, selecione o concessionário da sua preferência* </p>
                        </div>
                        <DefaultSelect field="dealer" :fieldOptions="setDealersObj" v-model:optSelected="dealer"/>
                    </div>

                   <div v-if="showModelsCb">
                        <div class="campaignsForm-slot">
                            <span>3</span>
                            <p>Por favor, selecione um modelo* </p>
                        </div>
                        <DefaultSelect field="model_lst" :fieldOptions="models" v-model:optSelected="modelID"/>
                   </div>
               </div>
                <hr>
                <div class="formCampaign-footer">
                    <div class="formCampaign-options">
                        <InputCheckbox :checkboxObj="defaultFormFields.policyField" />
                        <InputCheckbox :checkboxObj="defaultFormFields.disclaimerField" />
                    </div>
                    <div>
                        <vue-recaptcha v-if="showRecaptcha" size="normal"  theme="light" :siteKey="sitekey"
                        class="reCapcha"
                        :id="capchaID"
                        @verify="recaptchaVerified"
                        @expire="recaptchaExpired"
                        @fail="recaptchaFailed"
                        ref="vueRecaptcha" />
                        <p class="rgErrorMessage"></p>
                    </div>
                    <div class="separator"></div>
                    <div class="formBtn">
                        <button class="btn-secondary" type="submit">
                            <span>Enviar</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.2 6.844">
                                <g id="Group_7595" data-name="Group 7595" transform="translate(-131.675 -15.439)">
                                    <path id="Path_16717" data-name="Path 16717" d="M.253,13.2A.278.278,0,0,1,0,12.9V.3A.278.278,0,0,1,.253,0,.278.278,0,0,1,.507.3V12.9A.278.278,0,0,1,.253,13.2Z" transform="translate(144.875 18.608) rotate(90)" fill="currentColor"/>
                                    <path id="Path_16716" data-name="Path 16716" d="M6.591,3.676A.253.253,0,0,1,6.412,3.6L3.422.612.433,3.6a.253.253,0,0,1-.359-.358L3.243.074a.254.254,0,0,1,.359,0L6.77,3.243a.253.253,0,0,1-.179.433Z" transform="translate(144.875 15.439) rotate(90)" fill="currentColor"/>
                                </g>
                            </svg>
                        </button>
                    </div>
               </div>
            </form>
        </div>
    </section>
</template>
<script>
    import { mapState } from 'vuex'
    import InputText from '../FormFields/InputText/InputText.vue'
    import DefaultSelect from '../FormFields/ComboBox/DefaultSelect.vue'
    import InputCheckbox from '../FormFields/InputCheckbox/InputCheckbox.vue'
    import VueRecaptcha from 'vue3-recaptcha2';
    import {formsValidation} from '../../../mixins/formsValidation'
    import {dealersOptions, findDealer} from '../../../mixins/dealersOptions'

    export default {
        name: 'CampaignsForm',
        components: { InputText, DefaultSelect, InputCheckbox, VueRecaptcha },
        mixins: [formsValidation, dealersOptions, findDealer],
        props:{
            models: { type: Object, default: () => { return {}; }},
            formTypeKey: { type: [String, Boolean]},
            campaign_type: { type: String, default: '' },
        },
        data(){return{
            modelID: '',
            showModelsCb: false,
            gtmEvent: 'eec.campanha',
        }},
        mounted(){
            if(Object.keys(this.models).length > 1) this.showModelsCb = true;

            /** GTM change contactType */
            this.contactType = this.formParams(this.formTypeKey)?.contactType
        },
        computed:{
            ...mapState(['defaultFormFields', 'forms', 'dealers']),
            handleCurrModel(){
                if(this.showModelsCb){
                    let id = this.modelID;
                    let modelName = Object.values(this.models).filter(el => el.id == id)
                    return modelName[0]
                }
                return Object.values(this.models)[0];
            }
        },
        methods: {
            handleGTMEvent(){
                window.dataLayer?.push({
                    'event': this.gtmEvent,
                    'type': this.formParams(this.formTypeKey)?.contactType,
                    'dealer': `${this.siteName} - ${this.dealer}`,
                    'model': this.handleCurrModel.text || ''
                });
            },
        }
    }
</script>
<style lang="scss">
    @import './CampaignsForm.scss';
</style>
