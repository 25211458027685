<template>
    <div :class="{'filterUsados': isActive === false, 'filterUsados open': isActive === true,}">
        <p class="filterMobile" @click="handleFilterState">PESQUISA</p>
        <div class="filterContent">
            <div class="filterUsados-options">
                <ComboBox v-model:value="selectedModel" 
                :selectObj="filterData[0]" defaultVal="Modelo"
                @change="filterCars"/>
                <ComboBox v-model:value="selectedFuel" 
                :selectObj="filterData[1]" defaultVal="Combustível" 
                @change="filterCars"/>
                <ComboBox v-model:value="selectedYear" 
                :selectObj="filterData[2]" 
                defaultVal="Ano" 
                @change="filterCars"/>
                <ComboBox v-model:value="selectedPrice" 
                :isMinMax="true" :selectObj="pricesArr" 
                defaultVal="Todos os preços" 
                @change="filterCars"/>
                <ComboBox v-model:value="selectedKms" 
                :isMinMax="true" 
                :selectObj="kmsArr" 
                defaultVal="Quilometragem" 
                @change="filterCars"/>
            </div>
            <div class="filterUsados-result">
                <!-- <p class="btn-orange" @click="clickAction">Pesquisar carro</p> -->
                <GovButton :openNewTab="false" btnClass="btn-orange" 
                btnLink="#sendToCars" 
                btnText="Pesquisar carro" 
                svgItem="arrow"
                @click="clickAction" />
                <p class="availableCars"> {{ totalCars }} carros disponíveis </p>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import ComboBox from '../../Forms/FormFields/ComboBox/ComboBox.vue'
    import GovButton from '../../Buttons/GovButton.vue'

    export default {
        name: 'UsadosFilter',
        components: { ComboBox, GovButton },
        props: {
            filterData: {type: Object, default: () => { return {}; }},
        },
        mounted(){
            this.$nextTick(() => {
                let interval = 10000;
                let priceArr = [0,];
                let kmsArr = [];
                let priceObj = [];
                let kmsObj = [];
                let price = this.filterData[3] / interval;
                let kms = this.filterData[4] / interval;
                let ceilPrice = Math.ceil(price);
                let ceilKms = Math.ceil(kms);

                for(let i = 1; i <= ceilPrice; i++){
                    priceArr.push(i * interval);
                }

                for(let i = 1; i <= ceilKms; i++){
                    kmsArr.push(i * interval);
                }

                for(let i = 1; i < priceArr.length; i++){
                    let min = priceArr[i -1];
                    let max = priceArr[i];
                    let text = min + '€ - ' + max +'€';
                    let _obj = { 'min': min, 'max': max,  'text': text}
                    priceObj.push(_obj)
                }

                for(let i = 0; i < kmsArr.length; i++){
                    let max = kmsArr[i];
                    let text = 'Até ' + max +' km';
                    let _obj = { 'max': max,  'text': text}
                    kmsObj.push(_obj)
                }

                this.pricesArr = priceObj;
                this.kmsArr = kmsObj;
            });
        },
        data(){ return{
            isActive: false,
            pricesArr: [],
            kmsArr: [],

            selectedModel: '',
            selectedFuel: '',
            selectedYear: '',
            selectedPrice: '',
            selectedKms: '',
        }},
        beforeUnmount(){ // !!!
            let optObj = this.selectedFilterOptions.options;
            Object.keys( optObj ).forEach(k => optObj[k] = '');
        },
        computed:{
            ...mapState(['selectedFilterOptions']),
            totalCars(){
                if(this.selectedFilterOptions.totalcars) return this.selectedFilterOptions.totalcars
                return 0;
            }
        },
        methods:{
            filterCars(){
                this.$store.commit('selectedFilterOptions/UPDATE_OPTIONS', {
                    selectedModel: this.selectedModel,
                    selectedFuel: this.selectedFuel,
                    selectedYear: this.selectedYear,
                    selectedPrice: this.selectedPrice,
                    selectedKms: this.selectedKms,
                });
            },
            handleFilterState(){
                this.isActive = !this.isActive;
                document.body.classList.toggle('isActive');
            },
            clickAction(){
                this.isActive = false;
                document.body.classList.remove('isActive');
            }
        },
    }
</script>
<style lang="scss">
    @import './UsadosFilter.scss';
</style>